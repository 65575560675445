export enum DicoActions {
  ACC = 'ACC',
  WEI = 'WEI',
  ANN = 'ANN',
  GER = 'GER',
  AFH = 'AFH',
  VER = 'VER',
}

export const DicoActionLabelMap: { [key in DicoActions]: string } = {
  [DicoActions.ACC]: 'Acceptatie',
  [DicoActions.WEI]: 'Weigering',
  [DicoActions.ANN]: 'Annulering',
  [DicoActions.GER]: 'Gereedmelding',
  [DicoActions.AFH]: 'Afspraak huurder',
  [DicoActions.VER]: 'Verlenging',
}
