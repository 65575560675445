import React, { useCallback, useMemo, useState } from 'react';
import { useTypedSelector } from 'store';
import InputCard from 'components/InputCard';
import {
  getOpdrachtFotos,
  getOpdrachtFotosKlant,
  getOpdrachtFotosProduct,
  getOpdrachtFotosWerkzaamheden,
} from 'store/selectors/opdrachtBijlages';
import ImageCard from './ImageCard';
import { IBijlageDetails } from 'interfaces/bijlage';
import ImageViewer from './ImageViewer';
import { IStackStyles, Stack } from '@fluentui/react';
import { FileUploadType } from 'enums/fileUploadType';
import { BijlageBron } from 'enums/bijlageBron';
import FileUploadOpdrachtBijlage from '../FileUpload/OpdrachtBijlage/FileUploadOpdrachtBijlage';
import InputCardButton from 'components/InputCardButton';
import { BijlageOnderdeel } from "enums/bijlageOnderdeel";

const stackStyles: IStackStyles = {
  inner: {
    display: 'flex',
    height: '100%',
    width: '100%',
    padding: 10,
  },
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
  },
}

interface ImageViewerState {
  open: boolean
  id?: number
  opdrachtRegelId?: number
  imageUrl?: string
}

const initImageViewerState: ImageViewerState = {
  open: false,
}

const inputCardContentStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  overflow: 'auto',
  flexGrow: 0,
  flexShrink: 1,
}
const inputCardStyles: React.CSSProperties = { width: 500, margin: 4, paddingBottom: 16, flexGrow: 0 }

interface IGalleryProps {
  opdrachtId: number
}

interface IFileUploadDialogState {
  title: string
  accept?: string
  open: boolean
  opdrachtId?: number
  type: FileUploadType
  bron: BijlageBron
}

const Gallery: React.FC<IGalleryProps> = ({ opdrachtId }) => {
  const [imageViewerState, setImageViewerState] = useState<ImageViewerState>(initImageViewerState)

  const { opdrachtregelId } = useTypedSelector(state => state.opdrachten.regels);
  let images = useTypedSelector(state => getOpdrachtFotos(state, opdrachtId))
  const klantFotos = useTypedSelector(state => getOpdrachtFotosKlant(state, opdrachtId))
  const fotosWerkzaamheden = useTypedSelector(state => getOpdrachtFotosWerkzaamheden(state, opdrachtId))
  const productFotos = useTypedSelector(state => getOpdrachtFotosProduct(state, opdrachtId))
  const [fotosWerkzaamhedenUploadState, setFotosWerkzaamhedenUploadState] = useState<IFileUploadDialogState>({
    title: '',
    open: false,
    type: FileUploadType.Image,
    bron: BijlageBron.Klant,
  });
  const [productFotosUploadState, setProductFotosUploadState] = useState<IFileUploadDialogState>({
    title: '',
    open: false,
    type: FileUploadType.Image,
    bron: BijlageBron.Klant,
  });

  const opdracht = useTypedSelector(state => state.opdrachten.data.list.find(x => x.id === opdrachtId))

  const regelsIsDefined: boolean = (() => {
    return (opdracht?.regels?.length ?? 0) > 0;
  })();

  const handleImageClick = useCallback((image: IBijlageDetails) => {
    setImageViewerState({
      open: true,
      id: image.id,
      opdrachtRegelId: image.opdrachtRegelId && image.opdrachtRegelId,
      imageUrl: image.url,
    })
  }, [])

  const selectedImage: IBijlageDetails | undefined = useMemo(() => {
    if (!imageViewerState.open || !imageViewerState.id) return undefined
    const imageFound = images.find(
      x =>
        x.id === imageViewerState.id
    )
    if (imageFound) {
      imageFound.imageUrl = imageViewerState.imageUrl
      return imageFound;
    }
  }, [images, imageViewerState])

  const onAddFotoWerkzaamheden = (): any => {
    setFotosWerkzaamhedenUploadState({
      title: `Foto's werkzaamheden uploaden`,
      accept: 'image/*',
      open: true,
      opdrachtId: opdrachtId,
      type: FileUploadType.Image,
      bron: BijlageBron.Werkzaamheden,
    });
  };
  const onAddProductFotos = (): any => {
    setProductFotosUploadState({
      title: `Productfoto's uploaden`,
      accept: 'image/*',
      open: true,
      opdrachtId: opdrachtId,
      type: FileUploadType.Image,
      bron: BijlageBron.Product,
    });
  };
  return (
    <>
      <Stack horizontal wrap tokens={{ childrenGap: 8 }} styles={stackStyles}>
        <InputCard title="Foto's klant" style={inputCardStyles} contentStyle={inputCardContentStyles}>
          {klantFotos.map(image => (
            <ImageCard key={image.id} imageDetails={image} onClick={handleImageClick} />
          ))}
        </InputCard>
        <InputCardButton title="Foto's werkzaamheden" style={inputCardStyles} contentStyle={inputCardContentStyles}
          showAddButton={regelsIsDefined} addButtonTitle={'Toevoegen'}
          onAddButtonClick={onAddFotoWerkzaamheden}>
          {fotosWerkzaamheden.map(image => (
            <ImageCard key={image.id} imageDetails={image} onClick={handleImageClick} />
          ))}
        </InputCardButton>
        <InputCardButton title="Productfoto's" style={inputCardStyles} contentStyle={inputCardContentStyles}
          showAddButton={regelsIsDefined} addButtonTitle={'Toevoegen'}
          onAddButtonClick={onAddProductFotos}>
          {productFotos.map(image => (
            <ImageCard key={image.id} imageDetails={image} onClick={handleImageClick} />
          ))}
        </InputCardButton>
      </Stack>
      {imageViewerState.open && selectedImage && (
        <ImageViewer bijlage={selectedImage} onClose={() => setImageViewerState(initImageViewerState)} />
      )}
      {fotosWerkzaamhedenUploadState.open && (
        <FileUploadOpdrachtBijlage
          context={{
            opdrachtId: opdrachtId,
            opdrachtRegelId: opdrachtregelId!,
            type: fotosWerkzaamhedenUploadState.type,
            bron: fotosWerkzaamhedenUploadState.bron,
            title: fotosWerkzaamhedenUploadState.title,
            accept: fotosWerkzaamhedenUploadState.accept!,
            bijlageOnderdeel: BijlageOnderdeel.Foto,
            showRegelDropdown: true
          }}
          onCancel={() =>
            setFotosWerkzaamhedenUploadState({
              open: false,
              title: '',
              accept: '',
              opdrachtId: undefined,
              type: FileUploadType.Document,
              bron: BijlageBron.Klant,
            })
          }
          regels={opdracht?.regels ?? []}
        />
      )}
      {productFotosUploadState.open && (
        <FileUploadOpdrachtBijlage
          context={{
            opdrachtId: opdrachtId,
            opdrachtRegelId: opdrachtregelId!,
            type: productFotosUploadState.type,
            bron: productFotosUploadState.bron,
            title: productFotosUploadState.title,
            accept: productFotosUploadState.accept!,
            bijlageOnderdeel: BijlageOnderdeel.Foto,
            showRegelDropdown: true
          }}
          onCancel={() =>
            setProductFotosUploadState({
              open: false,
              title: '',
              accept: '',
              opdrachtId: undefined,
              type: FileUploadType.Document,
              bron: BijlageBron.Klant,
            })
          }
          regels={opdracht?.regels ?? []}
        />
      )}
    </>
  )
}

export default Gallery
