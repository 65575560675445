export const REQUIRED = 'Dit veld is verplicht'

export const NUMBER = 'Waarde moet een nummer zijn'
export const DATE = 'Waarde moet een datum zijn'
export const STR_MAX_LENGTH_20 = 'Dit veld mag maximaal 20 karakters bevatten'
export const STR_MAX_LENGTH_100 = 'Dit veld mag maximaal 100 karakters bevatten'
export const STR_MAX_LENGTH_500 = 'Dit veld mag maximaal 500 karakters bevatten'
export const GLN = 'Een GLN code moet uit 13 cijfers bestaan'
export const TIME = 'Vul een geldige tijd in (uu:mm)'

export const POSITIVE = 'Waarde moet positief zijn'
export const PERCENTAGE = 'Waarde moet tussen de 0 en de 100 liggen'
export const FUTURE_DATE = 'Datum mag niet in het verleden liggen'
export const FUTURE_TIME = 'Tijdstip mag niet in het verleden liggen'
