import { createSelector } from '@reduxjs/toolkit';
import { OpdrachtStatus } from 'enums/opdrachtStatus';
import { ILaneSortInfo } from 'interfaces/laneSortInfo';
import { IOpdracht, IOpdrachtgever } from 'interfaces/opdracht';
import { filterDocumentFn, filterFotoFn } from 'lib/bijlageFilter';
import {
    FilterOpdrachtGeverAlles,
    FilterOpdrachtNemerAlles,
    FilterOpdrachtNemerOnbekend
} from 'lib/constants/dashboard';
import { getPropertyName } from 'lib/interfaceUtils';
import { localeCompareString } from 'lib/internationalization';
import { RootState } from '../index';
import {
    getDashboardFilter,
    getExecuteLaneSortInfo,
    getOnholdLaneSortInfo,
    getPlanLaneSortInfo,
    getStartLaneSortInfo,
} from './dashboard';
import {BijlageOnderdeel} from "../../enums/bijlageOnderdeel";

const getOpdrachtenList = (state: RootState) => state.opdrachten.data.list;
const getOpdrachtenForDashboard = createSelector([getOpdrachtenList, getDashboardFilter], (opdrachten, filter) => {
    
    const zoekTerm = filter.zoekTerm?.toLowerCase() ?? '';
    
    if (filter.opdrachtgeverId === FilterOpdrachtGeverAlles 
        && filter.opdrachtNemerNaam === FilterOpdrachtNemerAlles
        && zoekTerm === '') 
    {
        return opdrachten;
    }
    
    const zoekTermen = zoekTerm.split(' ');
    
    const opdrachtenGefilterd = opdrachten.filter(o => {
        return (filter.opdrachtgeverId === FilterOpdrachtGeverAlles || o.opdrachtgever.id.toString() === filter.opdrachtgeverId) &&
               (filter.opdrachtNemerNaam === FilterOpdrachtNemerAlles || (o.partijOpdrachtNemer?.naam ?? FilterOpdrachtNemerOnbekend) === filter.opdrachtNemerNaam) && 
               (filter.zoekTerm === '' || zoekTermen.every(term => JSON.stringify(o).toLowerCase().includes(term))  );
    });

    return opdrachtenGefilterd;
});
const sort = (opdrachten: IOpdracht[], sortInfo: ILaneSortInfo): IOpdracht[] => {
    return opdrachten.sort((itemA, itemB) => {
        const {columnId, columnType, desc} = sortInfo;

        const a = itemA[columnId];
        const b = itemB[columnId];

        let returnValue = 0;

        switch (columnType) {
            case 'number':
                returnValue = desc ? (b as number) - (a as number) : (a as number) - (b as number);
                break;
            case 'boolean':
                returnValue = desc ? +(b as boolean) - +(a as boolean) : +(a as boolean) - +(b as boolean);
                break;
            case 'date':
                returnValue = desc
                    ? new Date((b || '9999-01-01') as string).getTime() - new Date((a || '9999-01-01') as string).getTime()
                    : new Date((a || '9999-01-01') as string).getTime() - new Date((b || '9999-01-01') as string).getTime();
                break;
            case 'string':
                returnValue = localeCompareString(a as string, b as string, desc);
                break;
        }

        return returnValue;
    });
};

export const getOpdrachten = (state: RootState) => state.opdrachten.data;
export const getOpdracht = (state: RootState, id: number) => {
    const opdracht = state.opdrachten.data.list.find(b => b.id === id);

    if (!opdracht) return undefined;

    let aantalDocumenten = (opdracht.bijlages || []).filter(filterDocumentFn).length;
    let aantalFotos = (opdracht.bijlages || []).filter(filterFotoFn).length;
    let aantalExterneDocumenten = (opdracht.bijlages || []).filter(b => b.bijlageOnderdeel === BijlageOnderdeel.ExternDocument).length;

    const regels = opdracht.regels || [];
    for (const regel of regels) {
        if (!regel.bijlages) continue;

        aantalDocumenten = aantalDocumenten + regel.bijlages.filter(filterDocumentFn).length;
        aantalFotos = aantalFotos + regel.bijlages.filter(filterFotoFn).length;
        aantalExterneDocumenten = aantalExterneDocumenten + regel.bijlages.filter(b => b.bijlageOnderdeel === BijlageOnderdeel.ExternDocument).length;
    }

    return {
        ...opdracht,
        documentenCount: aantalDocumenten,
        fotosCount: aantalFotos,
        externeDocumentenCount: aantalExterneDocumenten,
    } as IOpdracht;
};

export const getOpdrachtenForStartLane = createSelector(
    [getOpdrachtenForDashboard, getStartLaneSortInfo],
    (opdrachten, sortInfo) => {
        let filtered = opdrachten.filter(o => o.status === OpdrachtStatus.Ontvangen);
        return sort(filtered, sortInfo);
    }
);

export const getOpdrachtenForPlanLane = createSelector(
    [getOpdrachtenForDashboard, getPlanLaneSortInfo],
    (opdrachten, sortInfo) => {
        const filtered = opdrachten.filter(o => o.status === OpdrachtStatus.Geaccepteerd);
        return sort(filtered, sortInfo);
    }
);

export const getOpdrachtenForExecuteLane = createSelector(
    [getOpdrachtenForDashboard, getExecuteLaneSortInfo],
    (opdrachten, sortInfo) => {
        const filtered = opdrachten.filter(o => o.status === OpdrachtStatus.Gepland);
        return sort(filtered, sortInfo);
    }
);

export const getOpdrachtenForOnHoldLane = createSelector(
    [getOpdrachtenForDashboard, getOnholdLaneSortInfo],
    (opdrachten, sortInfo) => {
        const filtered = opdrachten.filter(o => o.status === OpdrachtStatus.Onderbroken);
        return sort(filtered, sortInfo);
    }
);

export const getDistinctOpdrachtgevers = (state: RootState): IOpdrachtgever[] => {
    const opdrachtgevers: IOpdrachtgever[] = [];
    const openOpdrachten = state.opdrachten.data.list.filter(o => o.status !== OpdrachtStatus.Afgesloten);

    const map = new Map<number, boolean>();
    for (const opdracht of openOpdrachten) {
        if (!map.has(opdracht.opdrachtgever.id)) {
            map.set(opdracht.opdrachtgever.id, true);
            opdrachtgevers.push(opdracht.opdrachtgever);
        }
    }

    return opdrachtgevers;
};

export const getOpdrachtNemerNamen = (state: RootState): string[] => {
    const opdrachtNemers: string[] = [];
    const openOpdrachten = state.opdrachten.data.list.filter(o => o.status !== OpdrachtStatus.Afgesloten);

    const map = new Map<string, boolean>();
    for (const opdracht of openOpdrachten) {
        const opdrachtNemerNaam = opdracht.partijOpdrachtNemer?.naam ?? FilterOpdrachtNemerOnbekend;
        if (!map.has(opdrachtNemerNaam)) {
            map.set(opdrachtNemerNaam, true);
            opdrachtNemers.push(opdrachtNemerNaam);
        }
    }

    // sort by name and return
    return opdrachtNemers.sort();
};

export const getAfgeslotenOpdrachten = (state: RootState) => {
    const opdrachten = state.opdrachten.data.list.filter(o => o.status === OpdrachtStatus.Afgesloten);
    return sort(opdrachten, {
        id: 'n/a',
        columnId: getPropertyName<IOpdracht>('datumGereed'),
        columnType: 'date',
        desc: true,
    });
};

export const getUpsertOpdracht = (state: RootState) => state.opdrachten.upsert;
