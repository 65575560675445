import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IStateMachine } from 'store';
import { RootState } from 'store/rootReducer';
import { invokeFetch } from 'services/apiClient';
import { IOpdracht } from 'interfaces/opdracht';
import { OpdrachtStatus } from 'enums/opdrachtStatus';
import { createOpdrachtregelbijlage, deleteOpdrachtregelbijlage } from '../bijlages/upsert';
import { BijlageType } from 'enums/bijlageType';
import { BijlageBron } from 'enums/bijlageBron';
import { BijlageKenmerk } from 'enums/bijlageKenmerk';
import {BijlageOnderdeel} from "enums/bijlageOnderdeel";

const mergeList = (items: Array<IOpdracht>, item: IOpdracht): Array<IOpdracht> =>
  items.filter(i => i.id !== item.id).concat([item])

export interface IOpdrachtenState extends IStateMachine {
  list: Array<IOpdracht>
}

const initState: IOpdrachtenState = {
  list: [],
  status: 'idle',
  error: '',
}

export const fetchOpdrachten = createAsyncThunk<Array<IOpdracht>, undefined, { state: RootState }>(
  'opdrachten/fetch',
  async (_, thunkApi) => {
    return await invokeFetch<Array<IOpdracht>>(
      thunkApi,
      'GET',
      `/odata/opdrachten?$filter=status ne 'Afgesloten'&$expand=Opdrachtgever,Gebeurtenissen($expand=Regels),partijOpdrachtGever,partijOpdrachtNemer`
    )
  }
)

export const fetchAfgeslotenOpdrachten = createAsyncThunk<Array<IOpdracht>, undefined, { state: RootState }>(
  'afgeslotenOpdrachten/fetch',
  async (_, thunkApi) => {
    return await invokeFetch<Array<IOpdracht>>(
      thunkApi,
      'GET',
      `/odata/opdrachten?$filter=status eq 'Afgesloten'&$expand=opdrachtgever,gebeurtenissen($expand=regels)`
    )
  }
)

export const fetchOpdracht = createAsyncThunk<IOpdracht, string, { state: RootState }>(
  'opdrachten/fetchById',
  async (id, thunkApi) => {
    return await invokeFetch<IOpdracht>(
      thunkApi,
      'GET',
      `/odata/opdrachten(${id})?$expand=opdrachtgever,gebeurtenissen($expand=regels),regels($expand=bijlages($expand=eigenschappen)),bijlages($expand=eigenschappen),partijOpdrachtGever,partijOpdrachtNemer`
    )
  }
)

export interface ICreateOpdrachtBijlageeigenschapArgs {
  naam: BijlageKenmerk
  waarde: string
}

export interface ICreateOpdrachtBijlageArgs {
  opdrachtId: number
  opdrachtRegelId: number
  soort: BijlageType
  bestandssoort: string
  bestandsnaam: string
  bron: BijlageBron
  eigenschappen: ICreateOpdrachtBijlageeigenschapArgs[],
  bijlageOnderdeel: BijlageOnderdeel,
  file: File
}

export const addOpdrachtBijlage = createAsyncThunk<
    IOpdracht,
    ICreateOpdrachtBijlageArgs,
    { state: RootState; }>(
    'opdrachten/addBijlage',
    async (args, thunkApi) => {
      const formData = new FormData();
      formData.append('opdrachtId', args.opdrachtId.toString());
      formData.append('opdrachtRegelId',args.opdrachtRegelId.toString());
      formData.append('soort', args.soort);
      formData.append('bestandssoort', args.bestandssoort);
      formData.append('bestandsnaam', args.bestandsnaam);
      formData.append('bron', args.bron);
      formData.append('eigenschappen', JSON.stringify(args.eigenschappen));
      formData.append('bijlageOnderdeel', args.bijlageOnderdeel.toString());
      formData.append('file', args.file, args.file.name);
      const opdracht = await invokeFetch<IOpdracht>(
          thunkApi, 'POST', `/opdracht/${args.opdrachtId}/bijlagen`, formData, false, {}, false);
        return opdracht;
    }
);

export interface IDeleteOpdrachtBijlageArgs {
  opdrachtId: number;
    bijlageId: number;
}

export const deleteOpdrachtBijlage = createAsyncThunk<
    IOpdracht,
    IDeleteOpdrachtBijlageArgs,
    { state: RootState; }>(
    'opdrachten/deleteBijlage',
    async (args, thunkApi) => {
        const opdracht = await invokeFetch<IOpdracht>(
            thunkApi, 'DELETE', `/opdracht/${args.opdrachtId}/bijlagen/${args.bijlageId}`, null, false, {}, false);
        return opdracht;
    }
);

export const slice = createSlice({
  name: 'opdrachten/data',
  initialState: initState,
  reducers: {
    fetchOpdrachtenSetPending: state => {
      state.status = 'pending'
      state.error = ''
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchOpdrachten.pending, (state, _) => {
      state.status = 'pending'
      state.error = ''
    })
    builder.addCase(fetchOpdrachten.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.list = action.payload
    })
    builder.addCase(fetchOpdrachten.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message || ''
    })

    builder.addCase(fetchAfgeslotenOpdrachten.pending, (state, _) => {
      state.status = 'pending'
      state.error = ''
    })
    builder.addCase(fetchAfgeslotenOpdrachten.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const openOpdrachten = state.list.filter(x => x.status !== OpdrachtStatus.Afgesloten)
      state.list = openOpdrachten.concat(action.payload)
    })
    builder.addCase(fetchAfgeslotenOpdrachten.rejected, (state, action) => {
      state.status = 'pending'
      state.error = action.error.message = ''
    })

    builder.addCase(fetchOpdracht.pending, (state, _) => {
      state.status = 'pending'
      state.error = ''
    })
    builder.addCase(fetchOpdracht.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.list = mergeList(state.list, action.payload)
    })
    builder.addCase(fetchOpdracht.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message || ''
    })

    builder.addCase(createOpdrachtregelbijlage.fulfilled, (state, { payload: bijlage }) => {
      const opdracht = state.list.find(x => x.id === bijlage.opdrachtId)!
      const regel = opdracht.regels.find(x => x.id === bijlage.opdrachtRegelId)!
      regel.bijlages = (regel.bijlages || []).concat([bijlage])
    })

    builder.addCase(deleteOpdrachtregelbijlage.fulfilled, (state, { payload: args }) => {
      const opdracht = state.list.find(x => x.id === args.opdrachtId)!
      const regel = opdracht.regels.find(x => x.id === args.opdrachtregelId)!
      regel.bijlages = (regel.bijlages || []).filter(x => x.id !== args.id)
    })

    builder.addCase(addOpdrachtBijlage.pending, (state, {payload: opdracht}) => {
        state.status = 'pending';
        state.error = '';
    });
    builder.addCase(addOpdrachtBijlage.fulfilled, (state, {payload: opdracht}) => {
      state.status = 'succeeded';
      state.list = mergeList(state.list, opdracht);
      state.error = '';
    });
    builder.addCase(addOpdrachtBijlage.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || '';
    });

    builder.addCase(deleteOpdrachtBijlage.pending, (state, {payload: opdracht}) => {
      state.status = 'pending';
      state.error = '';
    });
    builder.addCase(deleteOpdrachtBijlage.fulfilled, (state, {payload: opdracht}) => {
      state.status = 'succeeded';
      state.list = mergeList(state.list, opdracht);
      state.error = '';
    });
    builder.addCase(deleteOpdrachtBijlage.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || '';
    });
    
    
  },
})

export const {fetchOpdrachtenSetPending} = slice.actions
export default slice.reducer
