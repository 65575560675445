import React  from 'react';
import { IOpdracht } from 'interfaces/opdracht';
import { Icon, ITextFieldStyles, TextField } from '@fluentui/react';
import InputCard from 'components/InputCard';
import MailTo from 'components/MailTo';
import primaryTheme from 'themes/primary';
import Call from 'components/Call';

interface IProps {
    opdracht: IOpdracht
    labelWidth: number
    labelStyles: Partial<ITextFieldStyles>
}

export const ContactgegevensOpdrachtgever = ({opdracht, labelWidth, labelStyles} : IProps) => {

    return (
        <InputCard title="Opdrachtgever contactgegevens">
            {opdracht.partijOpdrachtGever && 
                <div className="opdrachtgever">
                    <TextField
                        autoComplete="off"
                        styles={labelStyles}
                        label="Opdrachtgever"
                        underlined
                        readOnly
                        value={opdracht.partijOpdrachtGever.naam}
                    />
                    <TextField
                        autoComplete="off"
                        styles={labelStyles}
                        label="GLN-code"
                        underlined
                        readOnly
                        value={opdracht.partijOpdrachtGever.gln}
                    />                    
                    {opdracht?.partijOpdrachtGever?.contactgegevens && (
                        opdracht.partijOpdrachtGever.contactgegevens.map((contactgegeven, index) => (
                                <>
                                    <TextField
                                        autoComplete="off"
                                        styles={labelStyles}
                                        label="Contactpersoon"
                                        underlined
                                        readOnly
                                        value={contactgegeven.contactpersoon}
                                    />
                                    <TextField
                                        autoComplete="off"
                                        styles={labelStyles}
                                        label="Telefoon"
                                        underlined
                                        readOnly
                                        value={contactgegeven.telefoonnummer}
                                        hidden={contactgegeven.telefoonnummer === ''}
                                        onRenderSuffix={props => renderCall(contactgegeven.telefoonnummer!)}
                                    />
                                    <TextField
                                        autoComplete="off"
                                        styles={labelStyles}
                                        label="Email"
                                        underlined
                                        readOnly
                                        value={contactgegeven.emailadres}
                                        hidden={contactgegeven.emailadres === ''}
                                        onRenderSuffix={_ => renderMailTo(contactgegeven.emailadres)}
                                    />
                                </>
                            )
                        ))}
                </div>
            
            }

        </InputCard>
    );
};

const renderMailTo = (email: string): JSX.Element => {
    return (
        <MailTo email={email}>
            <Icon iconName="mail" styles={{root: {color: primaryTheme.palette.themePrimary}}}/>
        </MailTo>
    );
};

const renderCall = (number: string): JSX.Element => {
    return (
        <Call phoneNumber={number}>
            <Icon iconName="phone" styles={{root: {color: primaryTheme.palette.themePrimary}}}/>
        </Call>
    );
};