import { BijlageBron } from 'enums/bijlageBron'
import {
  BijlageKenmerk,
  BijlageKenmerkCertificaatWaardeLabelMap,
  BijlageKenmerkHandleidingWaardeLabelMap,
} from 'enums/bijlageKenmerk'
import { BijlageType } from 'enums/bijlageType'
import { IBijlage, IBijlageDetails } from 'interfaces/bijlage'
import { filterDocumentFn, filterFotoFn } from 'lib/bijlageFilter'
import { RootState } from 'store'
import { BijlageOnderdeel } from '../../enums/bijlageOnderdeel'

const getKenmerk = (bijlage: IBijlage): string | undefined => {
  if (bijlage.soort === BijlageType.MAN) {
    const eigenschap = (bijlage.eigenschappen || []).find(x => x.naam === BijlageKenmerk.MANCategory)

    if (eigenschap) {
      return BijlageKenmerkHandleidingWaardeLabelMap[eigenschap.waarde]
    }
  } else if (bijlage.soort === BijlageType.STR) {
    const eigenschap = (bijlage.eigenschappen || []).find(x => x.naam === BijlageKenmerk.STRCategory)

    if (eigenschap) {
      return BijlageKenmerkCertificaatWaardeLabelMap[eigenschap.waarde]
    }
  }

  return undefined
}

const getOpdrachtBijlageDetails = (state: RootState, opdrachtId: number): IBijlageDetails[] => {
  const opdracht = state.opdrachten.data.list.find(x => x.id === opdrachtId)
  if (!opdracht) return []

  const bijlageUrls = state.blobs.data.list

  let allBijlages: IBijlageDetails[] = []
  allBijlages = (opdracht.bijlages ?? []).map(bijlage => {
    const url = (bijlageUrls.find(x => x.id === bijlage.bijlageId) ?? {}).uri
    return {
      ...bijlage,
      url: url ?? undefined,
      oorsprong: 'Opdracht',
      locatie: '',
      element: '',
      kenmerk: getKenmerk(bijlage),
    } as IBijlageDetails
  })

  for (const regel of opdracht.regels) {
    if (!regel.bijlages) continue

    for (const bijlage of regel.bijlages) {
      const url = (bijlageUrls.find(x => x.id === bijlage.bijlageId) ?? {}).uri
      allBijlages.push({
        ...bijlage,
        opdrachtId: opdracht.id,
        url: url ?? undefined,
        oorsprong: 'Regel',
        locatie: regel.locatie,
        element: regel.element,
        kenmerk: getKenmerk(bijlage),
      })
    }
  }

  return allBijlages
}

const sortByDateDescFn = (a: IBijlageDetails, b: IBijlageDetails) =>
  new Date(b.datum).getTime() - new Date(a.datum).getTime()

export const getOpdrachtDocumenten = (state: RootState, opdrachtId: number): IBijlageDetails[] => {
  const bijlages = getOpdrachtBijlageDetails(state, opdrachtId)
  return bijlages.filter(filterDocumentFn).sort(sortByDateDescFn)
}

export const getOpdrachtExterneDocumenten = (state: RootState, opdrachtId: number) => {
  const opdracht = state.opdrachten.data.list.find(x => x.id === opdrachtId)
  if (!opdracht) return []
  return opdracht.bijlages!.filter( x => x.bijlageOnderdeel.toString() === BijlageOnderdeel.ExternDocument )
}

export const getOpdrachtFotos = (state: RootState, opdrachtId: number): IBijlageDetails[] => {
  const bijlages = getOpdrachtBijlageDetails(state, opdrachtId)
  return bijlages.filter(filterFotoFn).sort(sortByDateDescFn)
}

export const getOpdrachtFotosKlant = (state: RootState, opdrachtId: number): IBijlageDetails[] => {
  const bijlages = getOpdrachtBijlageDetails(state, opdrachtId)
  return bijlages
    .filter(x => x.bron === BijlageBron.Klant)
    .filter(filterFotoFn)
    .sort(sortByDateDescFn)
}

export const getOpdrachtFotosWerkzaamheden = (state: RootState, opdrachtId: number): IBijlageDetails[] => {
  const bijlages = getOpdrachtBijlageDetails(state, opdrachtId)
  return bijlages
    .filter(x => x.bron === BijlageBron.Werkzaamheden)
    .filter(filterFotoFn)
    .sort(sortByDateDescFn)
}

export const getOpdrachtFotosProduct = (state: RootState, opdrachtId: number): IBijlageDetails[] => {
  const bijlages = getOpdrachtBijlageDetails(state, opdrachtId)
  return bijlages
    .filter(x => x.bron === BijlageBron.Product)
    .filter(filterFotoFn)
    .sort(sortByDateDescFn)
}
