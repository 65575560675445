import React from 'react'
import { ThemeProvider } from '@fluentui/react'
import { MsalProvider } from '@azure/msal-react'

import './App.css'

import AppRouter from 'AppRouter'
import primaryTheme from 'themes/primary'
import { MsalClient } from 'services/MsalClient'

function App() {
  const pca = MsalClient.getInstance()

  return (
    <MsalProvider instance={pca}>
      <ThemeProvider applyTo="body" theme={primaryTheme}>
        <AppRouter />
      </ThemeProvider>
    </MsalProvider>
  )
}

export default App
