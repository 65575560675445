import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IConfiguration {
  apiScopes: string[]
  apiEndpoint: string
  clientId: string
  redirectUri: string
  postLogoutRedirectUri: string
}

interface IConfigurationState extends IConfiguration {
  initialized: boolean
}

const initialState: IConfigurationState = {
  apiScopes: [],
  apiEndpoint: '',
  clientId: '',
  redirectUri: '',
  postLogoutRedirectUri: '',
  initialized: false,
}

export const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    initializeApp: (_, action: PayloadAction<IConfiguration>) => {
      return {
        ...action.payload,
        initialized: true,
      }
    },
  },
})

export const { initializeApp } = slice.actions

export default slice.reducer
