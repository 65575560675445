import React from 'react'

interface IMailToProps {
  style?: React.CSSProperties
  email: string
  subject?: string
  body?: string
}

const MailTo: React.FC<IMailToProps> = ({ style, email, subject = '', body = '', children }) => {
  let params = subject || body ? '?' : ''
  if (subject) params += `subject=${encodeURIComponent(subject)}`
  if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`

  return (
    <a href={`mailto:${email}${params}`} style={style}>
      {children}
    </a>
  )
}

export default MailTo
