import React, { useCallback, useMemo } from 'react'

import { Dialog, DialogFooter, IDialogContentProps, IModalProps, DialogType, DefaultButton } from '@fluentui/react'
import { useId } from '@fluentui/react-hooks'
import { useAppDispatch, useTypedSelector } from 'store'
import { getGenericError } from 'store/selectors/app'
import { useHistory } from 'react-router-dom'
import { resetGenericError } from 'store/actions/app/genericError'

const GenericError: React.FC<{}> = props => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const genericError = useTypedSelector(getGenericError)

  const labelId: string = useId('dialogLabel')
  const subTextId: string = useId('subTextLabel')

  const modalProps = useMemo(
    (): IModalProps => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: true,
    }),
    [labelId, subTextId]
  )

  const dialogContentProps = useMemo(
    (): IDialogContentProps => ({
      type: DialogType.normal,
      title: genericError.title,
      subText: genericError.subText,
    }),
    [genericError]
  )

  const handleConfirmClick = useCallback(() => {
    dispatch(resetGenericError())
    if (genericError.route) {
      history.push(genericError.route)
    }
  }, [genericError, history])

  if (!genericError.title || !genericError.subText) return null

  return (
    <div className="genericErrorDialog">
      <Dialog
        hidden={false}
        onDismiss={handleConfirmClick}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
        <DialogFooter>
          <DefaultButton primary onClick={handleConfirmClick} text="Ok" />
        </DialogFooter>
      </Dialog>
    </div>
  )
}

export default GenericError
