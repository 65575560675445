import { DashboardLanes } from 'enums/dashboardLanes'
import { RootState } from 'store'

export const getDashboardFilter = (state: RootState) => state.dashboard.filter;
export const getSortInfoByLaneId = (state: RootState, laneId: DashboardLanes) =>
  state.dashboard.laneSortInfo.find(x => x.id === laneId)!

export const getStartLaneSortInfo = (state: RootState) =>
  state.dashboard.laneSortInfo.find(x => x.id === DashboardLanes.Starten)!

export const getPlanLaneSortInfo = (state: RootState) =>
  state.dashboard.laneSortInfo.find(x => x.id === DashboardLanes.Plannen)!

export const getExecuteLaneSortInfo = (state: RootState) =>
  state.dashboard.laneSortInfo.find(x => x.id === DashboardLanes.Uitvoeren)!

export const getOnholdLaneSortInfo = (state: RootState) =>
  state.dashboard.laneSortInfo.find(x => x.id === DashboardLanes.Afwachten)!
