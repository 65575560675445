import React, { useMemo } from 'react';
import InputCard from 'components/InputCard';
import { IOpdracht } from 'interfaces/opdracht';
import { ITextFieldStyles, TextField } from '@fluentui/react';
import { stringToLocaleDateString } from 'lib/internationalization';
import { OpdrachtStatusLabelMap } from 'enums/opdrachtStatus';
import { OpdrachtVormLabelMap } from 'enums/opdrachtVorm';
import FluentToggle from 'components/FluentToggle';
import { OpdrachtTyperingLabelMap } from 'enums/opdrachtTypering';
import { OpdrachtSoortLabelMap } from '../../../../enums/opdrachtSoort';

interface IAlgemeenProps {
    opdracht: IOpdracht;
    labelWidth: number;
    labelStyles: Partial<ITextFieldStyles>;
}

const Algemeen: React.FC<IAlgemeenProps> = ({opdracht, labelWidth, labelStyles}) => {
    const toggleStyles = useMemo(
        () => ({
            label: {
                width: labelWidth,
                paddingLeft: 12,
            },
            root: {
                borderBottom: '1px solid #efefef',
                height: 32,
            },
        }),
        [labelWidth]
    );

    return (
        <InputCard title="Algemeen">
            <TextField
                autoComplete="off"
                styles={labelStyles}
                label="Opdrachtnummer"
                underlined
                readOnly
                value={opdracht.nummer}
            />
            {opdracht.subnummer && (
                <TextField
                    autoComplete="off"
                    styles={labelStyles}
                    label="Subopdrachtnummer"
                    underlined
                    readOnly
                    value={opdracht.subnummer}
                />
            )}
            <TextField
                autoComplete="off"
                styles={labelStyles}
                label="Opdrachtgever"
                underlined
                readOnly
                value={opdracht.opdrachtgever.naam}
            />
            <TextField
                autoComplete="off"
                styles={labelStyles}
                label="Omschrijving"
                underlined
                multiline
                autoAdjustHeight
                resizable={false}
                readOnly
                value={opdracht.vrijeTekst}
            />
            <TextField
                autoComplete="off"
                styles={labelStyles}
                label="Uiterste datum gereed"
                underlined
                readOnly
                value={stringToLocaleDateString(opdracht.gewensteUitvoerdatum)}
            />
            <TextField
                autoComplete="off"
                styles={labelStyles}
                label="Soort opdracht"
                underlined
                readOnly
                value={OpdrachtSoortLabelMap[opdracht.soort]}
            />
            <TextField
                autoComplete="off"
                styles={labelStyles}
                label="Contractvorm"
                underlined
                readOnly
                value={OpdrachtVormLabelMap[opdracht.afspraakwijze]}
            />
            <TextField
                autoComplete="off"
                styles={labelStyles}
                label="Typering"
                underlined
                readOnly
                value={OpdrachtTyperingLabelMap[opdracht.typering]}
            />
            <TextField
                autoComplete="off"
                styles={labelStyles}
                label="Status"
                underlined
                readOnly
                value={OpdrachtStatusLabelMap[opdracht.status]}
            />
            <FluentToggle label="BTW verlegd" inlineLabel styles={toggleStyles} checked={opdracht.btwVerlegd}/>
            <TextField
                autoComplete="off"
                styles={labelStyles}
                label="Bouwjaar"
                underlined
                readOnly
                value={opdracht.locatieBouwjaar}
            />
            {!!opdracht.locatieComplexnummer && (
                <TextField
                    autoComplete="off"
                    styles={labelStyles}
                    label="Complex"
                    underlined
                    readOnly
                    value={opdracht.locatieComplexnummer}
                />
            )}
            {!!opdracht.locatieVgeNummer && (
                <TextField
                    autoComplete="off"
                    styles={labelStyles}
                    label="VGE nummer"
                    underlined
                    readOnly
                    value={opdracht.locatieVgeNummer}
                />
            )}
            {!!opdracht.locatiePandnummer && (
                <TextField
                    autoComplete="off"
                    styles={labelStyles}
                    label="Pandnummer"
                    underlined
                    readOnly
                    value={opdracht.locatiePandnummer}
                />
            )}
        
        </InputCard>
    );
};

export default Algemeen;
