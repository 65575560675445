import React, { useEffect, useMemo, useState } from 'react';
import {
  CommandBar,
  Dropdown,
  ICommandBarItemProps,
  Icon,
  IconButton,
  IDropdownOption,
  TextField,
  TooltipHost,
} from '@fluentui/react';
import './index.scss';

import { useAppDispatch, useTypedSelector } from 'store';
import './index.scss';
import commandBarStyles from 'styles/commandBarStyles';
import {
  getDistinctOpdrachtgevers,
  getOpdrachtNemerNamen,
} from 'store/selectors/opdrachten';
import { fetchOpdrachten, fetchOpdrachtenSetPending } from 'store/actions/opdrachten/data';
import { FilterOpdrachtGeverAlles, FilterOpdrachtNemerAlles } from 'lib/constants/dashboard';
import { getDashboardFilter } from 'store/selectors/dashboard';
import {
  setFilterOpdrachtGever,
  setFilterOpdrachtNemer,
  setFilterZoekterm,
} from 'store/actions/dashboard';

import JobsSection from './subComponents/jobsSection';

const GeplandeOpdrachten: React.FC<{}> = () => {
  const dispatch = useAppDispatch();

  const [refetch, setRefetch] = useState(false);

  const opdrachtgevers = useTypedSelector(getDistinctOpdrachtgevers);
  const opdrachtNemerNamen = useTypedSelector(getOpdrachtNemerNamen);
  const selectedFilter = useTypedSelector(getDashboardFilter);
  
  useEffect(() => {
    dispatch(fetchOpdrachtenSetPending());
    const fetchPromise = dispatch(fetchOpdrachten());

    return () => {
      fetchPromise.abort();
    };
  }, [dispatch]);

  useEffect(() => {
    if (refetch) {
      dispatch(fetchOpdrachtenSetPending());
      dispatch(fetchOpdrachten());
      setRefetch(false);
    }
  }, [dispatch, refetch]);

  const commandBarItems: ICommandBarItemProps[] = useMemo(
    () => [
      {
        key: 'pagetitle',
        onRender: () => (
          <div className="dashboard-title page-title">Geplande opdrachten</div>
        )
      },
    ],
    []
  );

  const commandBarItemsFar: ICommandBarItemProps[] = useMemo(
    () => [
      {
        key: 'refresh',
        text: 'Vernieuwen',
        iconProps: { iconName: 'Refresh', className: 'icon' },
        split: false,
        ariaLabel: 'Vernieuwen',
        onClick: () => setRefetch(true),
      },
    ],
    [setRefetch]
  );

  const corporatieFilterOptions: IDropdownOption[] = useMemo(() => {
    const options: IDropdownOption[] = [];
    options.push({ key: FilterOpdrachtGeverAlles, text: FilterOpdrachtGeverAlles });
    let remainingOpdrachtgevers = [...opdrachtgevers];
    for (const opdrachtgever of remainingOpdrachtgevers) {
      options.push({ key: opdrachtgever.id.toString(), text: opdrachtgever.naam });
    }
    return options;
  }, [opdrachtgevers]);

  const opdrachtNemersOptions: IDropdownOption[] = useMemo(() => {
    const options: IDropdownOption[] = [];
    options.push({ key: FilterOpdrachtNemerAlles, text: FilterOpdrachtNemerAlles });
    let remainingOpdrachtNemers = [...opdrachtNemerNamen];
    for (const opdrachtNemer of remainingOpdrachtNemers) {
      options.push({ key: opdrachtNemer, text: opdrachtNemer });
    }
    return options;
  }, [opdrachtNemerNamen]);

  return (
    <div className="dashboardWrapper">
      <CommandBar items={commandBarItems} farItems={commandBarItemsFar} styles={commandBarStyles} />
      <div className="dashboardHeader">
        <TooltipHost content='Filter op opdrachtgever'>
          <Dropdown options={corporatieFilterOptions}
            defaultSelectedKey={selectedFilter.opdrachtgeverId}
            className='dropdown-filter'
            onChange={(e, option) =>
              dispatch(setFilterOpdrachtGever(option?.key?.toString() ?? FilterOpdrachtGeverAlles))} />
        </TooltipHost>
        <TooltipHost content='Filter op opdrachtnemer naam'>
          <Dropdown options={opdrachtNemersOptions}
            defaultSelectedKey={selectedFilter.opdrachtNemerNaam}
            className='dropdown-filter'
            onChange={(e, option) =>
              dispatch(setFilterOpdrachtNemer(option?.key?.toString() ?? FilterOpdrachtNemerAlles))} />

        </TooltipHost>
        <TextField
          value={selectedFilter.zoekTerm}
          onChange={(e, value) => dispatch(setFilterZoekterm(value ?? ''))}
          onRenderSuffix={() => (
            <>
              {(selectedFilter?.zoekTerm ?? '').length === 0
                ? (<Icon iconName='Search'></Icon>)
                : (<IconButton iconProps={{ iconName: 'Clear' }} onClick={() => dispatch(setFilterZoekterm(''))} />)}
            </>
          )}
          className='zoekterm'
        />
      </div>
      <JobsSection />
    </div>
  );
};

export default GeplandeOpdrachten;