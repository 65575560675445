import React, { useMemo } from 'react'
import { ActivityItem, IActivityItemProps, ILinkStyles, IStackStyles, Link, Stack } from '@fluentui/react'
import { useTypedSelector } from 'store'
import { getOpdrachtGebeurtenissen } from 'store/selectors/opdrachtGebeurtenissen'
import InputCard from 'components/InputCard'
import { getImageInitialsFromName } from 'lib/graph'
import { dateToLocaleString } from 'lib/internationalization'
import primaryTheme from 'themes/primary'
import { OpdrachtActions } from 'enums/opdrachtAction'
import { getGebeurtenisregelOmschrijving } from 'interfaces/opdrachtGebeurtenis'

const timelineWrapperStackStyles: Partial<IStackStyles> = {
  root: {
    boxSizing: 'border-box',
    display: 'block',
    flexGrow: 1,
  },
  inner: {
    padding: 10,
    display: 'flex',
    height: 'auto',
  },
}

const activityStackItemStyles: Partial<IStackStyles> = {
  root: {
    padding: 10,
    ':hover': {
      background: '#efefef',
    },
  },
  inner: {},
}

const activityItemLinkStyles: Partial<ILinkStyles> = {
  root: {
    fontWeight: 'bold',
    color: primaryTheme.palette.themePrimary,
  },
}

const gebeurtenisStyles: React.CSSProperties = {
  fontWeight: 'bold',
}

const omschrijvingStyles: React.CSSProperties = {
  fontWeight: 600,
}

const toelichtingStyles: React.CSSProperties = {
  fontStyle: 'italic',
}

interface ITimelineProps {
  opdrachtId: number
}

const Timeline: React.FC<ITimelineProps> = ({ opdrachtId }) => {
  const gebeurtenissen = useTypedSelector(state => getOpdrachtGebeurtenissen(state, opdrachtId))

  const activityItems = useMemo((): Partial<IActivityItemProps & React.ClassAttributes<{}>>[] => {
    return gebeurtenissen.map(
      gebeurtenis =>
        ({
          key: gebeurtenis.id,
          activityPersonas: [
            {
              imageInitials: getImageInitialsFromName(gebeurtenis.gebruikersnaam),
              text: gebeurtenis.gebruikersnaam,
            },
          ],
          activityDescription: [
            <Link key={1} styles={activityItemLinkStyles}>
              {gebeurtenis.gebruikersnaam}
            </Link>,
            <span key={2}> heeft deze opdracht </span>,
            <span key={3} style={gebeurtenisStyles}>
              {gebeurtenis.actie.toLowerCase()}.
            </span>,
          ],
          timeStamp: dateToLocaleString(new Date(gebeurtenis.datum)),
          onRenderComments: (_, defaultRender) => {
            if (gebeurtenis.actie === OpdrachtActions.Geaccepteerd) return null

            return (
              <>
                {gebeurtenis.regels.map(regel => (
                  <div key={regel.id}>
                    <span style={omschrijvingStyles}>{getGebeurtenisregelOmschrijving(regel.actie)}</span>
                    {regel.toelichting && (
                      <div>
                        <span style={toelichtingStyles}>
                          {` > `}
                          {regel.toelichting}
                        </span>
                      </div>
                    )}
                  </div>
                ))}
              </>
            )
          },
        } as Partial<IActivityItemProps & React.ClassAttributes<{}>>)
    )
  }, [gebeurtenissen])

  return (
    <Stack horizontal wrap tokens={{ childrenGap: 10 }} styles={timelineWrapperStackStyles}>
      <InputCard title="Tijdlijn" style={{ width: 500 }}>
        <Stack>
          {activityItems.map(item => (
            <Stack.Item key={item.key} styles={activityStackItemStyles}>
              <ActivityItem {...(item as IActivityItemProps)} />
            </Stack.Item>
          ))}
        </Stack>
      </InputCard>
    </Stack>
  )
}

export default Timeline
