import React, { useEffect, useState } from 'react';
import { Image, ImageFit, ImageLoadState, Shimmer, Stack } from '@fluentui/react';
import { IBijlageDetails } from 'interfaces/bijlage';
import { useAppDispatch } from 'store';
import { invokeFetchImage } from 'services/apiClient';

interface IImageCardProps {
  imageDetails: IBijlageDetails
  onClick: (item: IBijlageDetails) => void
}

const ImageCard: React.FC<IImageCardProps> = ({ imageDetails, onClick }) => {
    const [loading, setLoading] = useState(true);
    const [img, setImg] = useState('');
    const dispatch = useAppDispatch()

    const fetchImage = async () => {
        
        invokeFetchImage(imageDetails.bijlageId).then((blob) => {
            const url = URL.createObjectURL(blob);
            imageDetails.url = url;
            setImg(url);
            setLoading(false);
        }).catch((error) => {
            console.error(error);
        });
    };

    useEffect(() => {
        dispatch(fetchImage);
    }, [dispatch]);

    function handleImageClick(): any {
        const details = {...imageDetails, url: img };
        onClick(details);
    }

    return (
    <Stack.Item styles={{ root: { margin: 5 } }}>
      <Stack
        styles={{
          root: {
            borderRadius: 5,
            width: 200,
            ':hover': {
              background: '#efefef',
              boxShadow: `#efefef 0px 0px 9px -1px`,
            },
          },
        }}
      >
        <Stack.Item styles={{ root: { height: 200, width: 200 } }}>
          <Shimmer
            isDataLoaded={!loading}
            styles={{ root: { height: '100%' }, shimmerWrapper: { height: '100%', borderRadius: 5 } }}
          >
            {img && (
              <Image
                src={img}
                width={200}
                height={200}
                imageFit={ImageFit.cover}
                styles={{ root: { borderRadius: 5, cursor: 'pointer' } }}
                onClick={handleImageClick}
                onLoadingStateChange={loadState => {
                  setTimeout(() => {
                    setLoading(loadState !== ImageLoadState.loaded)
                  }, 600)
                }}
              />
            )}
          </Shimmer>
        </Stack.Item>
      </Stack>
    </Stack.Item>
  )
}

export default ImageCard
