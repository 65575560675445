import React from 'react'
import { DatePicker, DayOfWeek, IDatePickerStrings, mergeStyleSets, IDatePickerProps } from '@fluentui/react'
import { dateToLocaleDateString } from 'lib/internationalization'

const DayPickerStrings: IDatePickerStrings = {
  months: [
    'Januari',
    'Februari',
    'Maart',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Augustus',
    'September',
    'Oktober',
    'November',
    'December',
  ],

  shortMonths: ['Jan', 'Feb', 'Mrt', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],

  days: ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'],

  shortDays: ['Z', 'M', 'D', 'W', 'D', 'V', 'Z'],

  goToToday: 'Naar vandaag',
  prevMonthAriaLabel: 'Naar vorige maand',
  nextMonthAriaLabel: 'Naar volgende maand',
  prevYearAriaLabel: 'Naar vorig jaar',
  nextYearAriaLabel: 'Naar volgend jaar',
  closeButtonAriaLabel: 'Datumkiezer sluiten',
  monthPickerHeaderAriaLabel: '{0}, selecteer om de maand te wijzigen',
  yearPickerHeaderAriaLabel: '{0}, selecteer om het jaar te wijzigen',

  isRequiredErrorMessage: 'Dit veld is verplicht.',

  invalidInputErrorMessage: 'Verkeerd datumformaat.',

  //isOutOfBoundsErrorMessage: `Date must be between ${minDate.toLocaleDateString()}-${maxDate.toLocaleDateString()}`
}

const controlClass = mergeStyleSets({
  control: {
    margin: '0 0 15px 0',
  },
})

const formatDate = (date?: Date) => dateToLocaleDateString(date) || ''

const FluentDatePicker: React.FC<IDatePickerProps> = props => {
  return (
    <div>
      <DatePicker
        className={controlClass.control}
        firstDayOfWeek={DayOfWeek.Monday}
        strings={DayPickerStrings}
        placeholder="Kies een datum..."
        ariaLabel="Kies een datum"
        formatDate={formatDate}
        {...props}
      />
    </div>
  )
}

export default FluentDatePicker
