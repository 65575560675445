import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import './index.scss'
import { INavLinkGroup, INavStyles, IRenderFunction, IRenderGroupHeaderProps, Nav, Text } from '@fluentui/react'

const navStyles: Partial<INavStyles> = {
  root: {
    paddingTop: '20px',
    height: '100%',
    width: 201,
    boxSizing: 'border-box',
    border: '1px solid #eee',
    overflowY: 'auto',
    display: 'flex',
  },
  group: {
    width: '100%',
    h3: {
      paddingLeft: '5px',
    },
  },
}

const onRenderGroupHeader: IRenderFunction<IRenderGroupHeaderProps> = props =>
  props ? (
    <Text variant="large" styles={{ root: { fontWeight: 'bold' } }} as="h3">
      {props.name}
    </Text>
  ) : null

const Navbar: React.FC = () => {
  const history = useHistory()
  const navLinkGroups: INavLinkGroup[] = useMemo(
    () => [
      {
        name: 'Opdrachten',
        links: [
          { name: 'Onderhanden', key: 'Opdrachten', onClick: () => history.push('/opdrachten') },
          { name: 'Gepland', key: 'Gepland', onClick: () => history.push('/gepland') },
          { name: 'Afgesloten', key: 'Afgesloten', onClick: () => history.push('/afgesloten') },
        ],
      } as INavLinkGroup,
    ],
    [history]
  )

  return (<Nav className="nav" styles={navStyles} onRenderGroupHeader={onRenderGroupHeader} groups={navLinkGroups} />)
}

export default Navbar
