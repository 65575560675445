import React from 'react';
import InputCard from 'components/InputCard';
import { IOpdracht } from 'interfaces/opdracht';
import { Icon, ITextFieldStyles, TextField } from '@fluentui/react';
import Call from 'components/Call';
import OpenMap from 'components/OpenMap';
import MailTo from 'components/MailTo';
import primaryTheme from 'themes/primary';

const renderMailTo = (email: string): JSX.Element => {
    return (
        <MailTo email={email}>
            <Icon iconName="mail" styles={{root: {color: primaryTheme.palette.themePrimary}}}/>
        </MailTo>
    );
};

const renderCall = (number: string): JSX.Element => {
    return (
        <Call phoneNumber={number}>
            <Icon iconName="phone" styles={{root: {color: primaryTheme.palette.themePrimary}}}/>
        </Call>
    );
};

const renderMap = (street: string, city: string, houseNumber?: string): JSX.Element => {
    return (
        <OpenMap street={street} city={city} houseNumber={houseNumber}>
            <Icon iconName="mapPin" styles={{root: {color: primaryTheme.palette.themePrimary}}}/>
        </OpenMap>
    );
};

interface IContactgegevensProps {
    opdracht: IOpdracht;
    labelWidth: number;
    labelStyles: Partial<ITextFieldStyles>;
}

const Contactgegevens: React.FC<IContactgegevensProps> = ({opdracht, labelWidth, labelStyles}) => {
    return (
        <InputCard title="Onderhoudslocatie & contactgegevens" >
            <TextField
                autoComplete="off"
                styles={labelStyles}
                label="Straat"
                underlined
                readOnly
                value={`${opdracht.straatnaam} ${opdracht.huisnummer || ''} ${opdracht.huisletter || ''} ${
                    opdracht.huisnummerToevoeging || ''
                }`}
                onRenderSuffix={_ =>
                    renderMap(
                        opdracht.straatnaam,
                        opdracht.plaats,
                        opdracht.huisnummer ? `${opdracht.huisnummer}${opdracht.huisletter || ''}` : ''
                    )
                }
            />
            <TextField
                autoComplete="off"
                styles={labelStyles}
                label="PC & Plaats"
                underlined
                readOnly
                value={`${opdracht.postcode}  ${opdracht.plaats}`}
            />
            <TextField
                autoComplete="off"
                styles={labelStyles}
                underlined
                readOnly
                value={"  "}
            />
            <TextField
                autoComplete="off"
                styles={labelStyles}
                label="Contactpersoon"
                underlined
                readOnly
                value={opdracht.contactpersoon}
            />
            {opdracht.telefoonnummer1 && (
                <TextField
                    autoComplete="off"
                    styles={labelStyles}
                    label="Telefoonnummer"
                    underlined
                    readOnly
                    value={opdracht.telefoonnummer1}
                    onRenderSuffix={props => renderCall(opdracht.telefoonnummer1!)}
                />
            )}
            {opdracht.telefoonnummer2 && (
                <TextField
                    autoComplete="off"
                    styles={labelStyles}
                    label="Telefoonnummer"
                    underlined
                    readOnly
                    value={opdracht.telefoonnummer2}
                    onRenderSuffix={props => renderCall(opdracht.telefoonnummer2!)}
                />
            )}
            {opdracht.emailadres1 && (
                <TextField
                    autoComplete="off"
                    styles={labelStyles}
                    label="Email"
                    type="email"
                    underlined
                    readOnly
                    value={opdracht.emailadres1}
                    onRenderSuffix={_ => renderMailTo(opdracht.emailadres1!)}
                />
            )}
            {opdracht.emailadres2 && (
                <TextField
                    autoComplete="off"
                    styles={labelStyles}
                    label="Email"
                    type="email"
                    underlined
                    readOnly
                    value={opdracht.emailadres2}
                    onRenderSuffix={_ => renderMailTo(opdracht.emailadres2!)}
                />
            )}

        </InputCard>
    );
};

export default Contactgegevens;
