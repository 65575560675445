import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    CommandBar,
    Dropdown,
    ICommandBarItemProps,
    Icon,
    IconButton,
    IDropdownOption,
    IStackStyles,
    Spinner,
    SpinnerSize,
    Stack,
    TextField,
    TooltipHost,
} from '@fluentui/react';

import './index.scss';

import { useAppDispatch, useTypedSelector } from 'store';

import commandBarStyles from 'styles/commandBarStyles';
import {
    getDistinctOpdrachtgevers,
    getOpdrachtenForExecuteLane,
    getOpdrachtenForOnHoldLane,
    getOpdrachtenForPlanLane,
    getOpdrachtenForStartLane,
    getOpdrachtNemerNamen,
} from 'store/selectors/opdrachten';
import { fetchOpdrachten, fetchOpdrachtenSetPending } from 'store/actions/opdrachten/data';
import { DashboardLanes } from 'enums/dashboardLanes';
import { FilterOpdrachtGeverAlles, FilterOpdrachtNemerAlles } from 'lib/constants/dashboard';
import OpdrachtCard from './OpdrachtCard';
import Lane from './Lane';
import { getDashboardFilter, getSortInfoByLaneId } from 'store/selectors/dashboard';
import {
    setFilterOpdrachtGever,
    setFilterOpdrachtNemer,
    setFilterZoekterm,
    setLaneSortInfo
} from 'store/actions/dashboard';
import { ISetLaneSortInfo } from 'interfaces/laneSortInfo';
import { getPropertyName } from 'lib/interfaceUtils';
import { IOpdracht } from 'interfaces/opdracht';
import { ISortableColumn } from 'interfaces/sortableColumn';

const lanesStyles: IStackStyles = {
    root: {
        padding: '16px',
        height: '100%',
        width: '100%',
        overflowX: 'auto',
        overflowY: 'hidden',
        backgroundColor: '#f7f7f7'
    },
};

const laneStyle: IStackStyles = {
    root: {
        width: '100%',
        height: '100%',
        minWidth: 300,
        maxWidth: 380,

    }
};

const sortableColumns: ISortableColumn[] = [
    {
        key: getPropertyName<IOpdracht>('berichtDatum'),
        text: 'Ontvangstdatum',
        dataType: 'date',
    },
    {
        key: getPropertyName<IOpdracht>('gewensteUitvoerdatum'),
        text: 'Uiterste gereeddatum',
        dataType: 'date',
    },
];

const OnderhandenOpdrachten: React.FC<{}> = props => {
    const history = useHistory();
    const dispatch = useAppDispatch();

    const [refetch, setRefetch] = useState(false);

    const status = useTypedSelector(state => state.opdrachten.data.status);

    const toStart = useTypedSelector(getOpdrachtenForStartLane);
    const toPlan = useTypedSelector(getOpdrachtenForPlanLane);
    const toExecute = useTypedSelector(getOpdrachtenForExecuteLane);
    const onHold = useTypedSelector(getOpdrachtenForOnHoldLane);
    const opdrachtgevers = useTypedSelector(getDistinctOpdrachtgevers);
    const opdrachtNemerNamen = useTypedSelector(getOpdrachtNemerNamen);
    const selectedFilter = useTypedSelector(getDashboardFilter);
    const toStartSortInfo = useTypedSelector(state => getSortInfoByLaneId(state, DashboardLanes.Starten));
    const toPlanSortInfo = useTypedSelector(state => getSortInfoByLaneId(state, DashboardLanes.Plannen));
    const toExecuteSortInfo = useTypedSelector(state => getSortInfoByLaneId(state, DashboardLanes.Uitvoeren));
    const onHoldSortInfo = useTypedSelector(state => getSortInfoByLaneId(state, DashboardLanes.Afwachten));

    useEffect(() => {
        dispatch(fetchOpdrachtenSetPending());
        const fetchPromise = dispatch(fetchOpdrachten());

        return () => {
            fetchPromise.abort();
        };
    }, [dispatch]);

    useEffect(() => {
        if (refetch) {
            dispatch(fetchOpdrachtenSetPending());
            dispatch(fetchOpdrachten());
            setRefetch(false);
        }
    }, [dispatch, refetch]);

    const commandBarItems: ICommandBarItemProps[] = useMemo(
        () => [
            {
                key: 'pagetitle',
                onRender: () => (
                    <div className="dashboard-title page-title">Onderhanden opdrachten</div>
                )
            },
        ],
        []
    );

    const commandBarItemsFar: ICommandBarItemProps[] = useMemo(
        () => [
            {
                key: 'refresh',
                text: 'Vernieuwen',
                iconProps: {iconName: 'Refresh', className: 'icon'},
                split: false,
                ariaLabel: 'Vernieuwen',
                onClick: () => setRefetch(true),
            },
        ],
        [setRefetch]
    );

    const corporatieFilterOptions: IDropdownOption[] = useMemo(() => {
        const options: IDropdownOption[] = [];
        options.push({key: FilterOpdrachtGeverAlles, text: FilterOpdrachtGeverAlles});
        let remainingOpdrachtgevers = [...opdrachtgevers];
        for (const opdrachtgever of remainingOpdrachtgevers) {
            options.push({key: opdrachtgever.id.toString(), text: opdrachtgever.naam});
        }
        return options;
    }, [opdrachtgevers]);

    const opdrachtNemersOptions: IDropdownOption[] = useMemo(() => {
        const options: IDropdownOption[] = [];
        options.push({key: FilterOpdrachtNemerAlles, text: FilterOpdrachtNemerAlles});
        let remainingOpdrachtNemers = [...opdrachtNemerNamen];
        for (const opdrachtNemer of remainingOpdrachtNemers) {
            options.push({key: opdrachtNemer, text: opdrachtNemer});
        }
        return options;
    }, [opdrachtNemerNamen]);

    const handleCardClick = useCallback(
        (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            const id = e.currentTarget.id;
            history.push(`/opdrachten/${id}`);
        },
        [history]
    );

    const handleSetSortInfo = useCallback(
        (sortInfo: ISetLaneSortInfo) => {
            dispatch(setLaneSortInfo(sortInfo));
        },
        [dispatch]
    );


    return (
        <div className="dashboardWrapper">
            <CommandBar items={commandBarItems} farItems={commandBarItemsFar} styles={commandBarStyles}/>
            <div className="dashboardHeader">
                <TooltipHost content='Filter op opdrachtgever'>
                    <Dropdown options={corporatieFilterOptions}
                              defaultSelectedKey={selectedFilter.opdrachtgeverId}
                              className='dropdown-filter'
                              onChange={(e, option) =>
                                  dispatch(setFilterOpdrachtGever(option?.key?.toString() ?? FilterOpdrachtGeverAlles))}/>
                </TooltipHost>
                <TooltipHost content='Filter op opdrachtnemer naam'>
                    <Dropdown options={opdrachtNemersOptions}
                              defaultSelectedKey={selectedFilter.opdrachtNemerNaam}
                              className='dropdown-filter'
                              onChange={(e, option) =>
                                  dispatch(setFilterOpdrachtNemer(option?.key?.toString() ?? FilterOpdrachtNemerAlles))}/>
                    
                </TooltipHost>
                <TextField
                    value={selectedFilter.zoekTerm}
                    onChange={(e, value) => dispatch(setFilterZoekterm(value ?? ''))}
                    onRenderSuffix={() => (
                        <>
                            {(selectedFilter?.zoekTerm ?? '').length === 0 
                                ? (<Icon iconName='Search'></Icon>) 
                                : (<IconButton iconProps={{iconName: 'Clear'}} onClick={() => dispatch(setFilterZoekterm(''))}/>)}
                        </>
                    )}
                    className='zoekterm'
                />
            </div>
            {status === 'pending' && (
                <div className="spinnerWrapper">
                    <Spinner size={SpinnerSize.large}/>
                </div>
            )}
            {status !== 'pending' &&
                (
                    <Stack horizontal tokens={{childrenGap: 20, padding: 5}} styles={lanesStyles}>
                        <Stack grow={3} styles={laneStyle}>
                            <Lane
                                laneId={DashboardLanes.Starten}
                                count={toStart.length}
                                sortInfo={toStartSortInfo}
                                onSetSortInfo={handleSetSortInfo}
                                sortableColumns={sortableColumns}
                            >
                                {toStart.map(item => (
                                    <OpdrachtCard key={item.id} lane={DashboardLanes.Starten}
                                                  onCardClick={handleCardClick}
                                                  opdracht={item}/>
                                ))}
                            </Lane>
                        </Stack>
                        <Stack grow={3} styles={laneStyle}>
                            <Lane
                                laneId={DashboardLanes.Plannen}
                                count={toPlan.length}
                                sortInfo={toPlanSortInfo}
                                onSetSortInfo={handleSetSortInfo}
                                sortableColumns={sortableColumns}
                            >
                                {toPlan.map(item => (
                                    <OpdrachtCard key={item.id} lane={DashboardLanes.Plannen}
                                                  onCardClick={handleCardClick}
                                                  opdracht={item}/>
                                ))}
                            </Lane>
                        </Stack>
                        <Stack grow={3} styles={laneStyle}>
                            <Lane
                                laneId={DashboardLanes.Uitvoeren}
                                count={toExecute.length}
                                sortInfo={toExecuteSortInfo}
                                onSetSortInfo={handleSetSortInfo}
                                sortableColumns={sortableColumns}
                            >
                                {toExecute.map(item => (
                                    <OpdrachtCard key={item.id} lane={DashboardLanes.Uitvoeren}
                                                  onCardClick={handleCardClick}
                                                  opdracht={item}/>
                                ))}
                            </Lane>
                        </Stack>
                        <Stack grow={3} styles={laneStyle}>
                            <Lane
                                laneId={DashboardLanes.Afwachten}
                                count={onHold.length}
                                sortInfo={onHoldSortInfo}
                                onSetSortInfo={handleSetSortInfo}
                                sortableColumns={sortableColumns}
                            >
                                {onHold.map(item => (
                                    <OpdrachtCard key={item.id} lane={DashboardLanes.Afwachten}
                                                  onCardClick={handleCardClick}
                                                  opdracht={item}/>
                                ))}
                            </Lane>
                        </Stack>
                    </Stack>
                )
            }

        </div>
    );
};

export default OnderhandenOpdrachten;
