import * as y from 'yup';
import * as m from 'lib/constants/validationMessages';
import { OpdrachtStatus } from 'enums/opdrachtStatus';
import { PlanReasons } from 'enums/planReasons';
import { PauseReasons } from 'enums/pauseReasons';
import { OpdrachtSoort } from 'enums/opdrachtSoort';
import { IBijlage } from './bijlage';
import { OpdrachtTypering } from 'enums/opdrachtTypering';
import { CompleteReasons } from 'enums/completeReasons';
import { IOpdrachtGebeurtenis } from './opdrachtGebeurtenis';

export enum OpdrachtAfspraakwijze {
    REG = 'Regie',
    AFK = 'Afkoop',
    EHP = 'Eenheidsprijs',
    ANS = 'Aanneemsom',
}

export interface IOpdrachtgever {
    id: number;
    naam: string;
}

export interface IOpdracht {
    id: number;
    berichtNummer: string;
    berichtDatum: string;
    btwVerlegd?: boolean;
    nummer: string;
    subnummer?: string;
    soort: OpdrachtSoort;
    afspraakwijze: OpdrachtAfspraakwijze;
    typering: OpdrachtTypering;
    straatnaam: string;
    postcode: string;
    plaats: string;
    huisnummer?: string;
    huisletter?: string;
    huisnummerToevoeging?: string;
    contactpersoon?: string;
    telefoonnummer1?: string;
    telefoonnummer2?: string;
    emailadres1?: string;
    emailadres2?: string;
    gewensteUitvoerdatum?: string;
    vroegsteUitvoerdatum?: string;
    laatsteUitvoerdatum?: string;
    longitude?: string;
    latitude?: string;
    vrijeTekst?: string;
    offerteNummerAannemer?: string;
    orderNummerAannemer?: string;
    afspraakVan?: string;
    afspraakTot?: string;
    datumGereed?: string;
    status: OpdrachtStatus;
    buyerGln?: string;
    contractorGln?: string;
    opdrachtgever: IOpdrachtgever;
    partijOpdrachtGever?: OpdrachtPartij;
    partijOpdrachtNemer?: OpdrachtPartij;
    documentenCount: number;
    fotosCount: number;
    externeDocumentenCount: number;
    bijlages?: IBijlage[];
    regels: IOpdrachtregel[];
    gebeurtenissen: IOpdrachtGebeurtenis[];
    locatieComplexnummer?: string;
    locatiePandnummer?: string;
    locatieVgeNummer?: string;
    locatieBouwjaar?: string;
}

export interface IOpdrachtregel {
    id: number;
    opdrachtId: number;
    regelnummer: string;
    aantal: number;
    eenheid: string;
    normprijscode?: string;
    omschrijving?: string;
    omschrijvingLang?: string;
    vrijeTekst?: string;
    locatie?: string;
    element?: string;
    defect?: string;
    oorzaak?: string;
    prijs?: number;
    prijsbasis?: number;
    prijseenheid?: string;
    prijseenheidomschrijving?: string;
    bijlages?: IBijlage[];
}

export interface OpdrachtPartij {
    id: number;
    gln: string;
    naam: string;
    naam2: string;
    straatnaam: string;
    huisnummer: string;
    huisletter: string;
    huisnummerToevoeging: string;
    postcode: string;
    plaats: string;
    land: string;
    contactgegevens: OpdrachtPartijContactgegevens[];
}

export interface OpdrachtPartijContactgegevens {
    contactpersoon: string;
    telefoonnummer: string;
    emailadres: string;
}

export interface IPlanOpdracht {
    id: number;
    afspraakVan: string;
    afspraakTot: string;
    redencode?: PlanReasons;
    toelichting?: string;
}

export interface IPlanOpdrachtForm {
    afspraakDatum: Date | null;
    afspraakVan: string;
    afspraakTot: string;
    naLaatsteUitvoerdatum: boolean;
    redencode?: PlanReasons;
    toelichting?: string;
}

const timeRegex = /^(?:\d|[01]\d|2[0-3]):[0-5]\d$/;
const toelichtingMaxLength = 500;

export const planOpdrachtSchema = () => {
    const now = new Date();
    const dateWithoutHours = new Date(now);
    dateWithoutHours.setHours(0, 0, 0, 0);

    return y.object({
        afspraakDatum: y.date().required(m.REQUIRED).min(dateWithoutHours, m.FUTURE_DATE),
        afspraakVan: y
            .string()
            .matches(timeRegex, m.TIME)
            .required(m.REQUIRED)
            .test('checkTimeInThePast', m.FUTURE_TIME, (value, {options}) => {
                const {context} = options;
                if (!context || !value) return false;
                const opdracht = (context['opdracht'] || {}) as IPlanOpdrachtForm;

                // Check if the selected date is today.
                const compareDate = new Date(opdracht.afspraakDatum!);
                compareDate.setHours(0, 0, 0, 0);

                if (dateWithoutHours.getTime() === compareDate.getTime()) {
                    const [hours, minutes] = value.split(':');

                    if (parseInt(hours) < now.getHours()) return false;
                    if (parseInt(hours) === now.getHours() && parseInt(minutes) < now.getMinutes()) return false;
                }

                return true;
            }),
        afspraakTot: y
            .string()
            .matches(timeRegex, m.TIME)
            .required(m.REQUIRED)
            .test('checkTimeNotBeforeStartTime', 'Tijd tot mag niet voor de starttijd liggen', (value, {options}) => {
                const {context} = options;
                if (!context || !value) return false;
                const opdracht = (context['opdracht'] || {}) as IPlanOpdrachtForm;

                const {afspraakVan} = opdracht;
                if (!afspraakVan) return false;

                const [hoursFrom, minutesFrom] = opdracht.afspraakVan.split(':');
                const [hours, minutes] = value.split(':');

                if (parseInt(hours) < parseInt(hoursFrom)) return false;
                if (parseInt(hours) === parseInt(hoursFrom) && parseInt(minutes) < parseInt(minutesFrom)) return false;

                return true;
            }),
        naLaatsteUitvoerdatum: y.boolean().default(false).required(),
        redencode: y.string().when('naLaatsteUitvoerdatum', {
            is: true,
            then: y.string().required(m.REQUIRED),
        }),
        toelichting: y.string().when('naLaatsteUitvoerdatum', {
            is: true,
            then: y.string().required(m.REQUIRED).max(toelichtingMaxLength, m.STR_MAX_LENGTH_500),
        }),
    });
};

export interface IPauseOpdrachtForm {
    redencode?: PauseReasons;
    toelichting?: string;
}

export const pauseOpdrachtSchema = y.object({
    redencode: y.string().required(m.REQUIRED),
    toelichting: y.string().required(m.REQUIRED).max(toelichtingMaxLength, m.STR_MAX_LENGTH_500),
});

export interface IRejectOpdrachtForm {
    toelichting: string;
}

export const rejectOpdrachtSchema = y.object({
    toelichting: y.string().required(m.REQUIRED).max(toelichtingMaxLength, m.STR_MAX_LENGTH_500),
});

export interface ICancelOpdrachtForm {
    toelichting: string;
}

export const cancelOpdrachtSchema = y.object({
    toelichting: y.string().required(m.REQUIRED).max(toelichtingMaxLength, m.STR_MAX_LENGTH_500),
});

export interface IUpdateOpdrachtReferentiesForm {
    offertenummer?: string;
    ordernummer?: string;
}

export const updateOpdrachtReferentiesSchema = y.object({
    offertenummer: y.string().max(20, m.STR_MAX_LENGTH_20),
    ordernummer: y.string().max(20, m.STR_MAX_LENGTH_20),
});

export interface ICompleteOpdrachtForm {
    redencode?: CompleteReasons;
    toelichting?: string;
}

export const completeOpdrachtSchema = y.object({
    redencode: y.string().required(m.REQUIRED),
    toelichting: y.string().required(m.REQUIRED).max(toelichtingMaxLength, m.STR_MAX_LENGTH_500),
});
