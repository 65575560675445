import React from 'react';
import { CommandBarButton, Text } from '@fluentui/react';
import './index.scss';

interface IInputCardProps {
    title: string
    className?: string
    style?: React.CSSProperties
    contentStyle?: React.CSSProperties,
    showAddButton?: boolean,
    addButtonTitle?: string,
    onAddButtonClick?: () => void
}

const InputCardButton: React.FC<IInputCardProps> = ({
                                                        title,
                                                        className,
                                                        children,
                                                        style = {},
                                                        contentStyle = {},
                                                        showAddButton,
                                                        addButtonTitle,
                                                        onAddButtonClick
                                                    }) => {
    const onButtonClick = (): any => {
        if (onAddButtonClick) {
            onAddButtonClick();
        }
    };
    return (
        <div className={`inputCard ${className}`} style={style}>
            <div className="inputCardHeader flex-container">
                <Text as="h2" variant="large">
                    {title}
                </Text>
                {showAddButton &&
                    <CommandBarButton iconProps={{iconName: 'Add'}} styles={{ root: { height: 44 } }} onClick={onButtonClick}>{addButtonTitle}</CommandBarButton>
                }
            </div>
            <div className="inputCardContent" style={contentStyle}>
                {children}
            </div>
        </div>
    );
};

export default InputCardButton;
