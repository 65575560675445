import React from 'react'
import {
  ConstrainMode,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  ScrollbarVisibility,
  Spinner,
  SpinnerSize,
  StickyPositionType,
  Sticky,
  ScrollablePane,
  Selection,
  IDetailsListStyles,
  mergeStyles,
  FontSizes,
  FontWeights,
  SelectionMode,
  IDetailsListProps,
} from '@fluentui/react'
import { AutoSizer } from 'react-virtualized'

import './index.scss'

const styles: Partial<IDetailsListStyles> = {
  root: {
    display: 'flex',
    width: '100%',
  },
}

const headerStyles = mergeStyles({
  displayName: 'CustomColumn',
  span: {
    fontSize: FontSizes.size12,
    fontWeight: FontWeights.regular,
  },
})

const cellStyles = mergeStyles({
  displayName: 'CustomColumn',
  span: {
    fontSize: FontSizes.size12,
    fontWeight: FontWeights.regular,
  },
})

interface IFluentTableState {
  columns: Array<IColumn>
  items: Array<any>
  loading: boolean
}

interface IFluentTableProps {
  columns: Array<IColumn>
  items: Array<any> | []
  loading: boolean
  disableScroll?: boolean
  detailsListStyles?: Partial<IDetailsListStyles>
  detailsListProps?: Partial<IDetailsListProps>
  onSelectionChanged?: (ids: string[]) => void
  onItemInvoked: (id: string) => void
  onGetKey: (item: any) => string
}

export default class FluentTable extends React.Component<IFluentTableProps, IFluentTableState> {
  private _listSelection: Selection

  constructor(props) {
    super(props)

    this._listSelection = new Selection({
      onSelectionChanged: this.handleSelectionChanged,
    })

    const sortBy = this.props.columns.find(column => column.isSorted)
    const items = sortBy ? copyAndSort(this.props.items, sortBy.key, sortBy.isSortedDescending) : this.props.items

    this.state = {
      items,
      columns: props.columns.map(
        (col: IColumn) =>
          ({
            ...col,
            onColumnClick: this.handleColumnClick,
            headerClassName: headerStyles,
            className: cellStyles,
          } as IColumn)
      ),
      loading: props.loading,
    }
  }

  static getDerivedStateFromProps(nextProps: IFluentTableProps, prevState: IFluentTableState): IFluentTableState {
    // This logic decides whether or not the component should rerender when it receives new props.
    let nextState = {} as IFluentTableState

    if (nextProps.loading !== prevState.loading || nextProps.items.length !== prevState.items.length) {
      nextState.items = nextProps.items
    }

    return nextState
  }

  render() {
    const { loading, onSelectionChanged, detailsListStyles, detailsListProps, disableScroll = false } = this.props
    const { items, columns } = this.state

    const detailsList = (
      <DetailsList
        selection={this._listSelection}
        items={items!}
        columns={columns}
        layoutMode={DetailsListLayoutMode.justified}
        selectionPreservedOnEmptyClick={true}
        onItemInvoked={this.handleItemInvoked}
        getKey={this.handleGetKey}
        constrainMode={ConstrainMode.unconstrained}
        styles={{
          ...styles,
          ...detailsListStyles,
        }}
        selectionMode={onSelectionChanged ? SelectionMode.multiple : SelectionMode.none}
        onRenderDetailsHeader={(headerProps, defaultRender) => {
          return (
            <Sticky
              stickyPosition={StickyPositionType.Header}
              isScrollSynced={true}
              stickyBackgroundColor="transparent"
            >
              <div>{defaultRender && defaultRender(headerProps)}</div>
            </Sticky>
          )
        }}
        {...detailsListProps}
      />
    )

    return (
      <div className="fluentTableWrapper">
        {loading && (
          <div className="spinnerWrapper">
            <Spinner size={SpinnerSize.large} />
          </div>
        )}
        {!loading && disableScroll && <>{detailsList}</>}
        {!loading && !disableScroll && (
          <AutoSizer>
            {({ height, width }) => (
              <div style={{ display: 'flex', position: 'relative', height, width }}>
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>{detailsList}</ScrollablePane>
              </div>
            )}
          </AutoSizer>
        )}
      </div>
    )
  }

  private handleSelectionChanged = () => {
    const { onSelectionChanged, onGetKey } = this.props
    const ids: string[] = []

    this._listSelection.getSelection().forEach(item => ids.push(onGetKey(item)))
    onSelectionChanged && onSelectionChanged(ids)
  }

  private handleItemInvoked = item => {
    const { onGetKey, onItemInvoked } = this.props
    const id = onGetKey(item)
    id && onItemInvoked(id)
  }

  private handleGetKey = (item: any): string => {
    const { onGetKey } = this.props
    const id = onGetKey(item)
    return id ? id.toString() : ''
  }

  private handleColumnClick = (_, column: IColumn): void => {
    const { columns, items } = this.state
    const newColumns: IColumn[] = columns.slice()
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0]

    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending
        currColumn.isSorted = true
      } else {
        newCol.isSorted = false
        newCol.isSortedDescending = true
      }
    })

    const newItems = copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending)
    this.setState({
      columns: newColumns,
      items: newItems,
    })
  }
}

function copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  const key = columnKey as keyof T
  return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1))
}
