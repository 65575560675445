import React  from 'react';
import { IOpdracht } from 'interfaces/opdracht';
import { ITextFieldStyles, TextField } from '@fluentui/react';
import InputCard from 'components/InputCard';

interface IProps {
    opdracht: IOpdracht
    labelStyles: Partial<ITextFieldStyles>
}

export const OpdrachtNemer = ({opdracht, labelStyles} : IProps) => {
    
    return (
        <InputCard title="Opdrachtnemer">
            {opdracht.partijOpdrachtNemer &&
                <div className="opdrachtnemer">
                    <TextField
                        autoComplete="off"
                        styles={labelStyles}
                        label="Opdrachtnemer"
                        underlined
                        readOnly
                        value={opdracht.partijOpdrachtNemer.naam}
                    />
                    <TextField
                        autoComplete="off"
                        styles={labelStyles}
                        label="GLN-code"
                        underlined
                        readOnly
                        value={opdracht.partijOpdrachtNemer.gln}
                    />                    
                </div>
            }
        </InputCard>
    )
};
