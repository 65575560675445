// The anchor element only supports downloads from the same origin.
// Therefore we have to first fetch the file and then download it from the browser.
const downloadFile = async (url: string, fileName?: string) => {
  const resp = await fetch(url);
  const blob = await resp.blob();
  const blobURL = URL.createObjectURL(blob);
  const el = document.createElement('a');
  el.href = blobURL;
  if (fileName) {
    el.download = fileName
  }
  document.body.appendChild(el);
  el.click()
};

// Download a file that is already stored in the local blob storage.
export const downloadExistingFile = (url: string, fileName?: string) => {
  const el = document.createElement(`a`);
  el.href = url;
  if (fileName) {
      el.download = fileName
  }
  document.body.appendChild(el);
  el.click();
};

export default downloadFile
