import React from 'react';
import { IStackStyles, ITextFieldStyles, Stack } from '@fluentui/react';
import { IOpdracht } from 'interfaces/opdracht';
import Opdrachtregels from './Opdrachtregels';
import Algemeen from './Algemeen';
import Contactgegevens from './Contactgegevens';
import Afspraakgegevens from './Afspraakgegevens';
import { ContactgegevensOpdrachtgever } from './ContactgegevensOpdrachtgever';
import { OpdrachtNemer } from './OpdrachtNemer';

const labelWidth = 175

const labelStyles: Partial<ITextFieldStyles> = {
  wrapper: {
    borderBottom: '1px solid #efefef',
  },
  subComponentStyles: {
    label: {
      root: { width: labelWidth },
    },
  },
  suffix: {
    background: '',
  },
}

const stackStyles: IStackStyles = {
  inner: {
    display: 'flex',
    height: '100%',
    width: '100%',
    padding: 10,
  },
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
  },
}
interface IDetails {
  opdracht: IOpdracht
}

const Details: React.FC<IDetails> = ({ opdracht }) => {
  return (
    <>
      <Stack horizontal wrap tokens={{ childrenGap: 10 }} styles={stackStyles}>
        <Algemeen labelStyles={labelStyles} labelWidth={labelWidth} opdracht={opdracht} />
        <Contactgegevens labelStyles={labelStyles} labelWidth={labelWidth} opdracht={opdracht} />
        <Afspraakgegevens labelStyles={labelStyles} opdracht={opdracht} />
        {/*<Referentiegegevens labelStyles={labelStyles} opdracht={opdracht} />*/}
        <ContactgegevensOpdrachtgever labelStyles={labelStyles} opdracht={opdracht}  labelWidth={labelWidth}/>
        <OpdrachtNemer opdracht={opdracht} labelStyles={labelStyles} />
        <Opdrachtregels opdracht={opdracht} />
      </Stack>
    </>
  )
}

export default Details
