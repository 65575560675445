import React from 'react';
import { ActionButton } from '@fluentui/react';
import { useHistory } from 'react-router-dom';
import './navTop.scss';

export const NavTop = () => {
    const history = useHistory()

    const onClickLopendeOpdrachten = (): any => {
        history.push('/opdrachten')
    };
    const onClickAfgeslotenOpdrachten = (): any => {
        history.push('/afgesloten')
    };
    const onClickGeplandenOpdrachten = (): any => {
        history.push('/gepland')
    };
    return (
        <div className="nav-top flex-container flex-grow flex-gap-8">
            <div><ActionButton onClick={onClickLopendeOpdrachten}>Onderhanden</ActionButton> </div>
            <div><ActionButton onClick={onClickGeplandenOpdrachten}>Geplande</ActionButton> </div>
            <div><ActionButton onClick={onClickAfgeslotenOpdrachten}>Afgesloten</ActionButton></div>
        </div>
    )
};
