import React from 'react'
import { Text } from '@fluentui/react'
import './index.scss'

interface IInputCardProps {
  title: string
  className?: string
  style?: React.CSSProperties
  contentStyle?: React.CSSProperties
}

const InputCard: React.FC<IInputCardProps> = ({ title, className, children, style = {}, contentStyle = {} }) => {
  return (
    <div className={`inputCard ${className}`} style={style}>
        {title!! && <Text as="h2" variant="large">
            {title}
        </Text>}
      <div className="inputCardContent" style={contentStyle}>
        {children}
      </div>
    </div>
  )
}

export default InputCard
