import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IStateMachine } from 'store'
import { RootState } from 'store/rootReducer'
import { IPlanOpdracht, IUpdateOpdrachtReferentiesForm } from 'interfaces/opdracht'
import { PauseReasons } from 'enums/pauseReasons'
import { CompleteReasons } from 'enums/completeReasons'
import { invokeFetch } from 'services/apiClient'
import { fetchOpdracht } from './data'

const initState: IStateMachine = {
  status: 'idle',
  error: '',
}

export const acceptOpdracht = createAsyncThunk<{}, number, { state: RootState }>(
  'opdrachten/accept',
  async (id, thunkApi) => {
    return await invokeFetch(thunkApi, 'POST', `/odata/opdrachten(${id})/Accepteren`).then(() =>
      thunkApi.dispatch(fetchOpdracht(id.toString()))
    )
  }
)

interface IRejectOpdrachtArgs {
  id: number
  toelichting: string
}

export const rejectOpdracht = createAsyncThunk<{}, IRejectOpdrachtArgs, { state: RootState }>(
  'opdrachten/reject',
  async ({ id, toelichting }, thunkApi) => {
    return await invokeFetch(thunkApi, 'POST', `/odata/opdrachten(${id})/Weigeren`, {
      toelichting,
    }).then(() => thunkApi.dispatch(fetchOpdracht(id.toString())))
  }
)

export const planOpdracht = createAsyncThunk<{}, IPlanOpdracht, { state: RootState }>(
  'opdrachten/plan',
  async ({ id, ...data }, thunkApi) => {
    return await invokeFetch(thunkApi, 'POST', `/odata/opdrachten(${id})/Plannen`, {
      ...data,
    }).then(() => thunkApi.dispatch(fetchOpdracht(id.toString())))
  }
)

interface ICompleteOpdrachtArgs {
  id: number
  redencode: CompleteReasons
  toelichting: string
}

export const completeOpdracht = createAsyncThunk<{}, ICompleteOpdrachtArgs, { state: RootState }>(
  'opdrachten/complete',
  async ({ id, redencode, toelichting }, thunkApi) => {
    return await invokeFetch(thunkApi, 'POST', `/odata/opdrachten(${id})/Gereedmelden`, {
      redencode,
      toelichting,
    }).then(() => thunkApi.dispatch(fetchOpdracht(id.toString())))
  }
)

interface IPauseOpdrachtArgs {
  id: number
  redencode: PauseReasons
  toelichting: string
}

export const pauseOpdracht = createAsyncThunk<{}, IPauseOpdrachtArgs, { state: RootState }>(
  'opdrachten/pause',
  async ({ id, redencode, toelichting }, thunkApi) => {
    return await invokeFetch(thunkApi, 'POST', `/odata/opdrachten(${id})/Onderbreken`, {
      redencode,
      toelichting,
    }).then(() => thunkApi.dispatch(fetchOpdracht(id.toString())))
  }
)

interface ICancelOpdrachtArgs {
  id: number
  toelichting: string
}
export const cancelOpdracht = createAsyncThunk<{}, ICancelOpdrachtArgs, { state: RootState }>(
  'opdrachten/cancel',
  async ({ id, toelichting }, thunkApi) => {
    await invokeFetch(thunkApi, 'POST', `/odata/opdrachten(${id})/Annuleren`, {
      toelichting,
    })
    await thunkApi.dispatch(fetchOpdracht(id.toString()))
  }
)

interface IUpdateOpdracht extends IUpdateOpdrachtReferentiesForm {
  id: number
}

export const updateOpdracht = createAsyncThunk<{}, IUpdateOpdracht, { state: RootState }>(
  'opdrachten/update',
  async ({ id, ...data }, thunkApi) => {
    await invokeFetch(thunkApi, 'POST', `/odata/opdrachten(${id})/ReferentiesBijwerken`, {
      ordernummer: data.ordernummer || '',
      offertenummer: data.offertenummer || '',
    })
    await thunkApi.dispatch(fetchOpdracht(id.toString()))
  }
)

export const slice = createSlice({
  name: 'opdrachten/data',
  initialState: initState,
  reducers: {
    resetUpsertOpdracht: state => {
      state.status = 'idle'
      state.error = ''
    },
  },
  extraReducers: builder => {
    builder.addCase(acceptOpdracht.pending, (state, _) => {
      state.status = 'pending'
    })
    builder.addCase(acceptOpdracht.fulfilled, (state, _) => {
      state.status = 'succeeded'
    })
    builder.addCase(acceptOpdracht.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message || ''
    })

    builder.addCase(rejectOpdracht.pending, (state, _) => {
      state.status = 'pending'
    })
    builder.addCase(rejectOpdracht.fulfilled, (state, _) => {
      state.status = 'succeeded'
    })
    builder.addCase(rejectOpdracht.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message || ''
    })

    builder.addCase(planOpdracht.pending, (state, _) => {
      state.status = 'pending'
    })
    builder.addCase(planOpdracht.fulfilled, (state, _) => {
      state.status = 'succeeded'
    })
    builder.addCase(planOpdracht.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message || ''
    })

    builder.addCase(completeOpdracht.pending, (state, _) => {
      state.status = 'pending'
    })
    builder.addCase(completeOpdracht.fulfilled, (state, _) => {
      state.status = 'succeeded'
    })
    builder.addCase(completeOpdracht.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message || ''
    })

    builder.addCase(pauseOpdracht.pending, (state, _) => {
      state.status = 'pending'
    })
    builder.addCase(pauseOpdracht.fulfilled, (state, _) => {
      state.status = 'succeeded'
    })
    builder.addCase(pauseOpdracht.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message || ''
    })

    builder.addCase(cancelOpdracht.pending, (state, _) => {
      state.status = 'pending'
    })
    builder.addCase(cancelOpdracht.fulfilled, (state, _) => {
      state.status = 'succeeded'
    })
    builder.addCase(cancelOpdracht.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message || ''
    })

    builder.addCase(updateOpdracht.pending, (state, _) => {
      state.status = 'pending'
    })
    builder.addCase(updateOpdracht.fulfilled, (state, _) => {
      state.status = 'succeeded'
    })
    builder.addCase(updateOpdracht.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message || ''
    })
  },
})

export const { resetUpsertOpdracht } = slice.actions

export default slice.reducer
