import { CommandButton, IconButton, IContextualMenuItem, IStackStyles, NeutralColors, Stack, Text } from '@fluentui/react'
import { DashboardLanes } from 'enums/dashboardLanes'
import { ILaneSortInfo, ISetLaneSortInfo } from 'interfaces/laneSortInfo'
import { IOpdracht } from 'interfaces/opdracht'
import { ISortableColumn } from 'interfaces/sortableColumn'
import React, { useMemo } from 'react'
import primaryTheme from 'themes/primary'


const laneStyles: IStackStyles = {
  root: {
    height: '100%',
    minWidth: 310,
    maxWidth: 380,
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: 3,
    padding: 10,
    backgroundColor: NeutralColors.gray20,
  },
}

const laneHeaderStyles: IStackStyles = {
  root: {
    borderBottom: '1px solid #efefef',
  },
}

const laneInfoStyles: IStackStyles = {
  root: {
    paddingTop: 15,
    borderBottom: '1px solid #c4c4c4',
  },
}
interface ILaneProps {
  laneId: DashboardLanes
  count: number
  sortInfo: ILaneSortInfo
  onSetSortInfo: (sortInfo: ISetLaneSortInfo) => void
  sortableColumns: ISortableColumn[]
  styles?: IStackStyles
}

const Lane: React.FC<ILaneProps> = ({ laneId, count, children, styles, sortInfo, onSetSortInfo, sortableColumns }) => {
  const { desc = true } = sortInfo

  const sortableColumnsWithClickHandler = useMemo((): IContextualMenuItem[] => {
    return sortableColumns.map(column => ({
      ...column,
      disabled: sortInfo.columnId === column.key,
      onClick: (_, item) =>
        onSetSortInfo({
          id: laneId,
          columnId: item!.key as keyof IOpdracht,
          columnType: column.dataType,
        }),
    }))
  }, [laneId, sortInfo, sortableColumns, onSetSortInfo])

  const selectedColumn = useMemo((): ISortableColumn => {
    const column = sortableColumns.find(x => x.key === sortInfo.columnId)

    return column ? column : sortableColumns[0]
  }, [sortInfo, sortableColumns])

  return (
    <Stack styles={{ ...laneStyles, ...styles }}>
      <Stack styles={laneHeaderStyles}>
        <Text variant="mediumPlus" style={{ fontWeight: 600 }}>
          {laneId}
        </Text>
      </Stack>
      <Stack styles={laneInfoStyles}>
        <Stack horizontal>
          <Stack.Item align="center">
            <Text>{count}</Text>
          </Stack.Item>
          <Stack.Item grow={true}>
            <Stack style={{alignItems: 'end'}}>
              <CommandButton
                text={selectedColumn.text}
                title={selectedColumn.title}
                styles={{
                  textContainer: {
                    color: primaryTheme.palette.black,
                  },
                }}
                menuProps={{
                  items: sortableColumnsWithClickHandler,
                }}
              />
            </Stack>
          </Stack.Item>
          <Stack.Item align="center">
            <IconButton
                iconProps={{ iconName: desc ? 'Down' : 'Up' }}
                onClick={() =>
                    onSetSortInfo({
                      id: laneId,
                      desc: !desc,
                    })
                }
            />
          </Stack.Item>          
        </Stack>
      </Stack>
      {/*<div style={{ flex: 1, overflowY: 'auto' }}>{children}</div>*/}
        <Stack grow={true} tokens={{childrenGap: 12}} styles={{root: {overflow: 'auto', paddingTop: '8px', paddingRight: '6px'}}}>
            {children}
        </Stack>
    </Stack>
  )
}

export default Lane
