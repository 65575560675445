import {
    ConstrainMode,
    DetailsList,
    DetailsListLayoutMode,
    FontSizes,
    FontWeights,
    IColumn,
    IDetailsListProps,
    IDetailsListStyles,
    mergeStyles,
    Selection,
    SelectionMode,
    Spinner,
    SpinnerSize,
    Sticky,
    StickyPositionType
} from '@fluentui/react';
import * as React from 'react';
import { useEffect, useReducer } from 'react';
import './index.scss';
import { CheckboxVisibility } from '@fluentui/react/lib/components/DetailsList/DetailsList.types';
import { cloneDeep } from 'lodash';

interface IOhkTableProps {
    columns: Array<IColumn>
    items: Array<any> | []
    loading: boolean
    disableScroll?: boolean
    disableSort?: boolean
    detailsListStyles?: Partial<IDetailsListStyles>
    detailsListProps?: Partial<IDetailsListProps>
    onSelectionChanged?: (selectedKeys: string[]) => void
    onItemInvoked?: (id: string) => void
    onGetKey: (item: any) => string
}

function tableColumnsReducer(state: IColumn[], action: any) {
    switch (action.type) {
        case 'load':
            return cloneDeep(action.payload);
        case 'sort':
            const newColls = state.map((column: IColumn) => {
                if (column.key === action.payload.key) {
                    column.isSorted = true;
                    column.isSortedDescending = !!column.isSortedDescending ? false : true;
                } else {
                    column.isSorted = false;
                    column.isSortedDescending = true;
                }
                return cloneDeep(column);
            });
            return cloneDeep(newColls);
        default:
            return state;
    }
}

function tableItemsReducer(state: Array<any>, action: any) {
    switch (action.type) {
        case 'load':
            return cloneDeep(action.payload);
        case 'sort':
            return cloneDeep(copyAndSort(state, action.payload.key, action.payload.isSortedDescending, action.payload.fieldType));        
        default:
            return state;
    }
}


const OhkTable = ({columns, items, loading, disableScroll, disableSort, detailsListStyles, detailsListProps, 
                      onSelectionChanged, onItemInvoked, onGetKey} : IOhkTableProps) => {

    const [tableColumns, dispatchTableColumns] = useReducer(tableColumnsReducer,[]);
    const [tableItems, dispatchTableItems] = useReducer(tableItemsReducer, []);
    
    // Nieuwe kolommen ontvangen van parent
    useEffect(() => {
        const cols = columns.map((col: IColumn) => ({
                ...col,
                onColumnClick: !disableSort ? handleColumnClick : undefined,
                headerClassName: headerStyles,
                className: cellStyles,
            } as IColumn)
        );
        const clonedColumns = cloneDeep(cols);
        
        dispatchTableColumns({type: 'load', payload: clonedColumns});
    }, [columns]);

    // Nieuwe items ontvangen van parent
    useEffect(() => {
        // Set the items
        if (tableColumns.length > 0) {
            const sortBy = tableColumns.find(column => column.isSorted);
            const itemsSorted = sortBy ? copyAndSort(items, sortBy.key, sortBy.isSortedDescending, sortBy.data) : items;
            const clonedItems = cloneDeep(itemsSorted);
            dispatchTableItems({type: 'load', payload: clonedItems});
        } else {
            const clonedItems = cloneDeep(items);
            dispatchTableItems({type: 'load', payload: clonedItems});
        }
    }, [items]);

    // Kolommen zijn gewijzigd, meestal door column_click, wat de sorted kolom wijzigt
    useEffect(() => {
        if (tableColumns.length > 0) {
            const sortedColumn = tableColumns.find((column: IColumn) => column.isSorted);
            if (sortedColumn) {
                const isSortedDescending = sortedColumn?.isSortedDescending;
                dispatchTableItems({type: 'sort', payload: {key: sortedColumn.key, isSortedDescending, fieldType: sortedColumn.data}});
            }
        }

    }, [tableColumns]);
    
    
    // Handle selection
    let handleSelectionChanged = () => {
        if (onSelectionChanged) {
            const selectedKeys = selection.getSelection().map((item) => onGetKey(item));
            onSelectionChanged(selectedKeys);
        }
    };
    let selection = new Selection({onSelectionChanged: handleSelectionChanged});

    const handleColumnClick = (_, column: IColumn): void => {
        const currColumn =  tableColumns.find(currCol => column.key === currCol.key);
        if (currColumn) {
            const isSortedDescending = !!currColumn.isSortedDescending;
            dispatchTableColumns({type: 'sort', payload: {key: currColumn.key, isSortedDescending: isSortedDescending}});
        }
    };

    function handleItemInvoked(item: any): any {
        const id = onGetKey(item);
        id && onItemInvoked && onItemInvoked(id);
    }

    function handleGetKey(item: any): any {
        const id = onGetKey(item);
        return id ? id.toString() : '';
    }

    const detailsList = (
        <DetailsList
            selection={selection}
            items={tableItems}
            columns={tableColumns}
            layoutMode={DetailsListLayoutMode.justified}
            selectionPreservedOnEmptyClick={true}
            onItemInvoked={handleItemInvoked}
            getKey={handleGetKey}
            constrainMode={ConstrainMode.unconstrained}
            styles={{
                ...styles,
                ...detailsListStyles,
            }}
            checkboxVisibility={CheckboxVisibility.always}
            selectionMode={onSelectionChanged ? SelectionMode.multiple : SelectionMode.none}
            onRenderDetailsHeader={(headerProps, defaultRender) => {
                return (
                    <Sticky
                        stickyPosition={StickyPositionType.Header}
                        isScrollSynced={true}
                        stickyBackgroundColor="transparent"
                    >
                        <div>{defaultRender && defaultRender(headerProps)}</div>
                    </Sticky>
                )
            }}
            {...detailsListProps}
        />
    );
    
    return (
        <div className="ohkTableWrapper">
            {loading && (
                <div className="spinnerWrapper">
                    <Spinner size={SpinnerSize.large} />
                </div>
            )}
            {!loading && disableScroll && <>{detailsList}</>}
            {!loading && !disableScroll && (
                <div className="flex-grow scrollbar-auto">
                    {detailsList}
                </div>
            )}
        </div>
    );
};

function copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean, fieldType: string = 'string'): T[] {
    const key = columnKey as keyof T;
    return items.slice(0).sort((a: T, b: T) => {
        switch (fieldType) {
            case 'number':
                return sortNumber(a[key], b[key], isSortedDescending);
            case 'date':
                return sortDate(a[key], b[key], isSortedDescending);
            default:
                return sortString(a[key], b[key], isSortedDescending);
        }
    })
}

function sortString(a, b, isSortedDescending?: boolean): number {
    const aValue = !!a ? a : '';
    const bValue = !!b ? b : '';
    
    if (!isSortedDescending) {
        return aValue.localeCompare(bValue);
    } else {
        return bValue.localeCompare(aValue);
    }
}

function sortNumber(a, b, isSortedDescending?: boolean): number {
    const aValue = !!a ? a : 0;
    const bValue = !!b ? b : 0;
    return !isSortedDescending 
        ? aValue - bValue
        : bValue - aValue;
}

function sortDate(a, b, isSortedDescending?: boolean): number {
    const aValue = !!a ? new Date(a) : new Date(0);
    const bValue = !!b ? new Date(b) : new Date(0);
    return !isSortedDescending 
        ? aValue.getTime() - bValue.getTime()
        : bValue.getTime() - aValue.getTime();
}

const styles: Partial<IDetailsListStyles> = {
    root: {
        display: 'flex',
        width: '100%',
    },
};

const headerStyles = mergeStyles({
    displayName: 'CustomColumn',
    span: {
        fontSize: FontSizes.size12,
        fontWeight: FontWeights.regular,
    },
});

const cellStyles = mergeStyles({
    displayName: 'CustomColumn',
    span: {
        fontSize: FontSizes.size12,
        fontWeight: FontWeights.regular,
    },
});

export default OhkTable;