import React, { useCallback, useMemo } from 'react'
import {
  IColumn,
  ILinkStyles,
  IStackStyles,
  Link,
  MessageBar,
  MessageBarType,
  Stack
} from '@fluentui/react'
import { useTypedSelector } from 'store'
import { getOpdrachtExterneDocumenten } from 'store/selectors/opdrachtBijlages'
import FluentTable from 'components/FluentTable'
import { getPropertyName } from 'lib/interfaceUtils'
import { IBijlageDetails } from 'interfaces/bijlage'
import InputCard from "../../../../components/InputCard";

const stackStyles: IStackStyles = {
  root: {
    display: 'block',
    width: '100%',
    boxSizing: 'border-box',
  },
  inner: {
    width: '100%',
    height: 'auto',
    padding: 10,
  },
};

interface IDocumentsProps {
  opdrachtId: number;
}

const LinksStyles : ILinkStyles = {
  root: {
   fontSize: '14px'
  }
};

const ExterneDocumenten: React.FC<IDocumentsProps> = ({ opdrachtId}) => {

  const ExterneDocs = useTypedSelector(state => getOpdrachtExterneDocumenten(state, opdrachtId));

  const handleColumnClick = () => null
  const columns: IColumn[] = useMemo(
    () =>
      [
        {
          key: getPropertyName<IBijlageDetails>('bestandsnaam'),
          fieldName: getPropertyName<IBijlageDetails>('bestandsnaam'),
          name: 'Naam',
          minWidth: 70,
          maxWidth: 100,
          isResizable: true,
          data: 'string',
          onRender: (item) => (
            <Link key={item.id} href={item.externDocumentUrl} styles={LinksStyles} target={'_blank'}>
              {item.bestandsnaam}
            </Link>
          )
        },
      ] as IColumn[],
    [handleColumnClick]
  );

  const handleGetKey = useCallback((item: any) => {
    return (item as IBijlageDetails).opdrachtRegelId ? `regelbijlage${item.id}` : item.id.toString();
  }, []);

  return (
    <Stack horizontal wrap tokens={{ childrenGap: 10 }} styles={stackStyles}>
      <InputCard title="Externe documenten" style={{ minWidth: 500, overflowX: 'auto' }}>
      {ExterneDocs.length === 0 ? (
        <MessageBar messageBarType={MessageBarType.info}>Er zijn geen items om weer te geven.</MessageBar>
      ) : (
        <FluentTable
          columns={columns}
          items={ExterneDocs}
          loading={false}
          onItemInvoked={() => null}
          onGetKey={handleGetKey}
          disableScroll={true}
        />
      )}
      </InputCard>
    </Stack>
  );
};

export default ExterneDocumenten;
