import { IStateMachine } from 'store';
import { RootState } from 'store/rootReducer'
import { invokeFetch } from 'services/apiClient'
import { AannemerInfo } from 'interfaces/aannemerInfo';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

interface AannemerInfoState extends IStateMachine {
    aannemerInfo: AannemerInfo
}

const initState: AannemerInfoState = {
    aannemerInfo: {
        naam: '',
        glnCode: ''
    },
    status: 'idle',
    error: '',
}

const fetchAannemerInfo = createAsyncThunk<AannemerInfo, undefined, { state: RootState }>(
    'aannemerInfo/fetch',
    async (_, thunkApi) => {
        return await invokeFetch<AannemerInfo>(thunkApi, 'GET', '/instellingen/aannemer-info');
    }
)

export const slice = createSlice({
    name: 'aannemerInfo/data',
    initialState: initState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchAannemerInfo.pending, (state, action) => {
            state.status = 'pending';
            state.error = '';
        });
        builder.addCase(fetchAannemerInfo.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.aannemerInfo = action.payload;
        });
        builder.addCase(fetchAannemerInfo.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || '';
        });
    },
});

export { fetchAannemerInfo }
export default slice.reducer;