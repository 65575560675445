import {Stack, IStackStyles, Spinner, SpinnerSize} from "@fluentui/react";
import React from "react";
import { useTypedSelector } from 'store';
import {
  getOpdrachtenForExecuteLane
} from 'store/selectors/opdrachten';
import './jobsSection.scss'
import { format, isToday, isTomorrow } from 'date-fns';
import { Collapse } from 'antd';
import JobItem from "./JobItem";
import { FontIcon } from '@fluentui/react/lib/Icon';
import {IOpdracht} from "../../../../interfaces/opdracht";

const stackStyles: IStackStyles = {
  root: {
    overflow: 'auto'
  }
}
const { Panel } = Collapse;

const JobsSection: React.FC<{}> = () => {

  const toExecute = useTypedSelector(getOpdrachtenForExecuteLane);
  const status = useTypedSelector(state => state.opdrachten.data.status);


    const groupedJobs = toExecute.reduce((ans: Record<string, IOpdracht[]>, currentValue: IOpdracht) => {
        const dateKey = currentValue['afspraakVan']!.split('T')[0];
        (ans[dateKey] = ans[dateKey] || []).push(currentValue);
        return ans;
    }, {});

    const sortItemsInGroups = (groupedData: Record<string, IOpdracht[]>): Record<string, IOpdracht[]> => {
        for (const key in groupedData) {
            if (groupedData.hasOwnProperty(key)) {
                groupedData[key].sort((a, b) => {
                    const dateA = new Date(a.afspraakVan!).toISOString();
                    const dateB = new Date(b.afspraakVan!).toISOString();
                    return dateA.localeCompare(dateB);
                });
            }
        }
        return groupedData;
    };

    const groupedJobsData = sortItemsInGroups(groupedJobs);
    const getDateLabel = (date: string): string => {
    const parsedDate = new Date(date);
    if (isToday(parsedDate)) {
      return `Vandaag ${format(parsedDate, 'd-M-yyyy')}`;
    } else if (isTomorrow(parsedDate)) {
      return `Morgen ${format(parsedDate, 'd-M-yyyy')}`;
    } else {
      return format(parsedDate, 'd-M-yyyy');
    }
  };

  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0)

  const tomorrowDate = new Date(todayDate);
  tomorrowDate.setDate(todayDate.getDate() + 1);
  const getIcon = (date: string) => {
    const parsedDate = new Date(date);
    if (isToday(parsedDate)) {
      return <Stack className="todayAndTomorrow icons"><FontIcon aria-label="Vandaag" iconName="sunny"  /></Stack>;
    } else if (isTomorrow(parsedDate)) {
      return <Stack className="todayAndTomorrow icons"><FontIcon aria-label="Morgen" iconName="sunadd"  /></Stack>;
    } else if (parsedDate < todayDate) {
      return <Stack className="late icons"><FontIcon aria-label="Te laat" iconName="warning"  /></Stack>;
    } else if (parsedDate > tomorrowDate) {
      return <Stack className="later icons"><FontIcon aria-label="Later" iconName="calendar"  /></Stack>;
    }
  };
  const todayTomorrowDates = Object.keys(groupedJobsData)
    .filter((date) => isToday(new Date(date)) || isTomorrow(new Date(date)))
    .sort((a, b) => {
          const dateA = new Date(a);
          const dateB = new Date(b);
          if (isToday(dateA) && isTomorrow(dateB)) {
              return -1;
          } else if (isTomorrow(dateA) && isToday(dateB)) {
              return 1;
          } else {
              return dateA.getTime() - dateB.getTime();
          }
    })
    .map((date) => (
      <Panel key={format(new Date(date), 'yyyy-M-dd')} style={{ border: 'none' }} header={
        <>
          {getDateLabel(date)} ({groupedJobsData[date].length}) {getIcon(date)}
        </>
      } className="panelContainer">
        {groupedJobsData[date].map((item) => (
          <JobItem className="nestedJobs" key={item.id} item={item} />
        ))}
      </Panel>
    ));

  const generateDatePanel = (type: 'past' | 'future', label: string) => {

      const filteredDates = Object.keys(groupedJobsData)
      .filter((date) => ((type === 'past' && new Date(date) < todayDate) || (type === 'future' && new Date(date) >= todayDate)) && !isToday(new Date(date)) && !isTomorrow(new Date(date)))
      .sort((a, b) => (new Date(a) as any) - (new Date(b) as any));
      const totalItemsCount = filteredDates.reduce((sum, date) => sum + groupedJobsData[date].length, 0);

      const filteredDate = Object.keys(groupedJobsData)
      .filter((date) => {
        const currentDate = new Date(date);
        if (type === 'past' && currentDate < todayDate && !isToday(currentDate) && !isTomorrow(currentDate)) {
          return true;
        } else if (type === 'future' && currentDate >= tomorrowDate && !isToday(currentDate) && !isTomorrow(currentDate)) {
          return true;
        }
        return false;
      });

    return (
        <>
            { filteredDates.length > 0 &&
                <Panel
                    key={type}
                    style={{ border: 'none' }}
                    header={
                        <>
                            {label} ({totalItemsCount}) {getIcon(filteredDate[0])}
                        </>
                    }
                    className="panelContainer pastFuturePanel"
                >
                    <Collapse bordered={false} className="Collapse">
                        {filteredDates.map((date) => (
                            <Panel key={date} style={{ border: 'none' }} header={getDateLabel(date)} className="datePanelContainer">
                                {groupedJobsData[date].map((item) => (
                                    <JobItem key={item.id} item={item} />
                                ))}
                            </Panel>
                        ))}
                    </Collapse>
                </Panel>
            }
        </>

    );
  };

  const pastDatesPanel = generateDatePanel('past', 'Te laat');
  const futureDatesPanel = generateDatePanel('future', 'Later');

  return (
    <>
        {status === 'pending' ? (
            <div className="spinnerWrapper">
                <Spinner size={SpinnerSize.large}/>
            </div>
        ) :
        (<Stack styles={stackStyles} className="jobsSection">
            <Collapse bordered={false} defaultActiveKey={[format(todayDate, 'yyyy-M-dd'), format(tomorrowDate, 'yyyy-M-dd')]} className="Collapse">{pastDatesPanel}{todayTomorrowDates}{futureDatesPanel}</Collapse>
        </Stack>)}
    </>
  );
};

export default JobsSection;
