import { combineReducers } from '@reduxjs/toolkit'
import data from './data'
import upsert from './upsert'
import regels from './regels'

export default combineReducers({
  data,
  upsert,
  regels,
})
