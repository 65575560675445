import React, { useCallback, useEffect } from 'react';
import { IBijlageDetails } from 'interfaces/bijlage';
import { IButtonStyles, IconButton, MessageBar, MessageBarType, Modal, Stack, Text } from '@fluentui/react';
import { stringToLocaleString } from 'lib/internationalization';
import { downloadExistingFile } from 'lib/downloadFile';
import { peekResult, useAppDispatch, useTypedSelector } from 'store';
import { deleteOpdrachtregelbijlage, resetUpsertOpdrachtregelbijlage } from 'store/actions/bijlages/upsert';
import { getUpsertOpdrachtregelbijlage } from 'store/selectors/bijlages';
import { deleteOpdrachtBijlage } from 'store/actions/opdrachten/data';

const closeButtonStyles: IButtonStyles = {
    root: {
        marginLeft: 'auto',
        marginTop: 2,
        marginRight: 4,
    },
};

interface IImageViewerProps {
    bijlage: IBijlageDetails;
    onClose: () => void;
}

const ImageViewer: React.FC<IImageViewerProps> = ({bijlage, onClose}) => {
    const dispatch = useAppDispatch();
    const {error} = useTypedSelector(getUpsertOpdrachtregelbijlage);

    useEffect(() => {
        dispatch(resetUpsertOpdrachtregelbijlage());
    }, [dispatch]);

    const handleDelete = useCallback(() => {
        dispatch(resetUpsertOpdrachtregelbijlage());
        if (bijlage.opdrachtRegelId) {
            dispatch(
                deleteOpdrachtregelbijlage({
                    id: bijlage.id,
                    opdrachtId: bijlage.opdrachtId,
                    opdrachtregelId: bijlage.opdrachtRegelId,
                })
            )
                .then(peekResult)
                .then(success => success && onClose());
        } else {
            dispatch(
                deleteOpdrachtBijlage({
                    opdrachtId: bijlage.opdrachtId,
                    bijlageId: bijlage.id,
                })
            )
                .then(peekResult)
                .then(success => success && onClose());
        }
    }, [dispatch, bijlage.opdrachtRegelId, onClose]);

    return (
        <Modal
            isOpen={true}
            isBlocking={false}
            onDismiss={onClose}
            styles={{
                root: {
                    minWidth: 288,
                    minHeight: 172,
                    display: 'flex',
                    flexFlow: 'column nowrap',
                    overflow: 'hidden',
                },
                scrollableContent: {
                    overflow: 'hidden',
                    maxHeight: '100%',
                },
                main: {
                    overflow: 'hidden',
                },
            }}
        >
            <div style={{paddingLeft: 20, paddingRight: 20, paddingBottom: 20, overflow: 'hidden'}}>
                <Stack horizontal styles={{root: {height: 45}}} verticalAlign="center" horizontalAlign="space-between">
                    <Stack horizontal horizontalAlign="start" verticalAlign="center">
                        <Text variant="mediumPlus" styles={{root: {fontWeight: 'bold'}}}>
                            {bijlage.bestandsnaam}
                        </Text>
                        <IconButton
                            styles={{root: {margin: 0}}}
                            iconProps={{
                                iconName: 'Download',
                                onClick: () => {
                                    downloadExistingFile(bijlage.imageUrl as string, bijlage.bestandsnaam);
                                },
                            }}
                        />
                        <IconButton
                            styles={{root: {margin: 0}}}
                            iconProps={{
                                iconName: 'Delete',
                                onClick: () => handleDelete(),
                            }}
                            disabled={bijlage.systeem}
                        />
                    </Stack>
                    <IconButton
                        styles={closeButtonStyles}
                        iconProps={{
                            iconName: 'Cancel',
                        }}
                        ariaLabel="Close popup modal"
                        onClick={onClose}
                    />
                </Stack>
                <Stack horizontal horizontalAlign="start" styles={{root: {marginBottom: 10}}} tokens={{childrenGap: 5}}>
                    {bijlage.oorsprong} | {bijlage.locatie!! ? `${bijlage.locatie} | ` : ''} {bijlage.element!! ? `${bijlage.element} | ` : ''} 
                    {stringToLocaleString(bijlage.datum) || ''}
                </Stack>
                {error && (
                    <MessageBar messageBarType={MessageBarType.error} isMultiline={true}
                                styles={{root: {marginBottom: 10}}}>
                        {error}
                    </MessageBar>
                )}
                <img
                    src={bijlage.imageUrl}
                    alt={bijlage.bestandsnaam}
                    style={{
                        maxHeight: 'calc(100vh - 125px)',
                        maxWidth: 'calc(100vw - 60px)',
                        height: 'auto',
                        width: 'auto'
                    }}
                />
            </div>
        </Modal>
    );
};

export default ImageViewer;
