import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CommandBar, IColumn, ICommandBarItemProps, Link } from '@fluentui/react';
import { IOpdracht } from 'interfaces/opdracht';
import { getPropertyName } from 'lib/interfaceUtils';
import { dateToLocaleDateString } from 'lib/internationalization';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useTypedSelector } from 'store';
import { fetchAfgeslotenOpdrachten } from 'store/actions/opdrachten/data';
import { getAfgeslotenOpdrachten, getOpdrachten } from 'store/selectors/opdrachten';
import commandBarStyles from 'styles/commandBarStyles';
import OhkTable from 'components/OhkTable';

const wrapperStyles: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
};

const tableWrapperStyles: React.CSSProperties = {
    display: 'flex',
    height: '100%',
    width: '100%',
};

const AfgeslotenOpdrachten: React.FC<{}> = props => {
    const history = useHistory();
    const dispatch = useAppDispatch();

    const [refetch, setRefetch] = useState(false);

    const {status: fetchStatus} = useTypedSelector(getOpdrachten);
    const items = useTypedSelector(getAfgeslotenOpdrachten);

    useEffect(() => {
        const fetchPromise = dispatch(fetchAfgeslotenOpdrachten());

        return () => {
            fetchPromise.abort();
        };
    }, [dispatch]);

    useEffect(() => {
        if (refetch) {
            dispatch(fetchAfgeslotenOpdrachten());
            setRefetch(false);
        }
    }, [dispatch, refetch]);

    const commandBarItems: ICommandBarItemProps[] = useMemo(
        () => [
            {
                key: 'pagetitle',
                onRender: () => (
                    <div className="dashboard-title page-title">Afgesloten opdrachten</div>
                )
            },
        ],
        []
    );

    const commandBarItemsFar: ICommandBarItemProps[] = useMemo(
        () => [
            {
                key: 'refresh',
                text: 'Vernieuwen',
                iconProps: {iconName: 'Refresh', className: 'icon'},
                split: false,
                ariaLabel: 'Vernieuwen',
                onClick: () => setRefetch(true),
            },
        ],
        [setRefetch]
    );

    const handleGetKey = (item): string => {
        return (item as IOpdracht).id.toString();
    };

    const handleItemInvoked = useCallback(
        (id: string) => {
            history.push(`/opdrachten/${id}`);
        },
        [history]
    );

    const columns: IColumn[] = useMemo(
        () => [
            {
                key: getPropertyName<IOpdracht>('straatnaam'),
                fieldName: getPropertyName<IOpdracht>('straatnaam'),
                name: 'Adres',
                minWidth: 100,
                maxWidth: 300,
                isResizable: true,
                isRowHeader: true,
                data: 'string',
                onRender: (item: IOpdracht) => (
                    <Link key={item.id} onClick={() => handleItemInvoked(item.id.toString())}>
                        {`${item.straatnaam} ${item.huisnummer || ''} ${item.huisletter || ''} ${item.huisnummerToevoeging || ''}`}
                    </Link>
                ),
            },
            {
                key: getPropertyName<IOpdracht>('postcode'),
                fieldName: getPropertyName<IOpdracht>('postcode'),
                name: 'Postcode',
                minWidth: 80,
                maxWidth: 100,
                isResizable: true,
                data: 'string',
            },
            {
                key: getPropertyName<IOpdracht>('plaats'),
                fieldName: getPropertyName<IOpdracht>('plaats'),
                name: 'Plaats',
                minWidth: 80,
                maxWidth: 100,
                isResizable: true,
                data: 'string',
            },
            {
                key: getPropertyName<IOpdracht>('datumGereed'),
                fieldName: getPropertyName<IOpdracht>('datumGereed'),
                name: 'Gereeddatum',
                minWidth: 80,
                maxWidth: 100,
                isResizable: true,
                isSorted: true,
                isSortedDescending: true,
                data: 'string',
                onRender: (item: IOpdracht) => (
                    <span>{item.datumGereed && dateToLocaleDateString(new Date(item.datumGereed))}</span>
                ),
            },
            {
                key: getPropertyName<IOpdracht>('vrijeTekst'),
                fieldName: getPropertyName<IOpdracht>('vrijeTekst'),
                name: 'Omschrijving',
                minWidth: 100,
                maxWidth: 300,
                isResizable: true,
                data: 'string',
            },
            {
                key: getPropertyName<IOpdracht>('nummer'),
                fieldName: getPropertyName<IOpdracht>('nummer'),
                name: 'Opdrachtnummer',
                minWidth: 80,
                maxWidth: 100,
                isResizable: true,
                onRender: (item: IOpdracht) => (
                    <span>{item.nummer}  {item.subnummer!! && ` | ${item.subnummer}`}</span>
                )
            }
        ],
        [handleItemInvoked]
    );

    return (
        <div style={wrapperStyles}>
            <CommandBar items={commandBarItems} farItems={commandBarItemsFar} styles={commandBarStyles}/>
            <div style={tableWrapperStyles}>
                <OhkTable
                    columns={columns}
                    items={items}
                    loading={fetchStatus === 'pending'}
                    onGetKey={handleGetKey}
                    onItemInvoked={handleItemInvoked}
                    disableScroll={false}
                />
            </div>
        </div>
    );
};

export default AfgeslotenOpdrachten;
