import React, { useEffect, useRef, useState } from 'react'
import { Persona, PersonaSize, Image } from '@fluentui/react'
import './index.scss'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getIsInitialized } from 'store/selectors/app'
import ProfileCallout from './ProfileCallout'
import { useIsAuthenticated } from '@azure/msal-react'
import { useAppDispatch, useTypedSelector } from 'store';
import { getProfile } from 'store/selectors/profile'
import { MsalClient } from 'services/MsalClient'
import { fetchAannemerInfo } from 'store/actions/aannemer/data';
import { getAannemerInfo } from 'store/selectors/aannemer';

const Toolbar = () => {
  const history = useHistory()
  const isAuthenticated = useIsAuthenticated()

  const [imageUrl, setImageUrl] = useState('')
  const [profileCalloutVisible, setProfileCalloutVisible] = useState(false)

  const isInitialized = useSelector(getIsInitialized)
  const profile = useTypedSelector(getProfile)

  const personaRef = useRef<HTMLDivElement>(null)
  const ready = isAuthenticated && isInitialized
  const dispatch = useAppDispatch();
  const {aannemerInfo } = useTypedSelector(getAannemerInfo);

  useEffect(() => {
    if (ready) {
      const fetchImage = async () => {
        try {
          const token = await MsalClient.getGraphToken()
          const resp = await fetch('https://graph.microsoft.com/v1.0/me/photos/48x48/$value', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })

          const url = URL.createObjectURL(await resp.blob())
          setImageUrl(url)
        } catch (error) {}
      }

      fetchImage()

      return () => {
        if (imageUrl) {
          URL.revokeObjectURL(imageUrl)
        }
      }
    }
  }, [ready])

  useEffect(() => {
    if (ready) {
      dispatch(fetchAannemerInfo());
    }
  }, [ready]);

  const handleHomePageClick = () => {
    history.push('/')
  }

  return (
    <div className="toolbar">
      <div className="toolbarContainer">
        <div className="toolbarLeft">
          <div className="toolbarItem" onClick={handleHomePageClick}>
            <Image src={`${process.env.PUBLIC_URL}/logo.png`} height={38} style={{paddingLeft: 6}} />
          </div>
        </div>
        <div className="toolbarCenter">
          <span className="aannemer-naam" data-test="toolbar-aannemer-naam">{aannemerInfo && aannemerInfo.naam}</span>
          <span className="aannemer-glncode" data-test="toolbar-aannemer-glncode">GLN code: {aannemerInfo && aannemerInfo.glnCode}</span>
        </div>
        <div className="toolbarRight">
          <div className="toolbarItem toolbarIcon" ref={personaRef}>
            <Persona
              size={PersonaSize.size40}
              imageInitials={profile.shortName}
              imageUrl={imageUrl}
              onClick={() => setProfileCalloutVisible(prev => !prev)}
              styles={{ root: { width: 40 } }}
            />
          </div>
          <ProfileCallout
            visible={profileCalloutVisible}
            onDismiss={() => setProfileCalloutVisible(false)}
            targetRef={personaRef}
            profile={profile}
          />
        </div>
      </div>
    </div>
  )
}

export default Toolbar
