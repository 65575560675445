import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import { useIsAuthenticated } from '@azure/msal-react';

import Main from './Main';
import NotFound from './components/NotFound';
import Login from './containers/Login';
import Callback from './containers/Callback';
import OnderhandenOpdrachten from './containers/pages/OnderhandenOpdrachten';
import AfgeslotenOpdrachten from './containers/pages/AfgeslotenOpdrachten';
import Opdracht from './containers/pages/Opdracht';
import GeplandeOpdrachten from 'containers/pages/GeplandeOpdrachten';

const AppRouter = () => {
    const isAuthenticated = useIsAuthenticated();
    // Current path for login redirect
    const currentPath = window.location?.pathname;
    // Return URL for callback redirect
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const returnUrl = urlParams.get('returnUrl') ?? undefined;

    const isMobileDevice = () => {
        const userAgent = navigator.userAgent;
        const mobileKeywords = ['Android', 'iPhone', 'iPad', 'Windows Phone', 'Mobile', 'IEMobile', 'Mobi', 'iPod', 'BlackBerry', 'Opera Mini', 'webOS'];
        return mobileKeywords.some(keyword => userAgent.includes(keyword));
    }

    return (
        <Router>
            <Switch>
                <Route path="/login" render={() => (isAuthenticated ? <Redirect to={`/callback/?returnUrl=${returnUrl}`}/> : <Login/>)}/>
                <Route path="/callback" component={Callback}/>
                <Route
                    render={() =>
                        isAuthenticated ? (
                                <Main>
                                    <Switch>
                                        { isMobileDevice() ? <Route path="/" exact render={() => <Redirect to="/gepland"/>}/> :
                                                <Route path="/" exact render={() => <Redirect to="/opdrachten"/>}/> }
                                        <Route path="/opdrachten" exact component={OnderhandenOpdrachten}/>
                                        <Route path="/afgesloten" exact component={AfgeslotenOpdrachten}/>
                                        <Route path="/gepland" exact component={GeplandeOpdrachten}/>
                                        <Route path="/opdrachten/:opdrachtId" exact component={Opdracht}/>
                                        <Route component={NotFound}/>
                                    </Switch>
                                </Main>
                            ) :
                            (
                                <Redirect to={{pathname: `/login/?returnUrl=${encodeURIComponent(currentPath)}`}}/>
                            )
                    }
                />
            </Switch>
        </Router>
    );
};

export default AppRouter;
