import React from 'react';
import InputCard from 'components/InputCard';
import { IOpdracht } from 'interfaces/opdracht';
import { ITextFieldStyles, TextField } from '@fluentui/react';
import format from 'date-fns/format';
import { parseISO } from 'date-fns';

interface IAfspraakgegevensProps {
  opdracht: IOpdracht
  labelStyles: Partial<ITextFieldStyles>
}

const Afspraakgegevens: React.FC<IAfspraakgegevensProps> = ({ opdracht, labelStyles }) => {
  return (
    <>
      {opdracht.afspraakVan && (
        <InputCard 
            title={opdracht.soort === 'MUT' ? 'Planning' : 'Afspraak'}>
          <TextField
            autoComplete="off"
            styles={labelStyles}
            label="Van"
            underlined
            readOnly
            value={format(parseISO(opdracht.afspraakVan), 'dd-MM-yyyy HH:mm')}
          />
          <TextField
            autoComplete="off"
            styles={labelStyles}
            label="Tot"
            underlined
            readOnly
            value={format(parseISO(opdracht.afspraakTot ?? ''), 'dd-MM-yyyy HH:mm')}
          />
        </InputCard>
      )}
    </>
  )
}

export default Afspraakgegevens
