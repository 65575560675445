export enum OpdrachtTypering {
  ORG = 'ORG',
  GAR = 'GAR',
  MAO = 'MAO',
}

export const OpdrachtTyperingLabelMap: { [key in OpdrachtTypering]: string } = {
  [OpdrachtTypering.ORG]: 'Orgineel',
  [OpdrachtTypering.GAR]: 'Garantie',
  [OpdrachtTypering.MAO]: 'Meerwerk/Aanvullend',
}
