import { OpdrachtActions } from 'enums/opdrachtAction'
import { IOpdrachtGebeurtenis } from 'interfaces/opdrachtGebeurtenis'
import { RootState } from '../index'

const sortByDateDescFn = (a: IOpdrachtGebeurtenis, b: IOpdrachtGebeurtenis) =>
  new Date(b.datum).getTime() - new Date(a.datum).getTime()

export const getOpdrachtGebeurtenissen = (state: RootState, opdrachtId: number) =>
  (state.opdrachten.data.list.find(x => x.id === opdrachtId)?.gebeurtenissen || []).slice().sort(sortByDateDescFn)

export const getOpdrachtOnderbrokenGebeurtenis = (state: RootState, opdrachtId: number) => {
  const opdracht = state.opdrachten.data.list.find(x => x.id === opdrachtId)

  const onderbrokenGebeurtenissen = (opdracht?.gebeurtenissen || ([] as IOpdrachtGebeurtenis[]))
    .filter(x => x.actie === OpdrachtActions.Onderbroken)
    .sort(sortByDateDescFn)

  return (onderbrokenGebeurtenissen.length > 0 && onderbrokenGebeurtenissen[0]) || undefined
}
