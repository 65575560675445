import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'

interface IMeState {
  known: boolean
  status: 'idle' | 'pending' | 'succeeded' | 'failed'
  error: string | null
}

const initState: IMeState = {
  known: false,
  status: 'idle',
  error: null,
}

const fetchMe = createAsyncThunk('me/fetch', async (_, thunkApi) => {
  return await invokeFetch(thunkApi, 'GET', '/me/aannemer', null, false)
})

export const slice = createSlice({
  name: 'me',
  initialState: initState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchMe.pending, (state, _) => {
      state.status = 'pending'
      state.known = false
    })
    builder.addCase(fetchMe.fulfilled, (state, _) => {
      state.status = 'succeeded'
      state.known = true
    })
    builder.addCase(fetchMe.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error.message || null
      state.known = false
    })
  },
})

export { fetchMe }

export default slice.reducer
