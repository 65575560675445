import React, { useCallback, useState } from 'react';
import FileUploadDialog from 'components/FileUploadDialog';
import { AppDispatch, useAppDispatch } from 'store';
import { v4 as uuidv4 } from 'uuid';
import { IFileToUpload } from '../IFileToUpload';
import Form from '../Form';
import { IBijlageUpload } from 'interfaces/bijlage';
import { IFileUploadOpdrachtBijlageContext } from './IFileUploadOpdrachtBijlageContext';
import { IOpdrachtregel } from 'interfaces/opdracht'
import {
    ICreateOpdrachtBijlageArgs,
    ICreateOpdrachtBijlageeigenschapArgs
} from 'store/actions/opdrachten/data';
import { createOpdrachtregelbijlage } from 'store/actions/bijlages/upsert';


const doUpload = (
    event: ProgressEvent<FileReader>,
    file: IFileToUpload,
    formData: Record<string, IBijlageUpload>,
    uri: string,
    fileId: string | null,
    context: IFileUploadOpdrachtBijlageContext,
    dispatch: AppDispatch
) => {
    const data = formData[file.id];
    const eigenschappen: ICreateOpdrachtBijlageeigenschapArgs[] = [];

    if (data.kenmerk && data.kenmerkwaarde) {
        eigenschappen.push({
            naam: data.kenmerk,
            waarde: data.kenmerkwaarde,
        });
    }

    return dispatch(
      createOpdrachtregelbijlage({
            opdrachtId: context.opdrachtId,
            opdrachtRegelId: context.opdrachtRegelId,
            bestandssoort: file.file.type,
            bestandsnaam: file.file.name,
            soort: data.soort!,
            bron: context.bron,
            eigenschappen,
            bijlageOnderdeel: context.bijlageOnderdeel,
            file: file.file
        } as ICreateOpdrachtBijlageArgs)
    );

};

interface IFileUploadProps {
  onCancel: () => void
  context: IFileUploadOpdrachtBijlageContext
  regels: IOpdrachtregel[]
}

const FileUploadOpdrachtBijlage: React.FC<IFileUploadProps> = ({ onCancel, context, regels }) => {
  const dispatch = useAppDispatch();

  const [filesToUpload, setFilesToUpload] = useState<IFileToUpload[]>([]);
  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);

  const handleOnFilesSelected = useCallback((files: File[]) => {
    const newFilesToUpload: IFileToUpload[] = [];
    for (const file of files) {
      newFilesToUpload.push({
        id: uuidv4(),
        uploading: false,
        uploaded: false,
        bijlageId: '',
        file: file,
      })
    }
    setFilesToUpload(newFilesToUpload)
  }, []);

  const handleDeleteFile = useCallback(
    (id: string) => {
      setFilesToUpload(prev => [...prev.filter(x => x.id !== id)])
    },
    [setFilesToUpload]
  );

  const handleSave = useCallback(
    async (formData: Record<string, IBijlageUpload>) => {
      setUploading(true);

      for (const file of filesToUpload) {

        let reader = new FileReader();
        
        setFilesToUpload(prev => [...prev.filter(x => x.id !== file.id), { ...file, uploading: true }]);

        reader.onload = ev =>
          doUpload(ev, file, formData, '', null , context, dispatch).then(() => {
            setFilesToUpload(prev => [
              ...prev.filter(x => x.id !== file.id),
              { ...file, uploading: false, uploaded: true, bijlageId: '' },
            ])
          });

        reader.readAsArrayBuffer(file.file)
      }

      onCancel()
    },
    [dispatch, setUploading, setUploaded, filesToUpload, setFilesToUpload, context, onCancel]
  );

  return (
    <FileUploadDialog
      title={context.title}
      accept={context.accept}
      onFilesSelected={handleOnFilesSelected}
      onCancel={onCancel}
      disabled={uploading || uploaded}
    >
      <Form
        disabled={uploading || uploaded || filesToUpload.length === 0}
        files={filesToUpload}
        loading={uploading}
        context={context}
        onDeleteFile={handleDeleteFile}
        onSave={handleSave}
        regels={regels}
      />
    </FileUploadDialog>
  )
};

export default FileUploadOpdrachtBijlage
