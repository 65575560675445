export enum BijlageKenmerk {
  MANCategory = 'MANCategory',
  STRCategory = 'STRCategory',
}

export enum BijlageKenmerkCertificaatWaarde {
  // Circuit = 'Circuit',
  // Lightcone = 'Lightcone',
  // Lightcurve = 'Lightcurve',
  // Wiring = 'Wiring',
  // FSC = 'FSC',
  // ISPM = 'ISPM',
  // KEMA = 'KEMA',
  // KH = 'KH',
  // KIWA = 'KIWA',
  // KOMO = 'KOMO',
  // PEFC = 'PEFC',
  PVW = 'PVW',
  // IHM = 'IHM',
  // SDS = 'SDS',
  // REACH = 'REACH',
  // ART = 'ART',
  // Gastec = 'Gastec',
  // Gaskeur = 'Gaskeur',
  // ADR = 'ADR',
  // UN = 'UN',
  // GHS = 'GHS',
  // LQC = 'LQC',
  // CMR = 'CMR',
  // DIN = 'DIN',
  // ISO = 'ISO',
  // ECCN = 'ECCN',
  // WEEE = 'WEEE',
  DOP = 'DOP',
  // EEL = 'EEL',
  // ECO = 'ECO',
  Seal = 'Seal',
  // DECL = 'DECL',
  // CAL = 'CAL',
  // DOCCE = 'DOCCE',
  // MTCS = 'MTCS',
}

export const BijlageKenmerkCertificaatWaardeLabelMap: { [key in BijlageKenmerkCertificaatWaarde]: string } = {
  // [BijlageKenmerkCertificaatWaarde.Circuit]: 'Elektrisch schakelschema',
  // [BijlageKenmerkCertificaatWaarde.Lightcone]: 'Lightkegel diagram',
  // [BijlageKenmerkCertificaatWaarde.Lightcurve]: 'Lichtverdelingscurve',
  // [BijlageKenmerkCertificaatWaarde.Wiring]: 'Aansluitschema',
  // [BijlageKenmerkCertificaatWaarde.FSC]: 'Forest Stewardship Council',
  // [BijlageKenmerkCertificaatWaarde.ISPM]: 'International Standards For Phytosanitary Measures No. 15',
  // [BijlageKenmerkCertificaatWaarde.KEMA]: 'KEMA-KEUR',
  // [BijlageKenmerkCertificaatWaarde.KH]: 'Keurhout',
  // [BijlageKenmerkCertificaatWaarde.KIWA]: 'KIWA',
  // [BijlageKenmerkCertificaatWaarde.KOMO]: 'KOMO',
  // [BijlageKenmerkCertificaatWaarde.PEFC]: 'Programme for Endorsement of Forest Certification',
  [BijlageKenmerkCertificaatWaarde.PVW]: 'Politiekeurmerk Veilig Wonen',
  // [BijlageKenmerkCertificaatWaarde.IHM]: 'Inventaris gevaarlijke materialen',
  // [BijlageKenmerkCertificaatWaarde.SDS]: 'Veiligheidsblad',
  // [BijlageKenmerkCertificaatWaarde.REACH]: 'Registratie, evaluatie, authorisatie en restrictie van chemicaliën',
  // [BijlageKenmerkCertificaatWaarde.ART]: 'ART keurmerk tweewielersloten',
  // [BijlageKenmerkCertificaatWaarde.Gastec]: 'Gastec keurmerk',
  // [BijlageKenmerkCertificaatWaarde.Gaskeur]: 'Gaskeur keurmerk',
  // [BijlageKenmerkCertificaatWaarde.ADR]:
  // 'Europese verdrag voor het internationale vervoer van gevaarlijke goederen over de weg',
  // [BijlageKenmerkCertificaatWaarde.UN]: 'Identificatienummer gevaarlijke stoffen',
  // [BijlageKenmerkCertificaatWaarde.GHS]:
  // 'Wijze van indeling, kenmerking en etikettering van chemische stoffen en preparaten',
  // [BijlageKenmerkCertificaatWaarde.LQC]: 'Limited Quantity waarde',
  // [BijlageKenmerkCertificaatWaarde.CMR]: 'Internationale conventie bij grensoverschrijdend wegvervoer informatie',
  // [BijlageKenmerkCertificaatWaarde.DIN]: 'DIN informatie',
  // [BijlageKenmerkCertificaatWaarde.ISO]: 'ISO informatie',
  // [BijlageKenmerkCertificaatWaarde.ECCN]: 'Export Control Classification Number',
  // [BijlageKenmerkCertificaatWaarde.WEEE]: 'Richtlijn afgedankte elektrische en elektronische apparatuur (AEEA)',
  [BijlageKenmerkCertificaatWaarde.DOP]: 'Prestatieverklaring',
  // [BijlageKenmerkCertificaatWaarde.EEL]: 'EU energielabel',
  // [BijlageKenmerkCertificaatWaarde.ECO]: 'ECO label',
  [BijlageKenmerkCertificaatWaarde.Seal]: 'Keurmerk',
  // [BijlageKenmerkCertificaatWaarde.DECL]: 'Leveranciersverklaring van preferentiële oorsprong',
  // [BijlageKenmerkCertificaatWaarde.CAL]: 'Kalibratiecertificaat',
  // [BijlageKenmerkCertificaatWaarde.DOCCE]: 'Conformiteitsverklaring',
  // [BijlageKenmerkCertificaatWaarde.MTCS]: 'Malaysian Timber Certification System',
}

export enum BijlageKenmerkHandleidingWaarde {
  Use = 'Use',
  Tech = 'Tech',
}

export const BijlageKenmerkHandleidingWaardeLabelMap: { [key in BijlageKenmerkHandleidingWaarde]: string } = {
  [BijlageKenmerkHandleidingWaarde.Use]: 'Gebruikershandleiding',
  [BijlageKenmerkHandleidingWaarde.Tech]: 'Technische handleiding',
}
