import React from 'react'

interface IOpenMapWithCoordsProps {
  style?: React.CSSProperties
  latitude: string
  longitude: string
}

interface IOpenMapWithAddressProps {
  style?: React.CSSProperties
  street: string
  houseNumber?: string
  city: string
}

type IOpenMapProps = IOpenMapWithCoordsProps | IOpenMapWithAddressProps

const useCoords = (props: IOpenMapProps): props is IOpenMapWithCoordsProps => {
  return 'latitude' in props
}

const urlTemplate = `https://www.google.com/maps/search/?api=1&query=`

const OpenMap: React.FC<IOpenMapProps> = props => {
  let url: string
  if (useCoords(props)) {
    const { latitude, longitude } = props
    url = `${urlTemplate}${latitude},${longitude}`
  } else {
    const { street, houseNumber, city } = props
    const query = houseNumber ? `${street} ${houseNumber} ${city}` : `${street} ${city}`
    url = `${urlTemplate}${encodeURIComponent(query)}`
  }

  const { style } = props

  return (
    <a href={url} target="blank" style={style}>
      {props.children}
    </a>
  )
}

export default OpenMap
