import * as y from 'yup'
import * as m from 'lib/constants/validationMessages'
import { BijlageKenmerk, BijlageKenmerkCertificaatWaarde, BijlageKenmerkHandleidingWaarde } from 'enums/bijlageKenmerk'
import { BijlageType } from 'enums/bijlageType'
import { BijlageBron } from 'enums/bijlageBron'
import {BijlageOnderdeel} from "../enums/bijlageOnderdeel";
export interface IBijlage {
    id: number
    opdrachtId: number
    opdrachtRegelId: number
    url?: string
    soort: BijlageType
    bestandssoort: string
    bestandsnaam: string
    externDocumentUrl: string
    datum: string
    systeem: boolean
    bijlageId: string
    bron: BijlageBron
    bijlageOnderdeel: BijlageOnderdeel
    eigenschappen: IBijlageeigenschap[]
}

export interface IBijlageeigenschap {
  id: number
  naam: BijlageKenmerk | string
  waarde: string
}

export interface IBijlageDetails extends IBijlage {
  oorsprong: 'Regel' | 'Opdracht'
  locatie?: string
  element?: string
  kenmerk?: string
  imageUrl?: string
}

export interface IBijlageUpload {
  bijlageId: string
  soort?: BijlageType
  kenmerk?: BijlageKenmerk
  kenmerkwaarde?: BijlageKenmerkCertificaatWaarde | BijlageKenmerkHandleidingWaarde
}

const mapRules = (map, rule) => Object.keys(map).reduce((newMap, key) => ({ ...newMap, [key]: rule }), {})

export const uploadBijlageSchema = y.lazy(map =>
  y.object(
    mapRules(
      map,
      y.object({
        soort: y.string().required(m.REQUIRED),
        opdrachtregelId: y.number().when('showRegelDropdown', (showRegelDropdown: boolean, schema) => {
          if (showRegelDropdown) {
            return schema.required(m.REQUIRED)
          }
        }),
        kenmerk: y.string().when('soort', (soort: BijlageType, schema) => {
          if (soort !== BijlageType.MTE) {
            return schema.required(m.REQUIRED)
          }
        }),
        kenmerkwaarde: y.string().when('soort', (soort: BijlageType, schema) => {
          if (soort !== BijlageType.MTE) {
            return schema.required(m.REQUIRED)
          }
        }),
      })
    )
  )
)
