import React, { FunctionComponent } from 'react'
import './App.css'
import './Main.scss'
import './styles/styles.scss'
import './styles/flexbox.css'

import Toolbar from './containers/Toolbar'
import Navbar from './containers/Navbar'
import GenericError from 'containers/GenericError'
import { NavTop } from './containers/Navbar/navTop';

type MainProps = {}

const Main: FunctionComponent<MainProps> = props => {
  return (
    <div className="page">
      <Toolbar />
      <div className="main">
          <div className="navbar-top">
            <NavTop />
          </div>
          <div className="navbar">
            <Navbar />
          </div>
        <GenericError />
        <div className="content">{props.children}</div>
      </div>
    </div>
  )
}

export default Main
