import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useTypedSelector } from 'store'
import pivotStyles from 'styles/pivotStyles'
import { getOpdracht, getOpdrachten } from 'store/selectors/opdrachten'
import { MessageBar, MessageBarType, Pivot, PivotItem } from '@fluentui/react'
import './index.scss'
import { fetchOpdracht } from 'store/actions/opdrachten/data'
import Details from './Details'
import ActionBar from './ActionBar'
import Timeline from './Timeline'
import Documents from './Documents'
import Gallery from './Gallery'
import { Samenvatting } from './Samenvatting'
import ExterneDocumenten from './ExterneDocumenten'

const tabpanelStyles: React.CSSProperties = {
  display: 'flex',
  height: '100%',
  width: '100%',
  boxSizing: 'border-box',
  overflowY: 'auto',
}

enum tabs {
  Samenvatting = 'Samenvatting',
  Details = 'Details',
  Bijlagen = "Foto's",
  Documenten = 'Documenten',
  ExterneDocumenten = 'Externe documenten',
  Tijdlijn = 'Tijdlijn',
}

interface RouterParams {
  opdrachtId: string
}

const Opdracht: React.FC<{}> = props => {
  const dispatch = useAppDispatch()
  const { opdrachtId } = useParams<RouterParams>()

  const [refetch, setRefetch] = useState(false)
  const [selectedTab, setSelectedTab] = useState(tabs.Samenvatting)

  const opdracht = useTypedSelector(state => getOpdracht(state, +opdrachtId))
  const { status: fetchStatus, error } = useTypedSelector(getOpdrachten)

  useEffect(() => {
    if (opdrachtId) {
      dispatch(fetchOpdracht(opdrachtId))
    }
  }, [dispatch, opdrachtId])

  useEffect(() => {
    if (refetch && opdrachtId) {
      dispatch(fetchOpdracht(opdrachtId))
      setRefetch(false)
    }
  }, [dispatch, refetch, opdrachtId])

  const handleTabClick = useCallback(
    (item?: PivotItem) => {
      setSelectedTab(item!.props.itemKey! as tabs)
    },
    [setSelectedTab]
  )

  return (
    <div className="opdrachtWrapper">
      <div className="opdrachtHeaderWrapper">
        {fetchStatus === 'failed' && (
            <div>
              <div>Opdrachtgegevens ophalen mislukt.</div>
              <MessageBar messageBarType={MessageBarType.error}>
                {error}
              </MessageBar>
              
            </div>
            
        )}
        <ActionBar opdracht={opdracht} onRefreshClick={() => setRefetch(true)} />
        <div className="pageMargin">
          <Pivot styles={pivotStyles} 
                 selectedKey={selectedTab} 
                 headersOnly={true} 
                 overflowBehavior={'menu'}
                 onLinkClick={handleTabClick}>
            <PivotItem
              itemKey={tabs.Samenvatting}
              headerText={tabs.Samenvatting}
              headerButtonProps={{
                'data-order': 1,
              }}
            />
            <PivotItem
              itemKey={tabs.Details}
              headerText={tabs.Details}
              headerButtonProps={{
                'data-order': 2,
              }}
            />
            <PivotItem
              itemKey={tabs.Bijlagen}
              headerText={`${tabs.Bijlagen} (${opdracht?.fotosCount ?? 0})`}
              headerButtonProps={{
                'data-order': 3,
              }}
            />
            <PivotItem
              itemKey={tabs.Documenten}
              headerText={`${tabs.Documenten} (${opdracht?.documentenCount ?? 0})`}
              headerButtonProps={{
                'data-order': 4,
              }}
            />
            <PivotItem
              itemKey={tabs.ExterneDocumenten}
              headerText={`${tabs.ExterneDocumenten} (${opdracht?.externeDocumentenCount ?? 0})`}
              headerButtonProps={{
                'data-order': 5,
              }}
            />
            <PivotItem
              itemKey={tabs.Tijdlijn}
              headerText={tabs.Tijdlijn}
              headerButtonProps={{
                'data-order': 6,
              }}
            />
          </Pivot>
        </div>
      </div>
      {fetchStatus !== 'pending' && opdracht && (
        <div role="tabpanel" style={tabpanelStyles}>
          {selectedTab === tabs.Samenvatting && <Samenvatting opdracht={opdracht} />}
          {selectedTab === tabs.Details && <Details opdracht={opdracht} />}
          {selectedTab === tabs.Documenten && <Documents opdrachtId={opdracht.id}  />}
          {selectedTab === tabs.Bijlagen && <Gallery opdrachtId={opdracht.id} />}
          {selectedTab === tabs.ExterneDocumenten && <ExterneDocumenten opdrachtId={opdracht.id} />}
          {selectedTab === tabs.Tijdlijn && <Timeline opdrachtId={opdracht.id} />}
        </div>
      )}
    </div>
  )
}
export default Opdracht
