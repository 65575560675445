import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    IColumn,
    Icon,
    IconButton,
    IContextualMenuItem,
    IStackStyles,
    MessageBar,
    MessageBarType,
    Stack,
} from '@fluentui/react';
import { useAppDispatch, useTypedSelector } from 'store';
import { getOpdrachtDocumenten } from 'store/selectors/opdrachtBijlages';
import FluentTable from 'components/FluentTable';
import { getPropertyName } from 'lib/interfaceUtils';
import { IBijlageDetails } from 'interfaces/bijlage';
import * as mime from 'mime-types';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import { BijlageTypeLabelMap } from 'enums/bijlageType';
import { stringToLocaleString } from 'lib/internationalization';
import { fetchBlobAndDownload } from 'store/actions/blobs/data';
import { deleteOpdrachtregelbijlage, resetUpsertOpdrachtregelbijlage } from 'store/actions/bijlages/upsert';
import { getUpsertOpdrachtregelbijlage } from 'store/selectors/bijlages';
import { FileUploadType } from 'enums/fileUploadType';
import { BijlageBron } from 'enums/bijlageBron';
import FileUploadOpdrachtBijlage from '../FileUpload/OpdrachtBijlage/FileUploadOpdrachtBijlage';
import InputCardButton from 'components/InputCardButton';
import { deleteOpdrachtBijlage, IDeleteOpdrachtBijlageArgs } from 'store/actions/opdrachten/data';
import { BijlageOnderdeel } from "../../../../enums/bijlageOnderdeel";

const stackStyles: IStackStyles = {
    root: {
        display: 'block',
        width: '100%',
        boxSizing: 'border-box',
    },
    inner: {
        width: '100%',
        height: 'auto',
        padding: 10,
    },
};

interface IDocumentsProps {
    opdrachtId: number;
}

interface IFileUploadDialogState {
    title: string;
    accept?: string;
    open: boolean;
    opdrachtId?: number;
    type: FileUploadType;
    bron: BijlageBron;
}

const Documents: React.FC<IDocumentsProps> = ({ opdrachtId }) => {
    const dispatch = useAppDispatch();

    const docs = useTypedSelector(state => getOpdrachtDocumenten(state, opdrachtId));
    const { error } = useTypedSelector(getUpsertOpdrachtregelbijlage);
    const [documentUploadState, setDocumentUploadState] = useState<IFileUploadDialogState>({
        title: '',
        open: false,
        type: FileUploadType.Document,
        bron: BijlageBron.Klant,
    });


    const opdracht = useTypedSelector(state => state.opdrachten.data.list.find(x => x.id === opdrachtId))
    const { opdrachtregelId } = useTypedSelector(state => state.opdrachten.regels);

    const regelsIsDefined: boolean = (() => {
        return (opdracht?.regels?.length ?? 0) > 0;
    })();

    const rowActions: IContextualMenuItem[] = useMemo(() => {
        return [
            {
                key: 'download',
                text: 'Download',
                iconProps: { iconName: 'Download' },
            },
        ];
    }, []);

    useEffect(() => {
        dispatch(resetUpsertOpdrachtregelbijlage());
    }, [dispatch]);

    const handleDownload = useCallback(
        (id: string, filename: string) => {
            dispatch(fetchBlobAndDownload({ id: id, fileName: filename })).then();
        },
        [dispatch]
    );

    const handleDelete = useCallback(
        (id: number, opdrachtId: number, opdrachtregelId?: number) => {
            dispatch(resetUpsertOpdrachtregelbijlage());
            if (opdrachtregelId) {
                dispatch(
                    deleteOpdrachtregelbijlage({
                        id,
                        opdrachtId,
                        opdrachtregelId,
                    })
                );
            } else {
                dispatch(
                    deleteOpdrachtBijlage({
                        opdrachtId,
                        bijlageId: id,
                    } as IDeleteOpdrachtBijlageArgs)
                )
            }

        },
        [dispatch, opdrachtId]
    );

    const columns: IColumn[] = useMemo(
        () =>
            [
                {
                    key: 'fileType',
                    fieldName: 'fileType',
                    name: 'fileType',
                    iconName: 'Page',
                    minWidth: 16,
                    maxWidth: 16,
                    styles: {
                        iconClassName: {
                            fontSize: 16,
                            padding: 0,
                        },
                    },
                    isIconOnly: true,
                    onRender: (item: IBijlageDetails) => {
                        const extension = mime.extension(item.bestandssoort) || 'Page';

                        return <Icon {...getFileTypeIconProps({ extension, size: 16 })} />;
                    },
                },
                {
                    key: getPropertyName<IBijlageDetails>('bestandsnaam'),
                    fieldName: getPropertyName<IBijlageDetails>('bestandsnaam'),
                    name: 'Naam',
                    minWidth: 200,
                    maxWidth: 350,
                    isResizable: true,
                    data: 'string',
                    onRender: (item: IBijlageDetails) => (
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div style={{ flexShrink: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                <span>{item.bestandsnaam}</span>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    height: 'auto',
                                    flexShrink: 0,
                                }}
                            >
                                <IconButton
                                    styles={{
                                        root: { height: 'auto', padding: 0, ':active': { background: '#ffffff' } },
                                        splitButtonContainer: { width: 'auto' },
                                        menuIcon: { width: 0, height: 0, flexShrink: 1 },
                                    }}
                                    iconProps={{ iconName: 'MoreVertical' }}
                                    menuProps={{
                                        items: [
                                            {
                                                key: 'download',
                                                text: 'Downloaden',
                                                iconProps: { iconName: 'Download' },
                                                onClick: () => handleDownload(item.bijlageId, item.bestandsnaam),
                                            },
                                            {
                                                key: 'delete',
                                                text: 'Verwijderen',
                                                iconProps: { iconName: 'Delete' },
                                                onClick: () => handleDelete(item.id, item.opdrachtId, item.opdrachtRegelId),
                                                disabled: item.systeem,
                                            },
                                        ],
                                    }}
                                    hidden={true}
                                    menuIconProps={{ iconName: '' }}
                                />
                            </div>
                        </div>
                    ),
                },
                {
                    key: getPropertyName<IBijlageDetails>('bestandssoort'),
                    fieldName: getPropertyName<IBijlageDetails>('bestandssoort'),
                    name: 'Soort',
                    minWidth: 100,
                    maxWidth: 200,
                    isResizable: true,
                    data: 'string',
                    onRender: (item: IBijlageDetails) => <span>{BijlageTypeLabelMap[item.soort]}</span>,
                },
                {
                    key: getPropertyName<IBijlageDetails>('oorsprong'),
                    fieldName: getPropertyName<IBijlageDetails>('oorsprong'),
                    name: 'Bron',
                    minWidth: 70,
                    maxWidth: 100,
                    isResizable: true,
                    data: 'string',
                },
                {
                    key: getPropertyName<IBijlageDetails>('kenmerk'),
                    fieldName: getPropertyName<IBijlageDetails>('kenmerk'),
                    name: 'Kenmerk',
                    minWidth: 80,
                    maxWidth: 200,
                    isResizable: true,
                    data: 'string',
                },
                {
                    key: getPropertyName<IBijlageDetails>('locatie'),
                    fieldName: getPropertyName<IBijlageDetails>('locatie'),
                    name: 'Locatie',
                    minWidth: 70,
                    maxWidth: 100,
                    isResizable: true,
                    data: 'string',
                },
                {
                    key: getPropertyName<IBijlageDetails>('element'),
                    fieldName: getPropertyName<IBijlageDetails>('element'),
                    name: 'Element',
                    minWidth: 70,
                    maxWidth: 100,
                    isResizable: true,
                    data: 'string',
                },
                {
                    key: getPropertyName<IBijlageDetails>('datum'),
                    fieldName: getPropertyName<IBijlageDetails>('datum'),
                    name: 'Datum',
                    minWidth: 100,
                    maxWidth: 150,
                    isResizable: true,
                    isSorted: true,
                    isSortedDescending: true,
                    data: 'string',
                    onRender: (item: IBijlageDetails) => <span>{item.datum && stringToLocaleString(item.datum)}</span>,
                },
            ] as IColumn[],
        [rowActions, handleDownload, handleDelete]
    );

    const handleGetKey = useCallback((item: any) => {
        return (item as IBijlageDetails).opdrachtRegelId ? `regelbijlage${item.id}` : item.id.toString();
    }, []);

    const onAddDocument = (): any => {
        setDocumentUploadState({
            title: `Documenten uploaden`,
            open: true,
            opdrachtId: opdrachtId,
            type: FileUploadType.Document,
            bron: BijlageBron.Werkzaamheden,
        });
    };
    return (
        <Stack horizontal wrap tokens={{ childrenGap: 10 }} styles={stackStyles}>
            <InputCardButton title="Documenten" style={{ minWidth: 500, overflowX: 'auto' }}
                showAddButton={regelsIsDefined} addButtonTitle={'Toevoegen'}
                onAddButtonClick={onAddDocument}>
                {error && (
                    <MessageBar messageBarType={MessageBarType.error} isMultiline={true}
                        styles={{ root: { marginBottom: 10 } }}>
                        {error}
                    </MessageBar>
                )}
                <FluentTable
                    columns={columns}
                    items={docs}
                    loading={false}
                    onItemInvoked={() => null}
                    onGetKey={handleGetKey}
                    disableScroll={true}
                />
            </InputCardButton>
            {documentUploadState.open && (
                <FileUploadOpdrachtBijlage
                    context={{
                        opdrachtId: opdrachtId,
                        opdrachtRegelId: opdrachtregelId!,
                        type: documentUploadState.type,
                        bron: documentUploadState.bron,
                        title: documentUploadState.title,
                        accept: documentUploadState.accept!,
                        bijlageOnderdeel: BijlageOnderdeel.Document,
                        showRegelDropdown: true
                    }}
                    onCancel={() =>
                        setDocumentUploadState({
                            open: false,
                            title: '',
                            accept: '',
                            opdrachtId: undefined,
                            type: FileUploadType.Document,
                            bron: BijlageBron.Klant,
                        })
                    }
                    regels={opdracht?.regels ?? []}
                />
            )}
        </Stack>
    );
};

export default Documents;
