import { configureStore, ThunkAction, Action, getDefaultMiddleware } from '@reduxjs/toolkit'
import { useDispatch, createSelectorHook } from 'react-redux'
import rootReducer, { RootState } from './rootReducer'

const store = configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware()],
})

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default
    store.replaceReducer(newRootReducer)
  })
}

export type { RootState } from './rootReducer'
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>

// Type the useDispatch hook
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()

// Type the useSelector hook
export const useTypedSelector = createSelectorHook<RootState>()

// Peek async action result
export const peekResult = (action: any): boolean => {
  if (action.meta && action.meta.rejectedWithValue) {
    return false
  }
  const { error, payload = {} } = action
  const { errors = [] } = payload

  return error || (errors && errors.length > 0) ? false : true
}

export interface IRejectedThunk {
  statusCode: number
  error: string
}

export interface IStateMachine {
  status: 'idle' | 'pending' | 'succeeded' | 'failed'
  error: string
}

export { store }
