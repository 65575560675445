import React, { useCallback, useMemo, useState } from 'react';
import InputCard from 'components/InputCard';
import { IOpdracht, IOpdrachtregel } from 'interfaces/opdracht';
import FluentTable from 'components/FluentTable';
import {
    HoverCard,
    HoverCardType,
    IColumn,
    IconButton,
    IContextualMenuItem,
    IStackItemStyles,
    IStackStyles,
    ITextFieldStyles,
    Stack,
    TextField,
} from '@fluentui/react';
import { getPropertyName } from 'lib/interfaceUtils';
import { useTypedSelector } from 'store';
import { getOpdrachten } from 'store/selectors/opdrachten';
import { numberToLocaleString } from 'lib/internationalization';
import { OpdrachtRegelEenheidLabelMap } from 'enums/opdrachtRegelEenheid';
import FileUploadOpdrachtRegelbijlage from '../FileUpload/FileUploadOpdrachtRegelbijlage';
import { BijlageBron } from 'enums/bijlageBron';
import { FileUploadType } from 'enums/fileUploadType';
import { BijlageOnderdeel } from "../../../../enums/bijlageOnderdeel";

interface IFileUploadDialogState {
    title: string;
    accept?: string;
    open: boolean;
    opdrachtregelId?: number;
    type: FileUploadType;
    bron: BijlageBron;
}

const hoverCardStackStyles: Partial<IStackStyles> = {
    root: {
        minHeight: 400,
        minWidth: 400,
        padding: 14,
    },
};

const hoverCardStackItemStyles: Partial<IStackItemStyles> = {
    root: {
        padding: 0,
        margin: 0,
    },
};

const hoverCardTextFieldStyles: Partial<ITextFieldStyles> = {
    root: {
        paddingBottom: 10,
    },
    fieldGroup: {
        borderBottom: '1px solid #efefef',
        ':hover': {
            borderBottom: '1px solid #efefef',
        },
    },
    field: {
        padding: 0,
        paddingBottom: 5,
    },
};

interface ITakenProps {
    opdracht: IOpdracht;
}

const Opdrachtregels: React.FC<ITakenProps> = ({ opdracht }) => {
    const [fileUploadDialogState, setFileUploadDialogState] = useState<IFileUploadDialogState>({
        title: '',
        open: false,
        type: FileUploadType.Document,
        bron: BijlageBron.Klant,
    });

    const { regels = [] } = opdracht;

    const { status: fetchStatus } = useTypedSelector(getOpdrachten);

    const rowActions = useCallback(
        (opdrachtRegelId: number): IContextualMenuItem[] => {
            return [
                {
                    key: 'uploadDocument',
                    text: 'Documenten uploaden',
                    iconProps: { iconName: 'OpenFile' },
                    onClick: () =>
                        setFileUploadDialogState({
                            title: 'Documenten uploaden',
                            open: true,
                            opdrachtregelId: opdrachtRegelId,
                            type: FileUploadType.Document,
                            bron: BijlageBron.Werkzaamheden,
                        }),
                },
                {
                    key: 'uploadPictureBefore',
                    text: `Foto's werkzaamheden uploaden`,
                    iconProps: { iconName: 'PictureCenter' },
                    onClick: () =>
                        setFileUploadDialogState({
                            title: `Foto's werkzaamheden uploaden`,
                            accept: 'image/*',
                            open: true,
                            opdrachtregelId: opdrachtRegelId,
                            type: FileUploadType.Image,
                            bron: BijlageBron.Werkzaamheden,
                        }),
                },
                {
                    key: 'uploadPictureAfter',
                    text: `Productfoto's uploaden`,
                    iconProps: { iconName: 'PictureCenter' },
                    onClick: () =>
                        setFileUploadDialogState({
                            title: `Productfoto's uploaden`,
                            accept: 'image/*',
                            open: true,
                            opdrachtregelId: opdrachtRegelId,
                            type: FileUploadType.Image,
                            bron: BijlageBron.Product,
                        }),
                },
            ];
        },
        [setFileUploadDialogState, opdracht.id]
    );

    const columns: IColumn[] = useMemo(
        () =>
            [
                {
                    key: getPropertyName<IOpdrachtregel>('regelnummer'),
                    fieldName: getPropertyName<IOpdrachtregel>('regelnummer'),
                    name: 'Regel',
                    minWidth: 40,
                    maxWidth: 40,
                    data: 'string',
                },
                {
                    key: getPropertyName<IOpdrachtregel>('omschrijving'),
                    fieldName: getPropertyName<IOpdrachtregel>('omschrijving'),
                    name: 'Omschrijving',
                    minWidth: 200,
                    data: 'string',
                    onRender: (item: IOpdrachtregel) => (
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <span>{item.omschrijving}</span>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignContent: 'center',
                                height: 'auto'
                            }}>
                                <IconButton
                                    styles={{
                                        root: { height: 'auto', ':active': { background: '#ffffff' } },
                                        splitButtonContainer: { width: 'auto' },
                                    }}
                                    iconProps={{ iconName: 'MoreVertical' }}
                                    menuProps={{ items: rowActions(item.id) }}
                                    hidden={true}
                                    menuIconProps={{ iconName: '' }}
                                />
                            </div>
                        </div>
                    ),
                },
                {
                    key: getPropertyName<IOpdrachtregel>('locatie'),
                    fieldName: getPropertyName<IOpdrachtregel>('locatie'),
                    name: 'Locatie',
                    minWidth: 100,
                    data: 'string',
                },
                {
                    key: getPropertyName<IOpdrachtregel>('element'),
                    fieldName: getPropertyName<IOpdrachtregel>('element'),
                    name: 'Element',
                    minWidth: 100,
                    data: 'string',
                },
                {
                    key: getPropertyName<IOpdrachtregel>('defect'),
                    fieldName: getPropertyName<IOpdrachtregel>('defect'),
                    name: 'Defect',
                    minWidth: 100,
                    data: 'string',
                },
                {
                    key: getPropertyName<IOpdrachtregel>('aantal'),
                    fieldName: getPropertyName<IOpdrachtregel>('aantal'),
                    name: 'Aantal',
                    minWidth: 70,
                    maxWidth: 100,
                    data: 'number',
                    onRender: (item: IOpdrachtregel) => <span>{numberToLocaleString(item.aantal)}</span>,
                },
                {
                    key: getPropertyName<IOpdrachtregel>('eenheid'),
                    fieldName: getPropertyName<IOpdrachtregel>('eenheid'),
                    name: 'Eenheid',
                    minWidth: 50,
                    data: 'string',
                    onRender: (item: IOpdrachtregel) => <span>{OpdrachtRegelEenheidLabelMap[item.eenheid]}</span>,
                },
                {
                    key: getPropertyName<IOpdrachtregel>('prijs'),
                    fieldName: getPropertyName<IOpdrachtregel>('prijs'),
                    name: 'Prijs',
                    minWidth: 50,
                    data: 'number',
                    onRender: (item: IOpdrachtregel) => <span>{numberToLocaleString(item.prijs)}</span>,
                },
                {
                    key: getPropertyName<IOpdrachtregel>('normprijscode'),
                    fieldName: getPropertyName<IOpdrachtregel>('normprijscode'),
                    name: 'Normprijscode',
                    minWidth: 125,
                    data: 'string',
                },
            ] as IColumn[],
        [rowActions]
    );

    const handleGetKey = useCallback((item: any) => {
        return (item as IOpdrachtregel).id.toString();
    }, []);

    const onRenderCompactCard = useCallback((item: IOpdrachtregel): JSX.Element => {
        return (
            <Stack styles={hoverCardStackStyles} tokens={{ childrenGap: 0 }}>
                <Stack.Item styles={hoverCardStackItemStyles}>
                    {item.omschrijvingLang && (
                        <TextField
                            styles={hoverCardTextFieldStyles}
                            autoComplete="off"
                            disabled={false}
                            label="Extra omschrijving"
                            multiline
                            autoAdjustHeight
                            resizable={false}
                            readOnly
                            borderless
                            value={item.omschrijvingLang}
                        />
                    )}
                </Stack.Item>
                <Stack.Item styles={hoverCardStackItemStyles}>
                    {item.vrijeTekst && (
                        <TextField
                            styles={hoverCardTextFieldStyles}
                            autoComplete="off"
                            disabled={false}
                            label="Vrije tekst"
                            multiline
                            autoAdjustHeight
                            resizable={false}
                            readOnly
                            borderless
                            value={item.vrijeTekst}
                        />
                    )}
                </Stack.Item>
            </Stack>
        );
    }, []);

    return (
        <InputCard title="Opdrachtregels" style={{ minHeight: '300px', overflowX: 'auto' }}>
            <FluentTable
                columns={columns}
                items={regels}
                loading={fetchStatus === 'pending'}
                onItemInvoked={() => null}
                onGetKey={handleGetKey}
                disableScroll={true}
                detailsListProps={{
                    onRenderRow: (props, defaultRender) => {
                        return (
                            <div>
                                <HoverCard
                                    instantOpenOnClick={false}
                                    plainCardProps={{
                                        onRenderPlainCard: onRenderCompactCard,
                                        renderData: props?.item,
                                    }}
                                    type={HoverCardType.plain}
                                >
                                    {defaultRender!(props)}
                                </HoverCard>
                            </div>
                        );
                    },
                }}
            />
            {fileUploadDialogState.open && (
                <FileUploadOpdrachtRegelbijlage
                    context={{
                        opdrachtId: opdracht.id,
                        opdrachtRegelId: fileUploadDialogState.opdrachtregelId!,
                        type: fileUploadDialogState.type,
                        bron: fileUploadDialogState.bron,
                        title: fileUploadDialogState.title,
                        accept: fileUploadDialogState.accept!,
                        bijlageOnderdeel: fileUploadDialogState.type === FileUploadType.Image ? BijlageOnderdeel.Foto : BijlageOnderdeel.Document,
                        showRegelDropdown: false
                    }}
                    onCancel={() =>
                        setFileUploadDialogState({
                            open: false,
                            title: '',
                            accept: '',
                            opdrachtregelId: undefined,
                            type: FileUploadType.Document,
                            bron: BijlageBron.Klant,
                        })
                    }
                    regels={regels}
                />
            )}
        </InputCard>
    );
};

export default Opdrachtregels;
