import React, { useMemo } from 'react'
import { ComboBox, IComboBoxOption, IComboBoxProps } from '@fluentui/react'
import FluentTimePickerOptions from './options'

interface IProps {
  offsetKey?: string // must match the HH:MM format
}

type IFluentTimePickerProps = IComboBoxProps & IProps

const FluentTimePicker: React.FC<IFluentTimePickerProps> = props => {
  const { offsetKey, selectedKey } = props

  const options: IComboBoxOption[] = useMemo(() => {
    let newOptions = [...FluentTimePickerOptions]

    if (offsetKey) {
      const index = FluentTimePickerOptions.findIndex(x => x.key === offsetKey)
      if (index === -1) {
        // The key is not in the list, meaning that he user has filled in a time manually.
        // Find the closest option and use that as the offset key
        const [offsetHours, offsetMinutes] = offsetKey.split(':')
        newOptions = newOptions.reduce((acc, option) => {
          const [hours, minutes] = option.key.toString().split(':')
          if (parseInt(hours) > parseInt(offsetHours)) {
            acc.push(option)
            return acc
          }

          if (parseInt(hours) === parseInt(offsetHours)) {
            if (parseInt(minutes) > parseInt(offsetMinutes) || parseInt(minutes) === parseInt(offsetMinutes)) {
              acc.push(option)
              return acc
            }
          }
          return acc
        }, [] as IComboBoxOption[])
      } else {
        newOptions = newOptions.slice(index, newOptions.length)
      }
    }

    if (selectedKey) {
      // The selectedkey might not be in array when the user inputs manual values.
      // Add it to the array as a hidden property so that it is valid, but hidden in the dropdown.
      const index = newOptions.findIndex(x => x.key === selectedKey)
      if (index === -1) {
        newOptions.push({
          key: selectedKey.toString(),
          text: selectedKey.toString(),
          selected: true,
          hidden: true,
        })
      } else {
        newOptions[index] = { ...newOptions[index], selected: true }
      }
    }

    return newOptions
  }, [offsetKey, selectedKey])

  return (
    <ComboBox
      allowFreeform
      autoComplete={'off'}
      scrollSelectedToTop
      dropdownWidth={100}
      styles={{ optionsContainer: { maxHeight: 216 } }}
      {...props}
      options={options}
    />
  )
}

export default FluentTimePicker
