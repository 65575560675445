import { combineReducers } from '@reduxjs/toolkit'
import appReducer from './actions/app'
import opdrachtenReducer from './actions/opdrachten'
import profileReducer from './actions/profile'
import dashboardReducer from './actions/dashboard'
import blobsReducer from './actions/blobs'
import bijlagesReducer from './actions/bijlages'
import aannemerReducer from './actions/aannemer'

const rootReducer = combineReducers({
  profile: profileReducer,
  app: appReducer,
  opdrachten: opdrachtenReducer,
  dashboard: dashboardReducer,
  blobs: blobsReducer,
  bijlages: bijlagesReducer,
  aannemer: aannemerReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
