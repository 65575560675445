import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface regelsOpdrachtArgs {
  opdrachtregelId?: number
}

const initialState: regelsOpdrachtArgs = {
  opdrachtregelId: 0
}

export const slice = createSlice({
  name: 'regelUploadId',
  initialState,
  reducers: {
    setRegelId: (state, action: PayloadAction<regelsOpdrachtArgs>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setRegelId } = slice.actions;
export default slice.reducer;
