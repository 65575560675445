import { MsalClient } from 'services/MsalClient';
import { store } from 'store';
import { setGenericError } from 'store/actions/app/genericError';
import { RootState } from '../store';

export const invokeFetch = async <T>(
  thunkApi: any,
  method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE',
  uri: string,
  body?: any,
  odata: boolean = true,
  headers: any = {},
  jsonBody: boolean = true
): Promise<T> => {
  const state = thunkApi.getState() as RootState;
  const { apiEndpoint } = state.app.configuration;
  const endpoint = `${apiEndpoint}${uri}`;

    if (jsonBody) { 
        body = body && JSON.stringify(body);
        headers = {
            ...headers,
            'Content-Type': 'application/json',
        };
    }
    
  const token = await MsalClient.getApiToken();
  const resp = await fetch(endpoint, {
    method: method,
    headers: {
      Authorization: `Bearer ${token}`,
      ...headers,
    },
    body: body
  });

  const json = await resp.json();

  if (resp.ok) {
    return method === 'GET' && odata && json.hasOwnProperty('value') ? (json.value as T) : (json as T)
  } else if (resp.status === 404) {
    thunkApi.dispatch(
      setGenericError({
        title: 'Dit record is niet beschikbaar',
        subText:
          'Het gevraagde record is niet gevonden of u beschikt niet over de juiste machtigingen om deze weer te geven.',
        route: '/',
      })
    )
  } else if (resp.status === 500) {
    thunkApi.dispatch(
      setGenericError({
        title: 'Internal server error',
        subText: json.error,
        route: '/',
      })
    )
  }

  return Promise.reject(json.error)
};

export const invokeFetchDownload = async  (
    thunkApi: any,
    method: 'GET',
    uri: string,
    fileName: string
): Promise<any> => {

  
  
  const state = thunkApi.getState() as RootState;
  const { apiEndpoint } = state.app.configuration;
  const endpoint = `${apiEndpoint}${uri}`;

  const token = await MsalClient.getApiToken();
  const resp = await fetch(endpoint, {
    method: method,
    headers: {
      Authorization: `Bearer ${token}`
    },
  });

  if (resp.status === 204) return {} as any;

  if (resp.ok) {
    // download the file
    const blob = await resp.blob();
    const blobURL = URL.createObjectURL(blob);
    const el = document.createElement('a');
    el.href = blobURL;
    if (fileName) {
      el.download = fileName
    }
    document.body.appendChild(el);
    el.click();
    return {} as any;

  } else if (resp.status === 404) {
    thunkApi.dispatch(
        setGenericError({
          title: 'Dit record is niet beschikbaar',
          subText:
              'Het gevraagde record is niet gevonden of u beschikt niet over de juiste machtigingen om deze weer te geven.'
        })
    )
  } else if (resp.status === 500) {
    thunkApi.dispatch(
        setGenericError({
          title: 'Internal server error',
          subText: '',
        })
    )
  }

  return Promise.reject();
};

export const invokeFetchImage = async (blobId: string): Promise<any> => {
  
    const apiEndpoint = store.getState().app.configuration.apiEndpoint;
    const endpoint = `${apiEndpoint}/files/${blobId}`;
    const token = await MsalClient.getApiToken();
    const resp = await fetch(endpoint, {
        method: 'GET',
        headers: {
        Authorization: `Bearer ${token}`,
        },
    });
    
    if (resp.ok) {
        return resp.blob();
    }
    
    return Promise.reject();
};
