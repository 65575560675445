// OPD = Opdracht is een fictieve code die we gebruiken om een opdracht te kunnen toevoegen aan de documenten
export enum BijlageType {
  CAD = 'CAD',
  CHR = 'CHR',
  FAC = 'FAC',
  LOG = 'LOG',
  MAN = 'MAN',
  MTE = 'MTE',
  OTA = 'OTA',
  PAR = 'PAR',
  PHI = 'PHI',
  PPI = 'PPI',
  PRT = 'PRT',
  PVI = 'PVI',
  SCH = 'SCH',
  SOF = 'SOF',
  STR = 'STR',
  OPD = 'OPD',
}

export const BijlageTypeLabelMap: { [key in BijlageType]: string } = {
  [BijlageType.CAD]: 'AutoCAD DWG',
  [BijlageType.CHR]: 'Karakteristieken sheet',
  [BijlageType.FAC]: 'Factuur',
  [BijlageType.LOG]: 'Logo',
  [BijlageType.MAN]: 'Handleiding',
  [BijlageType.MTE]: 'Installatie- en onderhoudsdocument',
  [BijlageType.OTA]: 'Overig',
  [BijlageType.PAR]: 'Explosietekening',
  [BijlageType.PHI]: 'Productfoto hoge resolutie',
  [BijlageType.PPI]: 'Productfoto lage resolutie',
  [BijlageType.PRT]: 'Presentatie document/brochure',
  [BijlageType.PVI]: 'Presentatie video',
  [BijlageType.SCH]: 'Schema',
  [BijlageType.SOF]: 'Software',
  [BijlageType.STR]: 'Certificaat',
  [BijlageType.OPD]: 'Opdracht',
}
