import React from 'react'

interface ICallProps {
  style?: React.CSSProperties
  phoneNumber: string
}

const Call: React.FC<ICallProps> = ({ style, phoneNumber, children }) => {
  return (
    <a href={`tel:${phoneNumber}`} style={style}>
      {children}
    </a>
  )
}

export default Call
