export enum OpdrachtVorm {
  REG = 'REG',
  AFK = 'AFK',
  EHP = 'EHP',
  ANS = 'ANS',
}

export const OpdrachtVormLabelMap: { [key in OpdrachtVorm]: string } = {
  [OpdrachtVorm.REG]: 'Regie',
  [OpdrachtVorm.AFK]: 'Afkoop',
  [OpdrachtVorm.EHP]: 'Eenheidsprijs',
  [OpdrachtVorm.ANS]: 'Aanneemsom',
}
