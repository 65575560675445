export enum PlanReasons {
  AFR = 'AFR',
  AFW = 'AFW',
  BBT = 'BBT',
  MIB = 'MIB',
  VBW = 'VBW',
  BNT = 'BNT',
}

export const PlanReasonsLabelMap: { [key in PlanReasons]: string } = {
  [PlanReasons.AFR]: 'Afwachten, reactie opzichter',
  [PlanReasons.AFW]: 'Afwachten in verband met weer',
  [PlanReasons.BBT]: 'Bewoner belt terug',
  [PlanReasons.MIB]: 'Materiaal in bestelling',
  [PlanReasons.VBW]: 'Verzoek bewoner',
  [PlanReasons.BNT]: 'Bewoner niet thuis',
}
