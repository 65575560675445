import React, { useEffect } from 'react';
import { useMsalAuthentication } from '@azure/msal-react';
import { InteractionType, SsoSilentRequest } from '@azure/msal-browser';
import { graphScopes } from 'services/MsalClient';

// Trigger login
const Login: React.FC = () => {
    const request: SsoSilentRequest = {
        scopes: graphScopes,
    };

    const {login, error} = useMsalAuthentication(InteractionType.Silent, request);

    useEffect(() => {
        if (error) {
            login('redirect', request);
        }
    }, [error, login]);

    return <></>;
};

export default Login;
