import { DicoActionLabelMap, DicoActions } from 'enums/dicoActions'
import { PauseReasons, PauseReasonsLabelMap } from 'enums/pauseReasons'
import { OpdrachtActions } from 'enums/opdrachtAction'
import { PlanReasons, PlanReasonsLabelMap } from 'enums/planReasons'
import { CompleteReasons } from 'enums/completeReasons'
import { OpdrachtTypering } from '../enums/opdrachtTypering';

type ActieType = DicoActions | PauseReasons | PlanReasons | CompleteReasons | OpdrachtTypering

export interface IOpdrachtGebeurtenisregel {
  id: number
  actie: ActieType
  toelichting?: string
}

export interface IOpdrachtGebeurtenis {
  id: number
  opdrachtId: number
  actie: OpdrachtActions
  datum: string
  gebruikersnaam: string
  gebruikersId: string
  regels: IOpdrachtGebeurtenisregel[]
}

export const getGebeurtenisregelOmschrijving = (actie: ActieType) =>
  DicoActionLabelMap[actie] || PauseReasonsLabelMap[actie] || PlanReasonsLabelMap[actie] || CompleteReasons[actie] || OpdrachtTypering[actie] || actie
