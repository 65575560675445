export enum PauseReasons {
  AFR = 'AFR',
  AFW = 'AFW',
  BBT = 'BBT',
  MIB = 'MIB',
  VBW = 'VBW',
  BNT = 'BNT',
}

export const PauseReasonsLabelMap: { [key in PauseReasons]: string } = {
  [PauseReasons.AFR]: 'Afwachten, reactie opzichter',
  [PauseReasons.AFW]: 'Afwachten in verband met weer',
  [PauseReasons.BBT]: 'Bewoner belt terug',
  [PauseReasons.MIB]: 'Materiaal in bestelling',
  [PauseReasons.VBW]: 'Verzoek bewoner',
  [PauseReasons.BNT]: 'Bewoner niet thuis',
}
