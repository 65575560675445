import {useAccount, useIsAuthenticated, useMsal} from '@azure/msal-react';
import {DefaultButton, Spinner, SpinnerSize} from '@fluentui/react';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import './index.scss'
import {fetchMe} from 'store/actions/app/me';
import {fetchProfile} from 'store/actions/profile';
import {getMe, getMeStatus} from 'store/selectors/app';
import NavigationBar from "./subComponents/navigationBar";
import CallbackLoggedInNotKnow from "./subComponents/callbackLoggedInNotKnow";

// Process callback from AAD to check whether or not we know the tenant.

const Callback: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    // Get the return URL from the query string
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const returnUrl = urlParams.get('returnUrl') ?? '/';

    const {known} = useSelector(getMe);
    const fetchMeStatus = useSelector(getMeStatus);

    const {accounts, inProgress} = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const account = useAccount(accounts[0]);

    const {instance} = useMsal();

    const [loginProgrees, setLoginInProgress] = useState(false);

    useEffect(() => {
        if (
            inProgress.toString() === 'none' &&
            account &&
            isAuthenticated &&
            fetchMeStatus !== 'pending' &&
            fetchMeStatus !== 'failed'
        ) {
            dispatch(fetchMe());
            dispatch(fetchProfile());
        }
    }, [inProgress, account, fetchMeStatus, isAuthenticated]);

    useEffect(() => {
        // Authentication succeeded and the tenant is known by our system
        if (fetchMeStatus !== 'pending' && isAuthenticated && known) {
            history.push(returnUrl);
        }
    }, [fetchMeStatus, isAuthenticated, known]);

    const handleLoginClick = () => {
        // Redirect to the login page when the button is clicked
        history.push(`/login/?returnUrl=${returnUrl}`);
    };

    const handleLogOut = () => {
        setLoginInProgress(true)
        instance.logoutRedirect()
    };
    return (
        <div className='callback'>
            <NavigationBar/>
            <div className='container'>

                {
                    (inProgress.toString() !== 'none' || fetchMeStatus === 'pending')
                        ? (<Spinner size={SpinnerSize.large}/>)
                        : (isAuthenticated && account)
                            ? (!known && account.name && account.username &&
                                <CallbackLoggedInNotKnow name={account.name} email={account.username} handleLogout={handleLogOut}/>)
                            : (!loginProgrees ?
                                <div className='popupContainer'>
                                    <h3>U bent niet ingelogd.</h3>
                                    <p>Klik on de knop "Inloggen" om toegang te krijgen tot Onderhoudsketen.</p>
                                    <DefaultButton primary onClick={handleLoginClick} text="Inloggen"/>
                                </div> : null)

                }

            </div>

        </div>
    );
};


export default Callback;
