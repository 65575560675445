import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {DefaultEffects, NeutralColors, SharedColors, Stack, Text} from '@fluentui/react';
import { OpdrachtSoort } from 'enums/opdrachtSoort';
import { format } from 'date-fns';
import { IOpdracht } from 'interfaces/opdracht';
import './JobItem.scss'
interface JobItemProps {
  item: IOpdracht;
  className?: string;
}

const getOpdrachtSoortText = (opdrachtSoort: OpdrachtSoort): string => {
  switch (opdrachtSoort) {
    case OpdrachtSoort.INS:
      return 'Inspectie';
    case OpdrachtSoort.MUT:
      return 'Mutatie';
    case OpdrachtSoort.REP:
      return 'Reparatie';
  }
};

const getColorFromOpdrachtsoort = (opdrachtSoort: OpdrachtSoort): string => {
    switch (opdrachtSoort) {
        case OpdrachtSoort.INS:
            return SharedColors.orange20;
        case OpdrachtSoort.MUT:
            return SharedColors.green10;
        case OpdrachtSoort.REP:
            return SharedColors.cyanBlue10;
    }
};
const JobItem: React.FC<JobItemProps> = ({ item, className }) => {

  const history = useHistory();

  const handleCardClick = useCallback(
    () => {
      history.push(`/opdrachten/${item.id}`);
    },
    [history, item.id]
  );

  const getDateTimeLabel = (dateTime: string): string => {
    const parsedDate = new Date(dateTime);
    return format(parsedDate, 'd-M-yyyy HH:mm');
  };
  return (
      <Stack className='jobItemsContainer'>
          <Stack.Item>
              <div style={{
                  backgroundColor: getColorFromOpdrachtsoort(item.soort),
                  color: NeutralColors.white,
                  fontWeight: 600,
                  padding: '6px 8px',
                  display: 'inline-block',
                  fontSize: '11px',
                  marginTop: '-10px',
                  marginLeft: '5px',
                  boxShadow: DefaultEffects.elevation4,
              }}>
                  {getOpdrachtSoortText(item.soort)}
              </div>
          </Stack.Item>

    <Stack className={`jobContainer ${className}`} onClick={handleCardClick}>
      <Stack className='innerContainer'>
      <Stack>
        <Text block nowrap variant="smallPlus" className='bolder'>{`${item.straatnaam || '** straatnaam ontbreekt **'} ${item.huisnummer || ''} ${item.huisletter || ''}`}</Text>
        <Text block nowrap variant="smallPlus" className='bolder'>{`${item.postcode || '****'} ${item.plaats || '** plaats ontbreekt **'}`}</Text>
        <Text block nowrap variant="smallPlus" className='bolder'>{item.contactpersoon}</Text>
      </Stack>
      <Stack className="dates">
        <Text className='bolder' block nowrap variant="smallPlus">{item.afspraakVan && getDateTimeLabel(item.afspraakVan)}</Text>
        <Text className='bolder' block nowrap variant="smallPlus">{item.afspraakTot && getDateTimeLabel(item.afspraakTot)}</Text>
        <Text className='extra-spacing' block nowrap variant="smallPlus">{`Uiterlijk: ${item.gewensteUitvoerdatum ? getDateTimeLabel(item.gewensteUitvoerdatum) : 'Niet gespecificeerd'}`}</Text>
      </Stack>
      </Stack>
        <Text block nowrap variant="smallPlus">{`${item.nummer} ${item.subnummer ? ' - ' + item.subnummer : ''}`}</Text>
        <Text block nowrap variant="smallPlus">{item.vrijeTekst}</Text>
    </Stack>
      </Stack>
  )
};

export default JobItem;
