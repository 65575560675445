export enum CompleteReasons {
  WAF = 'WAF',
  BNB = 'BNB',
  GGE = 'GGE',
  GAR = 'GAR',
  DDE = 'DDE',
  INS = 'INS',
}

export const CompleteReasonsLabelMap: { [key in CompleteReasons]: string } = {
  [CompleteReasons.WAF]: 'Werk afgerond',
  [CompleteReasons.BNB]: 'Bewoner niet bereikbaar',
  [CompleteReasons.GGE]: 'Geen gebrek aangetroffen',
  [CompleteReasons.GAR]: 'Onder garantie uitvoeren',
  [CompleteReasons.DDE]: 'Oplossen door derden',
  [CompleteReasons.INS]: 'Inspectie nodig',
}
