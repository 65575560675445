export enum OpdrachtStatus {
  Ontvangen = 'Ontvangen',
  Geaccepteerd = 'Geaccepteerd',
  Gepland = 'Gepland',
  Onderbroken = 'Onderbroken',
  Afgesloten = 'Afgesloten',
}

export const OpdrachtStatusLabelMap: { [key in OpdrachtStatus]: string } = {
  [OpdrachtStatus.Ontvangen]: 'Ontvangen',
  [OpdrachtStatus.Geaccepteerd]: 'Geaccepteerd',
  [OpdrachtStatus.Gepland]: 'Gepland',
  [OpdrachtStatus.Onderbroken]: 'Onderbroken',
  [OpdrachtStatus.Afgesloten]: 'Afgesloten',
}
