import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IStateMachine } from 'store';
import { RootState } from 'store/rootReducer';
import { invokeFetchDownload } from 'services/apiClient';
import { IBlob } from 'interfaces/blob';

export interface IBijlageState extends IStateMachine {
  list: Array<IBlob>
}

const initState: IBijlageState = {
  list: [],
  status: 'idle',
  error: '',
};

export interface IFetchBlobAndDownloadArgs {
  id: string,
  fileName: string
}


export const fetchBlobAndDownload = createAsyncThunk<IBlob, IFetchBlobAndDownloadArgs, { state: RootState }>('blobs/fetchById', async (fetchBlobArgs, thunkApi) => {
  return await invokeFetchDownload(thunkApi, 'GET', `/files/${fetchBlobArgs.id}`, fetchBlobArgs.fileName);
});

export const slice = createSlice({
  name: 'blobs/data',
  initialState: initState,
  reducers: {},
});


export default slice.reducer
