import React, { useCallback, useEffect, useMemo } from 'react'
import { PauseReasonsLabelMap } from 'enums/pauseReasons'
import {
  ChoiceGroup,
  DefaultButton,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogType,
  IChoiceGroupOption,
  IDialogContentProps,
  IModalProps,
  MessageBar,
  MessageBarType,
  Spinner,
  SpinnerSize,
  Stack,
  TextField,
} from '@fluentui/react'
import { IOpdracht, IPauseOpdrachtForm, pauseOpdrachtSchema } from 'interfaces/opdracht'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { getPropertyName } from 'lib/interfaceUtils'
import { pauseOpdracht, resetUpsertOpdracht } from 'store/actions/opdrachten/upsert'
import { peekResult, useAppDispatch, useTypedSelector } from 'store'
import { getUpsertOpdracht } from 'store/selectors/opdrachten'

const defaultFormvalues: IPauseOpdrachtForm = {
  redencode: undefined,
  toelichting: undefined,
}

interface IPauseOpdrachtProps {
  opdracht: IOpdracht
  onCancel: () => void
}

const PauseOpdracht: React.FC<IPauseOpdrachtProps> = ({ opdracht, onCancel }) => {
  const dispatch = useAppDispatch()

  const { status, error } = useTypedSelector(getUpsertOpdracht)

  useEffect(() => {
    dispatch(resetUpsertOpdracht())
  }, [dispatch])

  const modalProps = useMemo(
    (): IModalProps => ({
      isBlocking: true,
    }),
    []
  )

  const dialogContentProps = useMemo(
    (): IDialogContentProps => ({
      type: DialogType.normal,
      title: 'Opdracht onderbreken',
    }),
    []
  )

  const options: IChoiceGroupOption[] = useMemo(() => {
    return Object.keys(PauseReasonsLabelMap).map(key => {
      return {
        key,
        text: PauseReasonsLabelMap[key],
      } as IChoiceGroupOption
    })
  }, [])

  const { handleSubmit, control, setValue, errors: formErrors, formState } = useForm<IPauseOpdrachtForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultFormvalues,
    resolver: yupResolver(pauseOpdrachtSchema),
  })

  const onSave = useCallback(
    (data: IPauseOpdrachtForm) => {
      dispatch(resetUpsertOpdracht())

      dispatch(
        pauseOpdracht({
          id: opdracht.id,
          redencode: data.redencode!,
          toelichting: data.toelichting!,
        })
      )
        .then(peekResult)
        .then(success => success && onCancel())
    },
    [dispatch, onCancel, opdracht]
  )

  const handleSave = () => {
    handleSubmit(onSave)()
  }

  const handleDismiss = useCallback(() => {
    status !== 'pending' && onCancel()
  }, [status, onCancel])

  return (
    <Dialog
      hidden={false}
      onDismiss={handleDismiss}
      dialogContentProps={dialogContentProps}
      modalProps={modalProps}
      minWidth={500}
    >
      <DialogContent styles={{ header: { height: 0, width: 0 }, inner: { padding: 0 } }}>
        <Stack tokens={{ childrenGap: 0 }}>
          {error && (
            <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
              {error}
            </MessageBar>
          )}
          <form onSubmit={handleSubmit(onSave)}>
            <Controller
              name={getPropertyName<IPauseOpdrachtForm>('redencode')}
              control={control}
              defaultValue={''}
              render={({ onBlur, value }) => (
                <ChoiceGroup
                  label="Redencode"
                  required
                  selectedKey={value}
                  options={options}
                  onBlur={onBlur}
                  onChange={(_, option) => {
                    setValue('redencode', option?.key)
                    onBlur()
                  }}
                />
              )}
            />
            <Controller
              name={getPropertyName<IPauseOpdrachtForm>('toelichting')}
              control={control}
              defaultValue={''}
              render={({ onChange, onBlur, value }) => (
                <TextField
                  label="Toelichting"
                  required
                  multiline
                  autoAdjustHeight
                  styles={{ field: { minHeight: 150 } }}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  errorMessage={formErrors.toelichting?.message}
                />
              )}
            />
            <input type="submit" style={{ visibility: 'hidden' }} />
          </form>
        </Stack>
      </DialogContent>
      <DialogFooter>
        <DefaultButton primary allowDisabledFocus disabled={!formState.isValid} onClick={handleSave}>
          {status === 'pending' ? <Spinner size={SpinnerSize.small} /> : 'Opslaan'}
        </DefaultButton>
      </DialogFooter>
    </Dialog>
  )
}

export default PauseOpdracht
