import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BijlageBron } from 'enums/bijlageBron';
import { BijlageKenmerk } from 'enums/bijlageKenmerk';
import { BijlageType } from 'enums/bijlageType';
import { IBijlage } from 'interfaces/bijlage';
import { invokeFetch } from 'services/apiClient';
import { IStateMachine, RootState } from 'store';
import {BijlageOnderdeel} from "../../../enums/bijlageOnderdeel";

const initState: IStateMachine = {
  status: 'idle',
  error: '',
};
export interface ICreateOpdrachtregelbijlageeigenschapArgs {
  naam: BijlageKenmerk
  waarde: string
}

export interface ICreateOpdrachtregelbijlageArgs {
  opdrachtId: number
  opdrachtRegelId: number
  soort: BijlageType
  bestandssoort: string
  bestandsnaam: string
  bron: BijlageBron
  eigenschappen: ICreateOpdrachtregelbijlageeigenschapArgs[],
  bijlageOnderdeel: BijlageOnderdeel,
  file: File
}

export const createOpdrachtregelbijlage = createAsyncThunk<
  IBijlage,
  ICreateOpdrachtregelbijlageArgs,
  { state: RootState }
>('bijlages/create', async ({ opdrachtId, ...body }, thunkApi) => {
    const formData = new FormData();
    formData.append('opdrachtId', opdrachtId.toString());
    formData.append('opdrachtRegelId', body.opdrachtRegelId.toString());
    formData.append('soort', body.soort);
    formData.append('bestandssoort', body.bestandssoort);
    formData.append('bestandsnaam', body.bestandsnaam);
    formData.append('bron', body.bron);
    formData.append('eigenschappen', JSON.stringify(body.eigenschappen));
    formData.append('bijlageOnderdeel', body.bijlageOnderdeel.toString());
    formData.append('file', body.file, body.file.name);
    
  const bijlage = await invokeFetch<IBijlage>(thunkApi, 'POST', `/opdrachtregelbijlages`, formData, false,{}, false);
  return {
    ...bijlage,
    opdrachtId,
  }
});

interface IDeleteOpdrachtregelbijlageArgs {
  opdrachtId: number
  opdrachtregelId: number
  id: number
}

export const deleteOpdrachtregelbijlage = createAsyncThunk<
  IDeleteOpdrachtregelbijlageArgs,
  IDeleteOpdrachtregelbijlageArgs,
  { state: RootState }
>('bijlages/delete', async (args, thunkApi) => {
  await invokeFetch<{}>(thunkApi, 'DELETE', `/opdrachtregelbijlages/${args.id}`);
  return args
});

export const slice = createSlice({
  name: 'bijlages/upsert',
  initialState: initState,
  reducers: {
    resetUpsertOpdrachtregelbijlage: state => {
      state.error = ''
    },
  },
  extraReducers: builder => {
    builder.addCase(createOpdrachtregelbijlage.pending, (state, _) => {
      state.status = 'pending';
      state.error = ''
    });
    builder.addCase(createOpdrachtregelbijlage.fulfilled, (state, _) => {
      state.status = 'succeeded'
    });
    builder.addCase(createOpdrachtregelbijlage.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || ''
    });

    builder.addCase(deleteOpdrachtregelbijlage.pending, (state, _) => {
      state.status = 'pending';
      state.error = ''
    });
    builder.addCase(deleteOpdrachtregelbijlage.fulfilled, (state, _) => {
      state.status = 'succeeded'
    });
    builder.addCase(deleteOpdrachtregelbijlage.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || ''
    })
  },
});

export const { resetUpsertOpdrachtregelbijlage } = slice.actions;

export default slice.reducer
