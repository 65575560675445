import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DashboardLanes } from 'enums/dashboardLanes';
import { ILaneSortInfo, ISetLaneSortInfo } from 'interfaces/laneSortInfo';
import { IOpdracht } from 'interfaces/opdracht';
import { FilterOpdrachtGeverAlles, FilterOpdrachtNemerAlles } from 'lib/constants/dashboard';
import { getPropertyName } from 'lib/interfaceUtils';

interface IDashboardState {
    filter: {
        opdrachtgeverId?: string;
        opdrachtNemerNaam?: string;
        zoekTerm?: string;
    };
    laneSortInfo: ILaneSortInfo[];
}

const initState: IDashboardState = {
    filter: {
        opdrachtgeverId: FilterOpdrachtGeverAlles,
        opdrachtNemerNaam: FilterOpdrachtNemerAlles,
        zoekTerm: '',
    },
    laneSortInfo: [
        {
            id: DashboardLanes.Starten,
            columnId: getPropertyName<IOpdracht>('gewensteUitvoerdatum'),
            columnType: 'date',
            desc: false,
        },
        {
            id: DashboardLanes.Plannen,
            columnId: getPropertyName<IOpdracht>('gewensteUitvoerdatum'),
            columnType: 'date',
            desc: false,
        },
        {
            id: DashboardLanes.Uitvoeren,
            columnId: getPropertyName<IOpdracht>('gewensteUitvoerdatum'),
            columnType: 'date',
            desc: false,
        },
        {
            id: DashboardLanes.Afwachten,
            columnId: getPropertyName<IOpdracht>('gewensteUitvoerdatum'),
            columnType: 'date',
            desc: false,
        },
    ],
};

export const slice = createSlice({
    name: 'dashboard',
    initialState: initState,
    reducers: {
        setFilterOpdrachtGever: (state, action:PayloadAction<string>) => {
            state.filter.opdrachtgeverId = action.payload;
        },
        setFilterOpdrachtNemer: (state, action:PayloadAction<string>) => {
            state.filter.opdrachtNemerNaam = action.payload;
        },
        setFilterZoekterm: (state, action:PayloadAction<string>) => {
            state.filter.zoekTerm = action.payload;
        },
        setLaneSortInfo: (state, action: PayloadAction<ISetLaneSortInfo>) => {
            const {id, ...rest} = action.payload;

            const index = state.laneSortInfo.findIndex(x => x.id === id);

            state.laneSortInfo[index] = {
                ...state.laneSortInfo[index],
                ...rest,
            };
        },
    },
});

const {setFilterOpdrachtGever, setFilterOpdrachtNemer, setFilterZoekterm, setLaneSortInfo} = slice.actions;
export {setFilterOpdrachtNemer, setFilterOpdrachtGever,setFilterZoekterm, setLaneSortInfo };

export default slice.reducer;
