import { CommandBar, ICommandBarItemProps } from '@fluentui/react'
import PlanOpdracht from 'containers/PlanOpdracht'
import PauseOpdracht from 'containers/PauseOpdracht'
import { OpdrachtStatus } from 'enums/opdrachtStatus'
import { IOpdracht } from 'interfaces/opdracht'
import React, { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppDispatch } from 'store'
import { acceptOpdracht } from 'store/actions/opdrachten/upsert'
import commandBarStyles from 'styles/commandBarStyles'
import RejectOpdracht from 'containers/RejectOpdracht'
import CancelOpdracht from 'containers/CancelOpdracht'
import CompleteOpdracht from 'containers/CompleteOpdracht'
import "./actionbar.scss";

interface IActionBarProps {
  opdracht?: IOpdracht
  onRefreshClick: () => void
}

const ActionBar: React.FC<IActionBarProps> = ({ opdracht, onRefreshClick }) => {
  const history = useHistory()
  const dispatch = useAppDispatch()

  const [onHoldDialogOpen, setOnHoldDialogOpen] = useState(false)
  const [planDialogOpen, setPlanDialogOpen] = useState(false)
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false)
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false)
  const [completeDialogOpen, setCompleteDialogOpen] = useState(false)

  const handleAccept = useCallback(() => {
    opdracht && dispatch(acceptOpdracht(opdracht.id))
  }, [dispatch, opdracht])

  const commandBarItems: ICommandBarItemProps[] = useMemo(() => {
    let items: ICommandBarItemProps[] = [
      {
        key: 'terug',
        iconProps: { iconName: 'Back', className: 'icon' },
        onClick: () => history.goBack(),
        split: true,
      },
      {
        key: 'pagetitle',
        onRender: () => (
            <div className="onderhandenopdrachten-title page-title">Opdracht {opdracht && opdracht.nummer}{opdracht && opdracht.subnummer ? `  |  ${opdracht.subnummer}` : ''}</div>
        )
      },
    ]

    if (!opdracht) return items

    const planAction: ICommandBarItemProps = {
      key: 'plan',
      text: 'Plannen',
      iconProps: { iconName: 'Calendar' },
      onClick: () => setPlanDialogOpen(true),
    }

    const cancelAction: ICommandBarItemProps = {
      key: 'cancel',
      text: 'Annuleren',
      iconProps: { iconName: 'Cancel' },
      onClick: () => setCancelDialogOpen(true),
    }

    const onHoldAction: ICommandBarItemProps = {
      key: 'onhold',
      text: 'Onderbreken',
      iconProps: { iconName: 'CirclePause' },
      onClick: () => setOnHoldDialogOpen(true),
    }

    if (opdracht.status === OpdrachtStatus.Ontvangen) {
      items.push(
        {
          key: 'accept',
          text: 'Accepteren',
          iconProps: { iconName: 'CheckMark' },
          onClick: () => handleAccept(),
        },
        {
          key: 'reject',
          text: 'Weigeren',
          iconProps: { iconName: 'Cancel' },
          onClick: () => setRejectDialogOpen(true),
        },
        planAction
      )
    } else if (opdracht.status === OpdrachtStatus.Geaccepteerd) {
      items.push(planAction, onHoldAction, cancelAction)
    } else if (opdracht.status === OpdrachtStatus.Gepland) {
      items.push(
        {
          key: 'complete',
          text: 'Gereedmelden',
          iconProps: { iconName: 'Completed' },
          onClick: () => setCompleteDialogOpen(true),
        },
        onHoldAction,
        {
          ...planAction,
          text: 'Herplannen',
        },
        cancelAction
      )
    } else if (opdracht.status === OpdrachtStatus.Onderbroken) {
      items.push(planAction, cancelAction)
    }

    return items
  }, [
    dispatch,
    history,
    opdracht,
    handleAccept,
    setPlanDialogOpen,
    setCancelDialogOpen,
    setOnHoldDialogOpen,
    setRejectDialogOpen,
    setCompleteDialogOpen,
  ])

  const commandBarItemsFar: ICommandBarItemProps[] = useMemo(
      () => [
        {
          key: 'refresh',
          text: 'Vernieuwen',
          iconProps: { iconName: 'Refresh', className: 'icon' },
          split: false,
          ariaLabel: 'Vernieuwen',
          className: 'commandbar-far',
          onClick: () => onRefreshClick(),
        },
      ],
      [onRefreshClick]
  );

  return (
    <>
      <CommandBar items={commandBarItems} farItems={commandBarItemsFar} styles={commandBarStyles} />
      {opdracht && onHoldDialogOpen && (
        <PauseOpdracht opdracht={opdracht} onCancel={() => setOnHoldDialogOpen(false)} />
      )}
      {opdracht && planDialogOpen && <PlanOpdracht opdracht={opdracht} onCancel={() => setPlanDialogOpen(false)} />}
      {opdracht && rejectDialogOpen && (
        <RejectOpdracht opdracht={opdracht} onCancel={() => setRejectDialogOpen(false)} />
      )}
      {opdracht && cancelDialogOpen && (
        <CancelOpdracht opdracht={opdracht} onCancel={() => setCancelDialogOpen(false)} />
      )}
      {opdracht && completeDialogOpen && (
        <CompleteOpdracht opdracht={opdracht} onCancel={() => setCompleteDialogOpen(false)} />
      )}
    </>
  )
}

export default ActionBar
