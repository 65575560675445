export enum OpdrachtRegelEenheid {
  CMT = 'CMT',
  DAY = 'DAY',
  GRM = 'GRM',
  HUR = 'HUR',
  KGM = 'KGM',
  LTR = 'LTR',
  MIN = 'MIN',
  MLT = 'MLT',
  MMT = 'MMT',
  MTK = 'MTK',
  MTQ = 'MTQ',
  MTR = 'MTR',
  PCE = 'PCE',
  TNE = 'TNE',
}

export const OpdrachtRegelEenheidLabelMap: { [key in OpdrachtRegelEenheid]: string } = {
  [OpdrachtRegelEenheid.CMT]: 'Centimeter',
  [OpdrachtRegelEenheid.DAY]: 'Dag',
  [OpdrachtRegelEenheid.GRM]: 'Gram',
  [OpdrachtRegelEenheid.HUR]: 'Uur',
  [OpdrachtRegelEenheid.KGM]: 'Kilogram',
  [OpdrachtRegelEenheid.LTR]: 'Liter',
  [OpdrachtRegelEenheid.MIN]: 'Minuut',
  [OpdrachtRegelEenheid.MLT]: 'Milliliter',
  [OpdrachtRegelEenheid.MMT]: 'Millimeter',
  [OpdrachtRegelEenheid.MTK]: 'Vierkante meter',
  [OpdrachtRegelEenheid.MTQ]: 'Kubieke meter',
  [OpdrachtRegelEenheid.MTR]: 'Meter',
  [OpdrachtRegelEenheid.PCE]: 'Stuks',
  [OpdrachtRegelEenheid.TNE]: 'Ton',
}
